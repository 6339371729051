import castActions from "../cast/actions";
import episodeActions from "../episode/actions";
import galleryActions from "../gallery/actions";
import ticketsActions from "../tickets/actions";
import seriesActions from "./actions";
import { Status } from "@api";
const initialState = {
  seriesStatus: Status.DEFAULT,
  seriesErrorMessage: "",
  createSeriesStatus: Status.DEFAULT,
  createSeriesErrorMessage: "",
  editSeriesStatus: Status.DEFAULT,
  editSeriesErrorMessage: "",
  deleteSeriesStatus: Status.DEFAULT,
  deleteSeriesErrorMessage: "",
  seriesDetailsStatus: Status.DEFAULT,
  seriesDetailsErrorMessage: "",
  showBannerStatus: Status.DEFAULT,
  showBannerErrorMessage: "",
  seriesDetails: {},
  list: [],
  count: {},
  filter: "",
  searchText: "",
  hasMore: true,
};
export default function seriesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case seriesActions.FETCH_SERIES_LIST_LOADING:
      return {
        ...state,
        seriesStatus: Status.LOADING,
        seriesErrorMessage: "",
      };
    case seriesActions.FETCH_SERIES_LIST_SUCCESS:
      const { list, count, hasMore } = payload;

      return {
        ...state,
        seriesStatus: Status.SUCCESS,
        seriesErrorMessage: "",
        list,
        count,
        hasMore,
      };
    case seriesActions.FETCH_SERIES_LIST_ERROR:
      return {
        ...state,
        seriesStatus: Status.ERROR,
        seriesErrorMessage: payload.errorMessage,
      };
    case seriesActions.FETCH_SERIES_DETAIL_LOADING:
      return {
        ...state,
        seriesDetailsStatus: Status.LOADING,
        seriesDetailsErrorMessage: "",
      };
    case seriesActions.FETCH_SERIES_DETAIL_SUCCESS:
      return {
        ...state,
        seriesDetailsStatus: Status.SUCCESS,
        seriesDetailsErrorMessage: "",
        seriesDetails: payload.seriesDetails,
      };
    case seriesActions.FETCH_SERIES_DETAIL_ERROR:
      return {
        ...state,
        seriesDetailsStatus: Status.ERROR,
        seriesDetailsErrorMessage: payload.errorMessage,
      };
    case seriesActions.RESET_SERIES_DETAIL_STATE:
      return {
        ...state,
        seriesDetailsStatus: Status.DEFAULT,
        seriesDetailsErrorMessage: "",
        seriesDetails: null,
      };
    case seriesActions.CREATE_SERIES_LOADING:
      return {
        ...state,
        createSeriesStatus: Status.LOADING,
        createSeriesErrorMessage: "",
      };
    case seriesActions.CREATE_SERIES_SUCCESS:
      return {
        ...state,
        createSeriesStatus: Status.SUCCESS,
        createSeriesErrorMessage: "",
        count: payload.count,
        list: payload.list,
      };
    case seriesActions.CREATE_SERIES_ERROR:
      return {
        ...state,
        createSeriesStatus: Status.ERROR,
        createSeriesErrorMessage: payload.errorMessage,
      };

    case seriesActions.EDIT_SERIES_LOADING:
      return {
        ...state,
        editSeriesStatus: Status.LOADING,
        editSeriesErrorMessage: "",
      };
    case seriesActions.EDIT_SERIES_SUCCESS:
      return {
        ...state,
        editSeriesStatus: Status.SUCCESS,
        editSeriesErrorMessage: "",
        seriesDetails: payload.seriesDetails,
      };
    case seriesActions.EDIT_SERIES_ERROR:
      return {
        ...state,
        editSeriesStatus: Status.ERROR,
        editSeriesErrorMessage: payload.errorMessage,
      };

    case seriesActions.SHOW_BANNER_SERIES_LOADING:
      return {
        ...state,
        showBannerStatus: Status.LOADING,
        showBannerErrorMessage: "",
      };
    case seriesActions.SHOW_BANNER_SERIES_SUCCESS:
      return {
        ...state,
        showBannerStatus: Status.SUCCESS,
        showBannerErrorMessage: "",
        list: payload.newList,
      };
    case seriesActions.SHOW_BANNER_SERIES_ERROR:
      return {
        ...state,
        showBannerStatus: Status.ERROR,
        showBannerErrorMessage: payload.errorMessage,
      };

    case seriesActions.RESET_CREATE_SERIES_STATE:
      return {
        ...state,
        createSeriesStatus: Status.DEFAULT,
        createSeriesErrorMessage: "",
      };
    case seriesActions.RESET_SERIES_STATE:
      return {
        ...state,
        seriesStatus: Status.DEFAULT,
        seriesErrorMessage: "",
        createSeriesStatus: Status.DEFAULT,
        createSeriesErrorMessage: "",
        editSeriesStatus: Status.DEFAULT,
        editSeriesErrorMessage: "",
        deleteSeriesStatus: Status.DEFAULT,
        deleteSeriesErrorMessage: "",
        seriesDetailsStatus: Status.DEFAULT,
        seriesDetailsErrorMessage: "",
        showBannerStatus: Status.DEFAULT,
        showBannerErrorMessage: "",
        filter: "",
        searchText: ""
      };
    case seriesActions.DELETE_SERIES_LOADING:
      return {
        ...state,
        deleteSeriesStatus: Status.LOADING,
        deleteSeriesErrorMessage: "",
      };
    case seriesActions.DELETE_SERIES_SUCCESS:
      return {
        ...state,
        deleteSeriesStatus: Status.SUCCESS,
        deleteSeriesErrorMessage: "",
        list: payload.list,
        count: payload.count,
      };
    case seriesActions.DELETE_SERIES_ERROR:
      return {
        ...state,
        deleteSeriesStatus: Status.ERROR,
        deleteSeriesErrorMessage: payload.errorMessage,
      };
    case episodeActions.CREATE_EPISODE_SUCCESS:
      return {
        ...state,
        seriesDetails: { ...state?.seriesDetails, episodeList: payload?.list },
      };
    case episodeActions.EDIT_EPISODE_SUCCESS:
      return {
        ...state,
        seriesDetails: {
          ...state?.seriesDetails,
          episodeList: payload?.episodeList,
        },
      };
    case episodeActions.DELETE_EPISODE_SUCCESS:
      return {
        ...state,
        seriesDetails: {
          ...state?.seriesDetails,
          episodeList: payload?.episodeList,
        },
      };
    case episodeActions.UPLOAD_EPISODE_IMAGE_SUCCESS:
      let newEpisodeList = state?.seriesDetails?.episodeList?.map((episode) => {
        if (episode.episodeIdentifier === payload?.identifier) {
          return {
            ...episode,
            landscapeImagePath: payload?.landscapeImagePath,
          };
        }
        return episode;
      });

      return {
        ...state,
        seriesDetails: {
          ...state?.seriesDetails,
          episodeList: [...newEpisodeList],
        },
      };
    case castActions.CREATE_CAST_SUCCESS:
      return {
        ...state,
        seriesDetails: { ...state?.seriesDetails, castList: payload?.list },
      };
    case castActions.EDIT_CAST_SUCCESS:
      return {
        ...state,
        seriesDetails: { ...state?.seriesDetails, castList: payload?.castList },
      };
    case castActions.DELETE_CAST_SUCCESS:
      return {
        ...state,
        seriesDetails: { ...state?.seriesDetails, castList: payload?.castList },
      };
    case ticketsActions.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        seriesDetails: {
          ...state?.seriesDetails,
          price: payload?.price,
          ticketPrefixCode: payload?.ticketPrefixCode,
        },
      };

    case seriesActions.SET_SERIES_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case seriesActions.SET_SERIES_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case galleryActions.CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        seriesDetails: {
          ...state?.seriesDetails,
          galleryList: payload?.list,
        },
      };
    case galleryActions.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        seriesDetails: { ...state?.seriesDetails, galleryList: payload?.list },
      };

    default:
      return state;
  }
}
