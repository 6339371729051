import { Status } from "@api";
import actions from "./actions";

const initState = {
  status: Status.DEFAULT,
  adminErrorMessage: "",
  adminListStatus: Status.DEFAULT,
  adminListErrorMessage: "",
  adminEditStatus: Status.DEFAULT,
  adminEditErrorMessage: "",
  adminCreateStatus: Status.DEFAULT,
  adminCreateErrorMessage: "",
  uploadProfileStatus: Status.DEFAULT,
  uploadErrorMessage: "",
  currentAdminEditStatus: Status.DEFAULT,
  currentAdminEditErrorMessage: "",
  list: [],
  count: {},
  hasMore: true,
  adminId: "",
  adminIdentifier: "",
  adminName: "",
  email: "",
  gender: "",
  adminStatus: "",
  adminRole: "",
  profileImagePath: null,
  joinDate: "",
  lastLogin: "",
  mobileNumber: "",
  address: "",
  filter: "",
  searchText: "",
  selectedAdmin: null,
  editView: false,
  creating: false,
};

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.CREATE_ADMIN_LOADING:
      return {
        ...state,
        adminCreateStatus: Status.LOADING,
        adminCreateErrorMessage: "",
      };
    case actions.CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        adminCreateStatus: Status.SUCCESS,
        adminCreateErrorMessage: "",
        list: payload.newAdminList,
        selectedAdmin: payload.newAdmin,
        editView: false,
        creating: false,
      };
    case actions.CREATE_ADMIN_ERROR:
      return {
        ...state,
        adminCreateStatus: Status.ERROR,
        adminCreateErrorMessage: payload.errorMessage,
      };
    case actions.FETCH_ADMIN_LIST_LOADING:
      return {
        ...state,
        adminListStatus: Status.LOADING,
        adminListErrorMessage: "",
      };
    case actions.FETCH_ADMIN_LIST_SUCCESS:
      const { list, count, hasMore, selectedAdmin } = payload;
      return {
        ...state,
        list,
        count,
        hasMore,
        selectedAdmin,
        adminListStatus: Status.SUCCESS,
        adminListErrorMessage: "",
        editView: false,
      };
    case actions.FETCH_ADMIN_LIST_ERROR:
      return {
        ...state,
        adminListStatus: Status.ERROR,
        adminListErrorMessage: payload.errorMessage,
      };
    case actions.RESET_ADMIN_LIST_STATE:
      return {
        ...state,
        adminListStatus: Status.DEFAULT,
        adminListErrorMessage: "",
        filter: "",
        searchText: "",
      };
    case actions.FETCH_ADMIN_DETAILS_LOADING:
      return {
        ...state,
        status: Status.LOADING,
        adminErrorMessage: "",
      };
    case actions.FETCH_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        ...payload,
        status: Status.SUCCESS,
        adminErrorMessage: "",
      };
    case actions.FETCH_ADMIN_DETAILS_ERROR:
      return {
        ...state,
        status: Status.ERROR,
        adminErrorMessage: payload.errorMessage,
      };
    case actions.EDIT_ADMIN_LOADING:
      return {
        ...state,
        adminEditStatus: Status.LOADING,
        adminEditErrorMessage: "",
      };
    case actions.EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        adminEditStatus: Status.SUCCESS,
        adminEditErrorMessage: "",
        selectedAdmin: payload.updatedAdmin,
        list: payload.updatedList,
        editView: false,
      };
    case actions.EDIT_ADMIN_ERROR:
      return {
        ...state,
        adminEditStatus: Status.ERROR,
        adminEditErrorMessage: payload.errorMessage,
      };
    case actions.RESET_EDIT_ADMIN_STATE:
      return {
        ...state,
        adminEditStatus: Status.DEFAULT,
        adminEditErrorMessage: "",
      };
    case actions.UPLOAD_ADMIN_IMAGE_LOADING:
      return {
        ...state,
        uploadProfileStatus: Status.LOADING,
        uploadErrorMessage: "",
      };
    case actions.UPLOAD_ADMIN_IMAGE_SUCCESS:
      return {
        ...state,
        uploadProfileStatus: Status.SUCCESS,
        uploadErrorMessage: "",
        profileImagePath: payload.profileImagePath,
      };
    case actions.UPLOAD_ADMIN_IMAGE_ERROR:
      return {
        ...state,
        uploadProfileStatus: Status.ERROR,
        uploadErrorMessage: payload.errorMessage,
      };
    case actions.EDIT_CURRENT_ADMIN_LOADING:
      return {
        ...state,
        currentAdminEditStatus: Status.LOADING,
        currentAdminEditErrorMessage: "",
      };
    case actions.EDIT_CURRENT_ADMIN_SUCCESS:
      return {
        ...state,
        ...payload.updatedCurrentAdmin,
        list: payload.updatedList,
        currentAdminEditStatus: Status.SUCCESS,
        currentAdminEditErrorMessage: "",
      };
    case actions.EDIT_CURRENT_ADMIN_ERROR:
      return {
        ...state,
        currentAdminEditStatus: Status.ERROR,
        currentAdminEditErrorMessage: payload.errorMessage,
      };
    case actions.RESET_EDIT_CURRENT_ADMIN_STATE:
      return {
        ...state,
        currentAdminEditStatus: Status.DEFAULT,
        currentAdminEditErrorMessage: "",
      };
    case actions.SET_ADMINS_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case actions.SET_ADMIN_FILTER:
      return {
        ...state,
        filter: payload,
        editView: false,
      };
    case actions.SET_SELECTED_ADMIN:
      return {
        ...state,
        selectedAdmin: payload.admin,
        editView: false,
        creating: false,
      };
    case actions.EDIT_VIEW:
      return {
        ...state,
        editView: payload.view,
      };
    case actions.CREATE_VIEW:
      return {
        ...state,
        creating: payload.creating,
        editView: true,
        selectedAdmin: null,
      };
    default:
      return state;
  }
}
