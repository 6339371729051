/**
 * Maps server error code
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  noErrorCodeFromServer: 910381,
  internalServerError: '1010',
  noTicketError: '1168',
  noThumbnailError: '1169',
  noVideoError: '1166',
  sessionExpired: '1000',
  forbiddenStatusCode: 403,
};
