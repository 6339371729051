import membersActions from './actions';
import { Status } from '@api';

const initialState = {
    list: [],
    count: {},
    filter: '',
    searchText: '',
    selectedMember: {},
    hasMore: true,
    memberStatus: Status.DEFAULT,
    memberErrorMessage: '',
}

export default function membersReducer(state = initialState, { type, payload }) {
    switch (type) {
        case membersActions.FETCH_MEMBER_LIST_LOADING:
            return {
                ...state,
                memberStatus: Status.LOADING,
                memberErrorMessage: '',
            }
        case membersActions.FETCH_MEMBER_LIST_SUCCESS:
            const { list, count, hasMore, selectedMember } = payload;
            return {
                ...state,
                list,
                count,
                hasMore,
                selectedMember,
                memberStatus: Status.SUCCESS,
                memberErrorMessage: '',
            }
        case membersActions.FETCH_MEMBER_LIST_ERROR:
            return {
                ...state,
                memberStatus: Status.ERROR,
                memberErrorMessage: payload.errorMessage,
            }
        case membersActions.RESET_MEMBERS_STATE:
          return {
            ...state,
            memberStatus: Status.DEFAULT,
            memberErrorMessage: '',
            filter: '',
            searchText: '',
          }
        case membersActions.SET_MEMBERS_SEARCH_TEXT:
            return {
                ...state,
                searchText: payload,
            }
        case membersActions.SET_MEMBERS_FILTER:
            return {
                ...state,
                filter: payload,
            }
        case membersActions.SET_SELECTED_MEMBER:
            return {
                ...state,
                selectedMember: payload.member,
            }
        default:
            return state;
    }
}