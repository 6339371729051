/**
 * Constants
 */

const ApiTimeOverAllowance = 50 * 1000; // 50 second timeout

const MediaUploadApiTimeOverAllowance = 60 * 60 * 1000; // 1 hr timeout

// Change this value to test different environment

export const VERSION = "01.0";

export const getBuildConfig = () => {
  return {
    baseUrl: process.env.REACT_APP_API_URL,
    baseAwsUrl: process.env.REACT_APP_BASE_AWS_URL,
    basePath: process.env.REACT_APP_BASE_PATH,
    hashSignKey: process.env.REACT_APP_HASH_SIGN_KEY,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccesskey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    apiTimeOverAllowance: ApiTimeOverAllowance,
    mediaUploadApiTimeOverAllowance: MediaUploadApiTimeOverAllowance,
    googleRecaptchaSiteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    videoPlayerBaseUrl: process.env.REACT_APP_VIDEO_PLAYER_BASE_URL,
    gdPlayerBaseUrl: process.env.REACT_APP_GD_PLAYER_BASE_URL,
  };
};
