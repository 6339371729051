import {
  PORTRAIT_PHOTO_SK,
  LANDSCAPE_PHOTO_SK,
  LIGHT_PHOTO_SK,
  DARK_PHOTO_SK,
  LANDSCAPE_GIF_PHOTO_SK,
  MOBILE_BANNER_PHOTO_SK,
  WEB_BANNER_PHOTO_SK,
  PORTRAIT_GIF_PHOTO_SK,
} from "@constants";

const postersActions = {
  // portrait image
  UPLOAD_PORTRAIT_IMAGE_REQUEST: "UPLOAD_PORTRAIT_IMAGE_REQUEST",
  UPLOAD_PORTRAIT_IMAGE_LOADING: "UPLOAD_PORTRAIT_IMAGE_LOADING",
  UPLOAD_PORTRAIT_IMAGE_SUCCESS: "UPLOAD_PORTRAIT_IMAGE_SUCCESS",
  UPLOAD_PORTRAIT_IMAGE_ERROR: "UPLOAD_PORTRAIT_IMAGE_ERROR",
  //  Landscape_image
  UPLOAD_LANDSCAPE_IMAGE_REQUEST: "UPLOAD_LANDSCAPE_IMAGE_REQUEST",
  UPLOAD_LANDSCAPE_IMAGE_LOADING: "UPLOAD_LANDSCAPE_IMAGE_LOADING",
  UPLOAD_LANDSCAPE_IMAGE_SUCCESS: "UPLOAD_LANDSCAPE_IMAGE_SUCCESS",
  UPLOAD_LANDSCAPE_IMAGE_ERROR: "UPLOAD_LANDSCAPE_IMAGE_ERROR",

  //  Light_image
  UPLOAD_LIGHT_IMAGE_REQUEST: "UPLOAD_LIGHT_IMAGE_REQUEST",
  UPLOAD_LIGHT_IMAGE_LOADING: "UPLOAD_LIGHT_IMAGE_LOADING",
  UPLOAD_LIGHT_IMAGE_SUCCESS: "UPLOAD_LIGHT_IMAGE_SUCCESS",
  UPLOAD_LIGHT_IMAGE_ERROR: "UPLOAD_LIGHT_IMAGE_ERROR",

  //  DARK_image
  UPLOAD_DARK_IMAGE_REQUEST: "UPLOAD_DARK_IMAGE_REQUEST",
  UPLOAD_DARK_IMAGE_LOADING: "UPLOAD_DARK_IMAGE_LOADING",
  UPLOAD_DARK_IMAGE_SUCCESS: "UPLOAD_DARK_IMAGE_SUCCESS",
  UPLOAD_DARK_IMAGE_ERROR: "UPLOAD_DARK_IMAGE_ERROR",

  //  gif_image
  UPLOAD_LANDSCAPE_GIF_IMAGE_REQUEST: "UPLOAD_LANDSCAPE_GIF_IMAGE_REQUEST",
  UPLOAD_LANDSCAPE_GIF_IMAGE_LOADING: "UPLOAD_LANDSCAPE_GIF_IMAGE_LOADING",
  UPLOAD_LANDSCAPE_GIF_IMAGE_SUCCESS: "UPLOAD_LANDSCAPE_GIF_IMAGE_SUCCESS",
  UPLOAD_LANDSCAPE_GIF_IMAGE_ERROR: "UPLOAD_LANDSCAPE_GIF_IMAGE_ERROR",

  UPLOAD_PORTRAIT_GIF_IMAGE_REQUEST: "UPLOAD_PORTRAIT_GIF_IMAGE_REQUEST",
  UPLOAD_PORTRAIT_GIF_IMAGE_LOADING: "UPLOAD_PORTRAIT_GIF_IMAGE_LOADING",
  UPLOAD_PORTRAIT_GIF_IMAGE_SUCCESS: "UPLOAD_PORTRAIT_GIF_IMAGE_SUCCESS",
  UPLOAD_PORTRAIT_GIF_IMAGE_ERROR: "UPLOAD_PORTRAIT_GIF_IMAGE_ERROR",

  //  mobile_banner_image
  UPLOAD_MOBILE_BANNER_IMAGE_REQUEST: "UPLOAD_MOBILE_BANNER_IMAGE_REQUEST",
  UPLOAD_MOBILE_BANNER_IMAGE_LOADING: "UPLOAD_MOBILE_BANNER_IMAGE_LOADING",
  UPLOAD_MOBILE_BANNER_IMAGE_SUCCESS: "UPLOAD_MOBILE_BANNER_IMAGE_SUCCESS",
  UPLOAD_MOBILE_BANNER_IMAGE_ERROR: "UPLOAD_MOBILE_BANNER_IMAGE_ERROR",

  //  web_banner_image
  UPLOAD_WEB_BANNER_IMAGE_REQUEST: "UPLOAD_WEB_BANNER_IMAGE_REQUEST",
  UPLOAD_WEB_BANNER_IMAGE_LOADING: "UPLOAD_WEB_BANNER_IMAGE_LOADING",
  UPLOAD_WEB_BANNER_IMAGE_SUCCESS: "UPLOAD_WEB_BANNER_IMAGE_SUCCESS",
  UPLOAD_WEB_BANNER_IMAGE_ERROR: "UPLOAD_WEB_BANNER_IMAGE_ERROR",

  RESET_UPLOAD_STATE: "RESET_UPLOAD_STATE",

  uploadPortraitImage: ({
    file,
    entityIdentifier,
    fileDirectory = PORTRAIT_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_PORTRAIT_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },
  uploadLandscapeImage: ({
    file,
    entityIdentifier,
    fileDirectory = LANDSCAPE_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_LANDSCAPE_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },
  uploadLightImage: ({
    file,
    entityIdentifier,
    fileDirectory = LIGHT_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_LIGHT_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },
  uploadDarkImage: ({
    file,
    entityIdentifier,
    fileDirectory = DARK_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_DARK_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },

  uploadLandscapeGifImage: ({
    file,
    entityIdentifier,
    fileDirectory = LANDSCAPE_GIF_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_LANDSCAPE_GIF_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },

  uploadPortraitGifImage: ({
    file,
    entityIdentifier,
    fileDirectory = PORTRAIT_GIF_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_PORTRAIT_GIF_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },

  uploadWebBannerPhoto: ({
    file,
    entityIdentifier,
    fileDirectory = WEB_BANNER_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_WEB_BANNER_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },

  uploadMobileBannerPhoto: ({
    file,
    entityIdentifier,
    fileDirectory = MOBILE_BANNER_PHOTO_SK,
    fileCategory,
  }) => {
    return {
      type: postersActions.UPLOAD_MOBILE_BANNER_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },

  resetUploadState: () => {
    return {
      type: postersActions.RESET_UPLOAD_STATE,
    };
  },
};

export default postersActions;
