const moviesActions = {
  FETCH_MOVIE_LIST_REQUEST: "FETCH_MOVIE_LIST_REQUEST",
  FETCH_MOVIE_LIST_LOADING: "FETCH_MOVIE_LIST_LOADING",
  FETCH_MOVIE_LIST_SUCCESS: "FETCH_MOVIE_LIST_SUCCESS",
  FETCH_MOVIE_LIST_ERROR: "FETCH_MOVIE_LIST_ERROR",
  CREATE_MOVIE_REQUEST: "CREATE_MOVIE_REQUEST",
  CREATE_MOVIE_LOADING: "CREATE_MOVIE_LOADING",
  CREATE_MOVIE_SUCCESS: "CREATE_MOVIE_SUCCESS",
  CREATE_MOVIE_ERROR: "CREATE_MOVIE_ERROR",
  RESET_CREATE_MOVIE_STATE: "RESET_CREATE_MOVIE_STATE",
  RESET_MOVIE_STATE: "RESET_MOVIE_STATE",
  DELETE_MOVIE_REQUEST: "DELETE_MOVIE_REQUEST",
  DELETE_MOVIE_LOADING: "DELETE_MOVIE_LOADING",
  DELETE_MOVIE_SUCCESS: "DELETE_MOVIE_SUCCESS",
  DELETE_MOVIE_ERROR: "DELETE_MOVIE_ERROR",

  FETCH_MOVIE_DETAIL_REQUEST: "FETCH_MOVIE_DETAIL_REQUEST",
  FETCH_MOVIE_DETAIL_LOADING: "FETCH_MOVIE_DETAIL_LOADING",
  FETCH_MOVIE_DETAIL_SUCCESS: "FETCH_MOVIE_DETAIL_SUCCESS",
  FETCH_MOVIE_DETAIL_ERROR: "FETCH_MOVIE_DETAIL_ERROR",

  SHOW_BANNER_REQUEST: "SHOW_BANNER_REQUEST",
  SHOW_BANNER_LOADING: "SHOW_BANNER_LOADING",
  SHOW_BANNER_SUCCESS: "SHOW_BANNER_SUCCESS",
  SHOW_BANNER_ERROR: "SHOW_BANNER_ERROR",

  // SEARCH
  SET_MOVIE_SEARCH_TEXT: "SET_MOVIE_SEARCH_TEXT",
  SET_MOVIE_FILTER: "SET_MOVIE_FILTER",

  EDIT_MOVIE_REQUEST: "EDIT_MOVIE_REQUEST",
  EDIT_MOVIE_LOADING: "EDIT_MOVIE_LOADING",
  EDIT_MOVIE_SUCCESS: "EDIT_MOVIE_SUCCESS",
  EDIT_MOVIE_ERROR: "EDIT_MOVIE_ERROR",

  fetchMovieList: (page) => {
    return {
      type: moviesActions.FETCH_MOVIE_LIST_REQUEST,
      payload: {
        page,
      },
    };
  },
  fetchMovieDetails: (movieIdentifier) => {
    return {
      type: moviesActions.FETCH_MOVIE_DETAIL_REQUEST,
      payload: {
        movieIdentifier,
      },
    };
  },

  createMovie: (movie) => {
    return {
      type: moviesActions.CREATE_MOVIE_REQUEST,
      payload: {
        ...movie,
      },
    };
  },

  editMovie: (movie) => {
    return {
      type: moviesActions.EDIT_MOVIE_REQUEST,
      payload: {
        ...movie,
      },
    };
  },

  showBanner: (movie) => {
    return {
      type: moviesActions.SHOW_BANNER_REQUEST,
      payload: {
        ...movie,
      },
    };
  },

  resetCreateMovieState: () => {
    return {
      type: moviesActions.RESET_CREATE_MOVIE_STATE,
    };
  },

  resetMovieState: () => {
    return {
      type: moviesActions.RESET_MOVIE_STATE,
    };
  },
  deleteMovie: (movieIdentifier) => {
    return {
      type: moviesActions.DELETE_MOVIE_REQUEST,
      payload: {
        movieIdentifier,
      },
    };
  },
  setSearchText: (payload) => {
    return {
      type: moviesActions.SET_MOVIE_SEARCH_TEXT,
      payload,
    };
  },
  setMovieFilter: (payload) => {
    return {
      type: moviesActions.SET_MOVIE_FILTER,
      payload,
    };
  },
};

export default moviesActions;
