import { call, put, select, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import _ from "lodash";
/**
 * worker saga: Calls the Production House create organization API.
 */
function* createProductionHouse({ payload: { name, nameMy, statusName } }) {
  try {
    yield put({ type: actions.CREATE_PRODUCTION_HOUSE_LOADING });
    /*** get data from reducer ***/
    let { list } = yield select(getDataFromStore);
    //api call
    const response = yield call(
      { context: api, fn: api.createProductionHouse },
      { name, nameMy, statusName }
    );
    // parse the data from response
    const { userResponse: newProductionHouse } = response;
    let newProductionHouseList = [newProductionHouse, ...list];
    yield put({
      type: actions.CREATE_PRODUCTION_HOUSE_SUCCESS,
      payload: {
        newProductionHouseList,
        newProductionHouse,
        count: newProductionHouse.count,
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_PRODUCTION_HOUSE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}
/**
 * worker saga: Calls the fetch productonHouse list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchProductionHouseList({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_PRODUCTION_HOUSE_LIST_LOADING });
    let {
      searchText: keyword,
      filter: statusName,
      list: extList,
    } = yield select(getDataFromStore);
    //api call
    const response = yield call(
      { context: api, fn: api.fetchProductionHouseList },
      { page, keyword, statusName }
    );
    // parse the data from response
    const {
      userResponse: { count, productionList: newList },
    } = response;
    const _list = page === 1 ? newList : [...extList, ...newList];
    yield put({
      type: actions.FETCH_PRODUCTION_HOUSE_LIST_SUCCESS,
      payload: {
        count,
        list: _list,
        hasMore: newList.length >= 12,
        selectedProductionHouse: _list[0],
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_PRODUCTION_HOUSE_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}
/**
 * worker saga: Calls the Put edit production house API.
 */
function* editProductionHouse({
  payload: { name, nameMy, statusName, productionHouseIdentifier },
}) {
  try {
    yield put({ type: actions.EDIT_PRODUCTION_HOUSE_LOADING });
    //*** get data from reducer ***
    let { list } = yield select(getDataFromStore);
    //api call
    const response = yield call(
      { context: api, fn: api.editProductionHouse },
      { name, nameMy, statusName, productionHouseIdentifier }
    );
    // parse the data from response
    const { userResponse } = response;
    let _list = _.clone(list);
    for (let i = 0; i < _list.length; i++) {
      if (_list[i].productionHouseIdentifier === productionHouseIdentifier) {
        _list[i] = userResponse;
      }
    }
    yield put({
      type: actions.EDIT_PRODUCTION_HOUSE_SUCCESS,
      payload: {
        updatedProductionHouse: userResponse,
        updatedList: _list,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_PRODUCTION_HOUSE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}
/**
 * worker saga: Calls the upload production image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadProductionImage({
  payload: { file, fileCategory, fileDirectory, productionHouseIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_PRODUCTION_IMAGE_LOADING });
    //*** get data from reducer ***
    let { list } = yield select(getDataFromStore);
    //api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier: productionHouseIdentifier,
      }
    );
    const {
      userResponse: { productionImagePath },
    } = response;
    let _list = _.clone(list);
    for (let i = 0; i < _list.length; i++) {
      if (_list[i].productionHouseIdentifier === productionHouseIdentifier) {
        _list[i].productionImagePath = productionImagePath;
      }
    }
    // send parsed data into action payload
    yield put({
      type: actions.UPLOAD_PRODUCTION_IMAGE_SUCCESS,
      payload: {
        productionImagePath,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_PRODUCTION_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}
const getDataFromStore = ({ productionHouses }) => {
  const { searchText, filter, list } = productionHouses;
  return {
    searchText,
    filter,
    list,
  };
};
// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(
    actions.CREATE_PRODUCTION_HOUSE_REQUEST,
    createProductionHouse
  );
  yield takeLatest(
    actions.FETCH_PRODUCTION_HOUSE_LIST_REQUEST,
    fetchProductionHouseList
  );
  yield takeLatest(actions.EDIT_PRODUCTION_HOUSE_REQUEST, editProductionHouse);
  yield takeLatest(
    actions.UPLOAD_PRODUCTION_IMAGE_REQUEST,
    uploadProductionImage
  );
}
