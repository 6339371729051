/* eslint-disable no-unused-vars */
import { call, takeLatest, put } from 'redux-saga/effects';
import actions from './actions';
import { api } from '@api';

/**
 * worker saga: Calls the videoFileUpload API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload       
 */
function* uploadVideo({ payload: { broadcastIdentifier, file, fileName, mimeType, fileSize } }) {

  try {
    yield put({ type: actions.UPLOAD_VIDEO_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.videoFileUpload },
      { broadcastIdentifier, fileName, mimeType, fileSize },
    );

    // parse the data from response
    const {
      userResponse: {
        presignedUrl,
        uploadIdentifier,
      }
    } = response;

    yield put({
      type: actions.UPLOAD_VIDEO_SUCCESS,
    });

    yield put({
      type: actions.PRESIGNED_URL_REQUEST,
      payload: {
        presignedUrl,
        file,
        uploadIdentifier,
        broadcastIdentifier,
      },
    });

  }
  catch (error) {
    yield put({
      type: actions.UPLOAD_VIDEO_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the presigned url API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload       
 */
function* requestPresignedUrl({ payload: { presignedUrl, file, uploadIdentifier, broadcastIdentifier } }) {

  try {
    yield put({ type: actions.PRESIGNED_URL_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.requestPresignedUrl },
      { presignedUrl, file },
    );

    // parse the data from response
    const {
      userResponse
    } = response;

    yield put({
      type: actions.PRESIGNED_URL_SUCCESS,
    });

    yield put({
      type: actions.UPLOAD_STATUS_REQUEST,
      payload: {
        broadcastIdentifier,
        uploadIdentifier,
      },
    });

  }
  catch (error) {
    yield put({
      type: actions.PRESIGNED_URL_ERROR,
      payload: {
        errorCode: error.name,
        errorMessage: error.message,
        errorStatus: error.status,
      },
    });
  }
}

/**
 * worker saga: Calls the upload status API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload       
 */
function* uploadStatus({ payload: { broadcastIdentifier, uploadIdentifier } }) {

  try {
    yield put({ type: actions.UPLOAD_STATUS_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadStatus },
      { broadcastIdentifier, uploadIdentifier },
    );

    yield put({
      type: actions.UPLOAD_STATUS_SUCCESS,
    });

  }
  catch (error) {
    yield put({
      type: actions.UPLOAD_STATUS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actions.UPLOAD_VIDEO_REQUEST, uploadVideo);
  yield takeLatest(actions.PRESIGNED_URL_REQUEST, requestPresignedUrl);
  yield takeLatest(actions.UPLOAD_STATUS_REQUEST, uploadStatus);
}