export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/signin",
  FORGOT_PASSWORD: "/forgot-password",
  PAGE_404: "/404",
  PAGE_500: "/500",
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "",
  MOVIE: "movies",
  SERIES: "series",
  SOURCES: "sources",
  TICKETS: "tickets",
  GIFT_TICKETS: "gift-tickets",
  INVOICES: "invoices",
  MEMBERS: "members",
  ADMINS: "admins",
  REPORT: "report",
  SUMMARY_REPORT: "summary-report",
  PORFILE: "profile",
  CHANGE_PASSWORD: "change-password",
  PERIOD_REPORT: "report",
  FAQS: "faqs",
  PRODUCTION_HOUSE: "production-house",
  GENRE: "genre",
  PAY_COLLECTIONS: "pay-collections",
  DOCUMENTARIES: "documentaries",
  VARIETY_SHOW: "variety-shows",
  DHAMMA: "dhamma",
  ENJOY_FREE: "enjoy-free",
  PROMOTION_PACKAGE: "promotion-package",
};
