/* eslint-disable no-unused-vars */
import { call, takeLatest, put, select } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import _ from "lodash";
import { LIMITS } from "@constants";

/**
 * worker saga: Calls the fetch ticket list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* fetchInvoiceList({ payload: { page = 1 } }) {

  try {
    yield put({ type: actions.FETCH_INVOICE_LIST_LOADING });

    const {
      list: extList,
      searchText: keyword,
      entityIdentifier,
      entityType,
      startDate,
      endDate
    } = yield select(getDataFromStore);

    const response = yield call(
      { context: api, fn: api.fetchInvoiceList },
      { page, startDate, endDate, entityIdentifier, entityType, keyword }
    );

    // parse the data from response
    const { userResponse } = response;

    const newList = userResponse;
    const title = userResponse?.title;

    yield put({
      type: actions.FETCH_INVOICE_LIST_SUCCESS,
      payload: {
        list: page === 1 ? newList : [...extList, ...newList],
        hasMore: newList.length >= LIMITS.PAGE_SIZE,
        showTitle: title,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_INVOICE_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}
/**
 * worker saga: Calls the fetch ticket list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* fetchTicketList({ payload: { page = 1, ticketInvoiceIdentifier } }) {
  try {
    yield put({ type: actions.FETCH_TICKET_LIST_LOADING });

    const { ticketList: extList } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.fetchTicketList },
      { page, ticketInvoiceIdentifier }
    );

    // parse the data from response
    const { userResponse } = response;

    const newList = userResponse?.ticketList;
    yield put({
      type: actions.FETCH_TICKET_LIST_SUCCESS,
      payload: {
        ticketList: page === 1 ? newList : [...extList, ...newList],
        hasMoreTicketList: newList.length >= LIMITS.PAGE_SIZE,
        userResponse,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_TICKET_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch ticket invoice details API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* fetchInvoiceDetails({ payload: { ticketInvoiceIdentifier, page } }) {
  try {
    yield put({ type: actions.FETCH_INVOICE_DETAILS_LOADING });

    const { invoiceDetailsList: extList } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.fetchInvoiceDetails },
      { ticketInvoiceIdentifier, page }
    );

    //parse the data from response
    const { userResponse } = response;

    const newList = userResponse.ticketList;

    yield put({
      type: actions.FETCH_INVOICE_DETAILS_SUCCESS,
      payload: {
        invoiceDetailsList: page === 1 ? newList : [...extList, ...newList],
        hasMoreInvoice: newList.length >= LIMITS.PAGE_SIZE,
        userResponse,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_INVOICE_DETAILS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* changeTicketToNewStatus({
  payload: { ticketSaleDetailIdentifier, statusName },
}) {
  try {
    yield put({ type: actions.CHANGE_TICKET_STATUS_LOADING });

    //*** get data from reducer ***
    let { invoiceDetailsList } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.changeTicketToNewStatus },
      { ticketSaleDetailIdentifier, statusName }
    );

    let _invoiceDetailsList = _.clone(invoiceDetailsList);
    for (let i = 0; i < _invoiceDetailsList.length; i++) {
      if (
        _invoiceDetailsList[i].ticketSaleDetailIdentifier ===
        ticketSaleDetailIdentifier
      ) {
        _invoiceDetailsList[i].ticketStatus = statusName;
      }
    }

    yield put({
      type: actions.CHANGE_TICKET_STATUS_SUCCESS,
      payload: {
        updatedInvoiceDetailsList: _invoiceDetailsList,
      },
    });
  } catch (error) {
    yield put({
      type: actions.CHANGE_TICKET_STATUS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ invoices }) => {
  const {
    list,
    searchText,
    hasMore,
    invoiceDetailsList,
    entityIdentifier,
    entityType,
    startDate,
    endDate
  } = invoices;

  return {
    list,
    searchText,
    hasMore,
    invoiceDetailsList,
    entityIdentifier,
    entityType,
    startDate,
    endDate
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.FETCH_INVOICE_LIST_REQUEST, fetchInvoiceList);
  yield takeLatest(actions.FETCH_TICKET_LIST_REQUEST, fetchTicketList);
  yield takeLatest(actions.FETCH_INVOICE_DETAILS_REQUEST, fetchInvoiceDetails);
  yield takeLatest(
    actions.CHANGE_TICKET_STATUS_REQUEST,
    changeTicketToNewStatus
  );
}
