import { call, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { message } from "antd";

/**
 * worker saga: Calls the create venue API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* createGallery({ payload }) {
  try {
    yield put({ type: actions.CREATE_GALLERY_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.createGallery },
      { ...payload }
    );

    // parse the data from response
    const {
      userResponse: { galleryList },
    } = response;

    yield put({
      type: actions.CREATE_GALLERY_SUCCESS,
      payload: {
        list: [...galleryList],
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_GALLERY_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* deleteGallery({ payload: { galleryIdentifier } }) {
  try {
    yield put({ type: actions.DELETE_GALLERY_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteGallery },
      { galleryIdentifier }
    );

    const {
      userResponse: { galleryList },
    } = response;

    yield put({
      type: actions.DELETE_GALLERY_SUCCESS,
      payload: {
        list: [...galleryList],
      },
    });
  } catch (error) {
    message.error(error.message);

    yield put({
      type: actions.DELETE_GALLERY_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.CREATE_GALLERY_REQUEST, createGallery);
  yield takeLatest(actions.DELETE_GALLERY_REQUEST, deleteGallery);
}
