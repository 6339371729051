import { Status } from '@api';
import actions from './actions';
import appActions from '@iso/redux/app/actions';

const initState = {
  sessionId: null,
  loginStatus: Status.DEFAULT,
  loginErrorMessage: '',
  logoutStatus: Status.DEFAULT,
  logoutErrorMessage: '',
  sessionStatus: Status.DEFAULT,
};

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOGIN_LOADING:
      return {
        ...state,
        loginStatus: Status.LOADING,
        loginErrorMessage: '',
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        sessionId: payload.sessionId,
        loginStatus: Status.SUCCESS,
        loginErrorMessage: '',
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loginStatus: Status.ERROR,
        loginErrorMessage: payload.errorMessage,
      };
    case actions.LOGOUT_LOADING:
      return {
        ...state,
        logoutStatus: Status.LOADING,
        logoutErrorMessage: '',
      }
    case actions.LOGOUT_ERROR:
      return {
        ...state,
        logoutStatus: Status.ERROR,
        logoutErrorMessage: payload.errorMessage,
      }
    case appActions.SESSION_EXPIRED:
      return {
        ...state,
        sessionStatus: Status.ERROR,
      }
    case actions.LOGOUT_SUCCESS:
      return initState;
    default:
      return state;
  }
}
