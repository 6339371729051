import { call, put, select, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { LIMITS } from "@constants";
import { message } from "antd";

/**
 * worker saga: Calls the fetch PackageList list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchPackageList({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_PACKAGE_LIST_LOADING });

    let {
      searchText: keyword,
      filter: statusName,
      list: extList,
    } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.fetchPackageList },
      { page, keyword, statusName }
    );

    // parse the data from response
    const {
      userResponse: { promotionList: newList, count },
    } = response;

    yield put({
      type: actions.FETCH_PACKAGE_LIST_SUCCESS,
      payload: {
        count,
        list: page === 1 ? newList : [...extList, ...newList],
        hasMore: newList?.length >= LIMITS.PAGE_SIZE,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_PACKAGE_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the create FAQ API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* createPackage({ payload }) {
  try {
    yield put({ type: actions.CREATE_PACKAGE_LOADING });

    const { list } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.createPackage },
      { ...payload }
    );

    // parse the data from response
    const { userResponse: newPackage } = response;

    let newPackageList = [newPackage, ...list];

    yield put({
      type: actions.CREATE_PACKAGE_SUCCESS,
      payload: {
        newPackageList,
        newPackage,
        count: newPackage.count,
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_PACKAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the delete FAQ API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */

function* deletePackage({ payload: { packageIdentifier } }) {
  try {
    yield put({ type: actions.DELETE_PACKAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deletePackage },
      { packageIdentifier }
    );

    // const { userResponse } = response;

    message.success("Promotion Package was deleted successfully!");

    const { list } = yield select(getDataFromStore);
    let filteredList = list.filter(
      (item) => item.packageIdentifier !== packageIdentifier
    );

    yield put({
      type: actions.DELETE_PACKAGE_SUCCESS,
      payload: {
        list: filteredList,
        //   count,
      },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_PACKAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* fetchPackageDetails({ payload: { packageIdentifier } }) {
  try {
    yield put({ type: actions.FETCH_PACKAGE_DETAIL_LOADING });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchPackageDetails },
      { packageIdentifier }
    );

    // parse the data from response
    const { userResponse: packageDetails } = response;

    yield put({
      type: actions.FETCH_PACKAGE_DETAIL_SUCCESS,
      payload: {
        packageDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_PACKAGE_DETAIL_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* editPackage({ payload }) {
  try {
    yield put({ type: actions.EDIT_PACKAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.editPackage },
      { ...payload }
    );

    // parse the data from response
    const { userResponse: packageDetails } = response;

    yield put({
      type: actions.EDIT_PACKAGE_SUCCESS,
      payload: {
        packageDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_PACKAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ promotionPackage }) => {
  const { searchText, filter, list } = promotionPackage;
  return {
    searchText,
    filter,
    list,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.FETCH_PACKAGE_LIST_REQUEST, fetchPackageList);
  yield takeLatest(actions.CREATE_PACKAGE_REQUEST, createPackage);
  yield takeLatest(actions.DELETE_PACKAGE_REQUEST, deletePackage);
  yield takeLatest(actions.FETCH_PACKAGE_DETAIL_REQUEST, fetchPackageDetails);
  yield takeLatest(actions.EDIT_PACKAGE_REQUEST, editPackage);
}
