import { ADMIN_SK, PROFILE_PHOTO_SK } from "@constants";
const actions = {
  CREATE_ADMIN_REQUEST: 'CREATE_ADMIN_REQUEST',
  CREATE_ADMIN_LOADING: 'CREATE_ADMIN_LOADING',
  CREATE_ADMIN_SUCCESS: 'CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_ERROR: 'CREATE_ADMIN_ERROR',

  FETCH_ADMIN_LIST_REQUEST: 'FETCH_ADMIN_LIST_REQUEST',
  FETCH_ADMIN_LIST_LOADING: 'FETCH_ADMIN_LIST_LOADING',
  FETCH_ADMIN_LIST_SUCCESS: 'FETCH_ADMIN_LIST_SUCCESS',
  FETCH_ADMIN_LIST_ERROR: 'FETCH_ADMIN_LIST_ERROR',

  FETCH_ADMIN_DETAILS_REQUEST: 'FETCH_ADMIN_DETAILS_REQUEST',
  FETCH_ADMIN_DETAILS_LOADING: 'FETCH_ADMIN_DETAILS_LOADING',
  FETCH_ADMIN_DETAILS_SUCCESS: 'FETCH_ADMIN_DETAILS_SUCCESS',
  FETCH_ADMIN_DETAILS_ERROR: 'FETCH_ADMIN_DETAILS_ERROR',

  EDIT_ADMIN_REQUEST: 'EDIT_ADMIN_REQUEST',
  EDIT_ADMIN_LOADING: 'EDIT_ADMIN_LOADING',
  EDIT_ADMIN_SUCCESS: 'EDIT_ADMIN_SUCCESS',
  EDIT_ADMIN_ERROR: 'EDIT_ADMIN_ERROR',
  RESET_EDIT_ADMIN_STATE: 'RESET_EDIT_ADMIN_STATE',

  EDIT_CURRENT_ADMIN_REQUEST: 'EDIT_CURRENT_ADMIN_REQUEST',
  EDIT_CURRENT_ADMIN_LOADING: 'EDIT_CURRENT_ADMIN_LOADING',
  EDIT_CURRENT_ADMIN_SUCCESS: 'EDIT_CURRENT_ADMIN_SUCCESS',
  EDIT_CURRENT_ADMIN_ERROR: 'EDIT_CURRENT_ADMIN_ERROR',
  RESET_EDIT_CURRENT_ADMIN_STATE: 'RESET_EDIT_CURRENT_ADMIN_STATE',

  UPLOAD_ADMIN_IMAGE_REQUEST: 'UPLOAD_ADMIN_IMAGE_REQUEST',
  UPLOAD_ADMIN_IMAGE_LOADING: 'UPLOAD_ADMIN_IMAGE_LOADING',
  UPLOAD_ADMIN_IMAGE_SUCCESS: 'UPLOAD_ADMIN_IMAGE_SUCCESS',
  UPLOAD_ADMIN_IMAGE_ERROR: 'UPLOAD_ADMIN_IMAGE_ERROR',

  RESET_ADMIN_LIST_STATE: 'RESET_ADMIN_LIST_STATE',
  // SEARCH
  SET_ADMINS_SEARCH_TEXT: 'SET_ADMINS_SEARCH_TEXT',
  SET_ADMIN_FILTER: 'SET_ADMIN_FILTER',
  EDIT_VIEW: 'EDIT_VIEW',
  SET_SELECTED_ADMIN: 'SET_SELECTED_ADMIN',
  CREATE_VIEW: 'CREATE_VIEW',

  createAdmin: ({
    adminName,
    email,
    adminRole,
    gender,
    mobileNumber,
    address,
    statusName,
  }) => {
    return {
      type: actions.CREATE_ADMIN_REQUEST,
      payload: {
        adminName,
        email,
        adminRole,
        gender,
        mobileNumber,
        address,
        statusName,
      }
    }
  },

  fetchAdminList: page => {
    return {
      type: actions.FETCH_ADMIN_LIST_REQUEST,
      payload: {
        page,
      }
    }
  },

  resetAdminListState: ()=>{
    return {
       type: actions.RESET_ADMIN_LIST_STATE,
    }
  },

  editAdmin: ({
    adminName,
    email,
    adminRole,
    gender,
    mobileNumber,
    address,
    statusName,
    adminIdentifier
  }) => {
    return {
      type: actions.EDIT_ADMIN_REQUEST,
      payload: {
        adminName,
        email,
        adminRole,
        gender,
        mobileNumber,
        address,
        statusName,
        adminIdentifier
      }
    }
  },

  resetEditAdminState: () => ({ type: actions.RESET_EDIT_ADMIN_STATE }),

  editCurrentAdmin: ({
    adminName,
    email,
    adminRole,
    gender,
    mobileNumber,
    address,
    statusName,
    adminIdentifier
  }) => {
    return {
      type: actions.EDIT_CURRENT_ADMIN_REQUEST,
      payload: {
        adminName,
        email,
        adminRole,
        gender,
        mobileNumber,
        address,
        statusName,
        adminIdentifier
      }
    }
  },

  resetEditCurrentAdminState: () => ({ type: actions.RESET_EDIT_CURRENT_ADMIN_STATE }),

  setSearchText: payload => {
    return {
      type: actions.SET_ADMINS_SEARCH_TEXT,
      payload,
    };
  },

  setFilter: payload => {
    return {
      type: actions.SET_ADMIN_FILTER,
      payload,
    }
  },

  setSelectedAdmin: admin => {
    return {
      type: actions.SET_SELECTED_ADMIN,
      payload: {
        admin,
      },
    }
  },

  fetchAdminDetails: () => ({ type: actions.FETCH_ADMIN_DETAILS_REQUEST }),

  viewChange: view => ({
    type: actions.EDIT_VIEW,
    payload: {
      view,
    },
  }),

  setCreateAdmin: creating => ({
    type: actions.CREATE_VIEW,
    payload: {
      creating,
    }
  }),

  uploadAdminUserImage: (file, entityIdentifier, fileDirectory = PROFILE_PHOTO_SK, fileCategory = ADMIN_SK) => {
    return {
      type: actions.UPLOAD_ADMIN_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },
};
export default actions;
