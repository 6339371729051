//====================================================
//================ Admin Roles =======================
//====================================================
export const EXECUTIVE_SK = "executive";
export const HOST_SK = "host";
//====================================================
//================ Entity Status =====================
//====================================================
export const DRAFTED_SK = "drafted";
export const PUBLISHED_SK = "published";
export const UNPUBLISHED_SK = "unpublished";
export const EXPIRED_SK = "expired";
export const OUTDATE_SK = "outdate";
export const UP_COMING_SK = "upcoming";
export const DELETE_SK = "deleted";
//====================================================
//================ Entity Types ======================
//====================================================

export const PRODUCTION = "production";
export const POSTER_SK = "poster";
export const VENUE_SK = "venue";
export const EVENT_SK = "event";
export const ADMIN_SK = "admin";
//====================================================
//================ Venue Types =======================
//====================================================
export const VIDEO_SK = "video";
export const LIVE_SK = "live";
//====================================================
//================ Privacy Types =====================
//====================================================
export const PREMIUM_SK = "premium";
export const FREEBIE_SK = "free to enjoy";
//====================================================
//================ File Directory ====================
//====================================================
export const PROFILE_PHOTO_SK = "profilePhoto";
export const PORTRAIT_PHOTO_SK = "portraitPhoto";
export const LANDSCAPE_PHOTO_SK = "landscapePhoto";
export const THUMBNAIL_PHOTO_SK = "thumbnailPhoto";
export const MOBILE_BANNER_PHOTO_SK = "mobileBannerPhoto";
export const WEB_BANNER_PHOTO_SK = "webBannerPhoto";

export const LIGHT_PHOTO_SK = "lightPhoto";
export const DARK_PHOTO_SK = "darkPhoto";
export const LANDSCAPE_GIF_PHOTO_SK = "landscapeGifPhoto";
export const PORTRAIT_GIF_PHOTO_SK = "portraitGifPhoto";
//====================================================
//======= Admin/Organization/Member Status ===========
//====================================================
export const ACTIVE_SK = "active";
export const INACTIVE_SK = "inactive";
//====================================================
//================ Upload Status =====================
//====================================================
export const CREATED_SK = "created";
export const UPLOADED_SK = "uploaded";
export const PROCESSING_SK = "processing";
export const COMPLETED_SK = "completed";
export const FAILED_SK = "failed";
//====================================================
//================ Source Type =======================
//====================================================
export const RTMP_SK = "rtmp";
export const BOXCASTER_SK = "boxcaster";
export const REBROADCAST_SK = "recording";
//====================================================
//================ Ticket Status =====================
//====================================================
export const NEW_SK = "new";
export const USED_SK = "used";

//====================================================
//================== Timeframe =======================
//====================================================
export const PAST_SK = "past";
export const FUTURE_SK = "future";

//====================================================
//=============== Period for report ==================
//====================================================
export const MONTHLY_SK = "monthly";
export const QUARTERLY_SK = "quarterly";
export const ANNUAL_SK = "annual";

//====================================================
//=============== File Category ==================
//====================================================
export const MOVIE_SK = "movie";
export const SERIES_SK = "series";
export const CAST_SK = "cast";
export const EPISODE_SK = "episode";
export const TV_PROGRAM_SK = "tvprogram";
export const TV_PROGRAM_EPISODE_SK = "tvprogram episode";
export const DOCUMENTARIES_SK = "documentaries";
export const VARIETY_SHOWS_SK = "variety shows";
export const DHAMMA_SK = "dhamma";

//====================================================
//=============== Local Entity Type ==================
//====================================================
export const PAY_COLLECTIONS = "pay-collections";
export const ENJOY_FREE = "enjoy-free";
export const DOCUMENTARIES_LK = "documentaries";
export const VARIETY_SHOWS_LK = "variety-shows";
export const DHAMMA_LK = "dhamma";
export const SERIES_LK = "series";

export const DesiredActionOption = [
  {
    value: UP_COMING_SK,
    label: "Upcoming",
  },
  {
    value: PUBLISHED_SK,
    label: "Published",
  },
  {
    value: UNPUBLISHED_SK,
    label: "Unpublished",
  },
  {
    value: DRAFTED_SK,
    label: "Drafted",
  },
];

//====================================================
//=============== ShowLike Video PrefixUrl ===========
//====================================================
export const BUNNY_PLAYER_BASE_URL = "https://iframe.mediadelivery.net/";
export const GD_PLAYER_BASE_URL = "https://";
