/* eslint-disable array-callback-return */
import { call, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";

import { message } from "antd";

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadPortraitImage({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_PORTRAIT_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Portrait photo is uploaded successfully!");
    const {
      userResponse: { portraitImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_PORTRAIT_IMAGE_SUCCESS,
      payload: {
        portraitImageUrl: portraitImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_PORTRAIT_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadLandscapeImage({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_LANDSCAPE_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Landscape photo is uploaded successfully!");
    const {
      userResponse: { landscapeImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_LANDSCAPE_IMAGE_SUCCESS,
      payload: {
        landscapeImageUrl: landscapeImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_LANDSCAPE_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadLightPhoto({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_LIGHT_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Light photo is uploaded successfully!");
    const {
      userResponse: { lightImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_LIGHT_IMAGE_SUCCESS,
      payload: {
        lightImageUrl: lightImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_LIGHT_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadDarkPhoto({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_DARK_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Dark photo is uploaded successfully!");
    const {
      userResponse: { darkImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_DARK_IMAGE_SUCCESS,
      payload: {
        darkImageUrl: darkImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_DARK_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadLandscapeGifPhoto({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_LANDSCAPE_GIF_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Landscape GIF photo is uploaded successfully!");
    const {
      userResponse: { landscapeGifImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_LANDSCAPE_GIF_IMAGE_SUCCESS,
      payload: {
        landscapeGifUrl: landscapeGifImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_LANDSCAPE_GIF_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadPortraitGifPhoto({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_PORTRAIT_GIF_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Portrait GIF photo is uploaded successfully!");
    const {
      userResponse: { portraitGifImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_PORTRAIT_GIF_IMAGE_SUCCESS,
      payload: {
        portraitGifUrl: portraitGifImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_PORTRAIT_GIF_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadMobileBannerPhoto({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_MOBILE_BANNER_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Banner photo is uploaded successfully!");
    const {
      userResponse: { mobileBannerImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_MOBILE_BANNER_IMAGE_SUCCESS,
      payload: {
        mobileBannerImageUrl: mobileBannerImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_MOBILE_BANNER_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* uploadWebBannerPhoto({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_WEB_BANNER_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Banner photo is uploaded successfully!");
    const {
      userResponse: { webBannerImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_WEB_BANNER_IMAGE_SUCCESS,
      payload: {
        webBannerImageUrl: webBannerImagePath.originalUrl,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_WEB_BANNER_IMAGE_SUCCESS,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.UPLOAD_PORTRAIT_IMAGE_REQUEST, uploadPortraitImage);
  yield takeLatest(
    actions.UPLOAD_LANDSCAPE_IMAGE_REQUEST,
    uploadLandscapeImage
  );
  yield takeLatest(actions.UPLOAD_LIGHT_IMAGE_REQUEST, uploadLightPhoto);
  yield takeLatest(actions.UPLOAD_DARK_IMAGE_REQUEST, uploadDarkPhoto);
  yield takeLatest(
    actions.UPLOAD_LANDSCAPE_GIF_IMAGE_REQUEST,
    uploadLandscapeGifPhoto
  );
  yield takeLatest(
    actions.UPLOAD_PORTRAIT_GIF_IMAGE_REQUEST,
    uploadPortraitGifPhoto
  );
  yield takeLatest(
    actions.UPLOAD_MOBILE_BANNER_IMAGE_REQUEST,
    uploadMobileBannerPhoto
  );
  yield takeLatest(
    actions.UPLOAD_WEB_BANNER_IMAGE_REQUEST,
    uploadWebBannerPhoto
  );
}
