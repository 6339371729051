const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_LOADING: 'LOGIN_LOADING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_LOADING: 'LOGOUT_LOADING',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (email, password) => ({
    type: actions.LOGIN_REQUEST,
    payload: {
      email,
      password,
    },
  }),
  logout: (isSessionExpired) => ({
    type: actions.LOGOUT_REQUEST,
    payload: {
      isSessionExpired,
    },
  }),
};
export default actions;
