import "moment/locale/my";
import "moment/locale/en-in";

import {
  DRAFTED_SK,
  PUBLISHED_SK,
  UNPUBLISHED_SK,
  EXPIRED_SK,
  LIMITS,
} from "@constants";
import { message } from "antd";
import { 
  BUNNY_PLAYER_BASE_URL, 
  DHAMMA_LK, 
  DHAMMA_SK, 
  DOCUMENTARIES_LK, 
  DOCUMENTARIES_SK, 
  GD_PLAYER_BASE_URL, 
  SERIES_LK, 
  SERIES_SK, 
  VARIETY_SHOWS_LK 
} from "../../constants/stringConstants";

const moment = require("moment");
const momentTz = require("moment-timezone");

const LOCAL_STORAGE_KEYS = {
  sessionId: "SessionId",
  adminIdentifier: "AdminIdentifier",
  adminRole: "AdminRole",
  topBarTheme: "topbarTheme",
  siteBarTheme: "sidebarTheme",
  layoutTheme: "layoutTheme",
  changeTheme: "changeThemes",
};

export function setToken(value) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.sessionId, value);
}

export function clearToken() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.sessionId);
}

export function getToken() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.sessionId);
  } catch (err) {
    clearToken();
    return null;
  }
}

export function setAdminIdentifier(value) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.adminIdentifier, value);
}

export function clearAdminIdentifier() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.adminIdentifier);
}

export function getAdminIdentifier() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.adminIdentifier);
  } catch (err) {
    clearAdminIdentifier();
    return null;
  }
}

export function setAdminRole(value) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.adminRole, value);
}

export function clearAdminRole() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.adminRole);
}

export function getAdminRole() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.adminRole);
  } catch (err) {
    clearAdminRole();
    return null;
  }
}

export function setThemes(key, value) {
  localStorage.setItem(key, value);
}

export function getTopBarTheme() {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.topBarTheme);
}

export function getSiteBarTheme() {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.siteBarTheme);
}

export function getLayoutTheme() {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.layoutTheme);
}

export function getChangeThemes() {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.changeTheme);
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

/**
 *
 * @returns current device informations
 */
export function getDeviceInfo() {
  return {
    deviceId: window.navigator.userAgent,
    deviceName: window.navigator.userAgent,
  };
}

/**
 * @returns object containing ISO 8601 and RFC2822 formatted date
 */
export function getDate() {
  const currentDate = new Date(Date.now());
  let iso8601Date = currentDate.toISOString();

  iso8601Date = `${iso8601Date.split(".")[0]}+00:00`;

  const rfc2822Date = currentDate.toUTCString().replace("GMT", "+0000");

  return {
    iso8601Date,
    rfc2822Date,
  };
}

/**
 * return true if @a is object else false
 */
export const isObject = (a) => {
  return !!a && a.constructor === Object;
};

/**
 * return true is a is empty or non empty valid string
 */
export const isString = (a) => {
  return typeof a === "string";
};

/**
 * return true if a is non empty valid string
 */
export const isNonEmptyString = (a) => {
  return !!(isString(a) && a !== "");
};

/**
 * @param {String} date - local date
 * @return {String} formatted utc date
 */
export const localToUtc = (date) => {
  const myDate = moment(date).utc();

  if (myDate.isValid()) {
    return myDate.format(`YYYY-MM-DD HH:mm:ss`);
  }

  return "";
};

/**
 * @param {String} date - local date
 * @return {String} formatted utc date
 */
export const localToUtcNoTime = (date) => {
  const myDate = moment(date).utc();

  if (myDate.isValid()) {
    return myDate.format(`YYYY-MM-DD`);
  }

  return "";
};

/**
 * @param {String} date - ISO 8601 or RFC 2822 format
 * @return {String} formatted local date
 */
export const utcToLocal = (date) => {
  const myDate = moment.utc(date).local();

  if (myDate.isValid()) {
    return myDate;
  }

  return "";
};

export const getDesiredStatusOptions = (defaultOption) => {
  const options = [EXPIRED_SK, DRAFTED_SK, PUBLISHED_SK, UNPUBLISHED_SK];

  return options.filter(
    (opt, key) =>
      opt === defaultOption || key === options.indexOf(defaultOption) + 1
  );
};

/**
 * Use this method to show date in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 *
 * @return {string} formatted  date
 */
export function getFormattedDateTime(date) {
  let currentTimezone = momentTz.tz.guess();
  let format = "dddd, MMMM D, YYYY [at] h:mm A [UTC]Z";
  return momentTz.utc(date).tz(currentTimezone, true).format(format);
}

/**
 * Use this method to show date in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 * @param {bool} isUTC - if incoming date in UTC and we want to show in local time
 *
 * @return {string} formatted  date in format "MMMM DD, YYYY"
 */
export function formattedDate(date, isUTC = true) {
  const myDate = isUTC ? moment.utc(date).local() : moment(date);

  if (myDate.isValid()) {
    return myDate.format(`MMMM D, YYYY`);
  }

  return "";
}

/**
 * Use this method to show time in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 *
 * @return {string} formatted time
 */
export function getFormattedTime(date) {
  let currentTimezone = momentTz.tz.guess();
  let format = "h:mm A [UTC]Z";
  return momentTz.utc(date).tz(currentTimezone, true).format(format);
}

/**
 * @param {String} dates - utc date
 * @return true if current date is between start date and end date
 */
export function isBetweenTwoDates(startDate, endDate) {
  let currentDate = moment();
  return (
    currentDate >= utcToLocal(startDate) && currentDate <= utcToLocal(endDate)
  );
}

/**
 * Use this method to show date in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 * @param {bool} isUTC - if incoming date in UTC and we want to show in local time
 *
 * @return {string} formatted  date in format "D.M.YYYY"
 */
export function getformattedDate(date, isUTC = true) {
  const myDate = isUTC ? moment.utc(date).local() : moment(date);

  if (myDate.isValid()) {
    return myDate.format(`D.M.YYYY`);
  }

  return "";
}

/**
 * Use this method to check event is start or not
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 *
 * @return {boolean} false if current date is not reached at given datetime
 */
export function isLived(datetime) {
  let myDate = moment.utc(datetime);
  return moment().isAfter(myDate) || moment().isSame(myDate);
}

/**
 * Use this method to show formatted price in application
 *
 * @param {string}
 *
 * @return {string} formatted price
 */
export function getFormattedPrice(num) {
  // return String(x).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  // Convert number to string and split by decimal point
  let [integerPart, decimalPart] = num.toString().split(".");
  // Format the integer part with commas
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // If decimal part is ".00", remove it
  if (decimalPart === "00" || decimalPart === undefined) {
    return integerPart;
  } else {
    return integerPart + "." + decimalPart;
  }
}

/**
 * Use this method to check file size and type
 *
 * @param {File} file
 * @param {boolean} isVideo true if it's video
 *
 * @return {boolean} false if file type or size is not valid
 */
export function validateFile(file) {
  const isValidType =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/gif";

  if (!isValidType) {
    message.error("You can only upload JPG/PNG file!");
  }

  // const bytesOf1M = 1048576;
  // const isLt10M = file.size <= bytesOf1M * LIMITS.MAX_IMAGE_FILE_SIZE;

  // if (!isLt10M) {
  //   message.error("Image must be smaller than 10MB!");
  // }

  // return isValidType && isLt10M;
  return isValidType;
}

/**
 * Use this method to check video file size and type
 *
 * @param {File} file
 *
 * @return {boolean} false if file type or size is not valid
 */
export function validateVideoFile(file) {
  const isValidType =
    file.type === "video/mp4" || file.type === "video/quicktime";

  if (!isValidType) {
    message.error("You can only upload MP4/MOV file!");
  }

  const bytesOf1Gb = 1073741824;
  const isLt8GB = file.size <= bytesOf1Gb * LIMITS.MAX_VIDEO_FILE_SIZE;

  if (!isLt8GB) {
    message.error("Video must be smaller than 8GB!");
  }
  return isLt8GB && isValidType;
}

/**
 * Use this method to show table ID in application
 *
 * @param {String}
 * @return {string} formatted ID
 */
export function addLeadingZeros(num) {
  return String(num).padStart("3", "0");
}

export function checkProgramType(type) {
  if (type === DOCUMENTARIES_SK) {
    return { name: "Documentary", type };
  } else if (type === DHAMMA_SK) {
    return { name: "Dhamma", type };
  } else if (type === "variety-shows") {
    return { name: "Variety Show", type: "variety shows" };
  } else return;
}

export const descriptionValidator = (_, value) => {
  if (!value || value.length <= LIMITS.SHORT_DESCRIPTIONS_MAX_LENGTH) {
    return Promise.resolve();
  }
  return Promise.reject('Description must be 255 characters or less.');
};

export const episodeDescriptionValidator = (_, value) => {
  if (!value || value.length <= LIMITS.EPISODE_DESCRIPTIONS_MAX_LENGTH) {
    return Promise.resolve();
  }
  return Promise.reject('Description must be 8000 characters or less.');
};

export const titleValidator = (_, value) => {
  if (!value || value.length <= 255) {
    return Promise.resolve();
  }
  return Promise.reject('Title must be 255 characters or less.');
};

export const seriesNumberValidator = (_, value) => {
  if (!value || value >= 1) {
    return Promise.resolve();
  }
  return Promise.reject('Number must be greater than or equal to 1.');
};

export const prefixCodeValidator = (_, value) => {
  const regex = /^[A-Za-z]{3}$/;
  if (!value || regex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject('Prefix Code must be 3 uppercase letters.');
};

export const bunnyUrlValidator = (_, value) => {
  if (!value || value.includes(BUNNY_PLAYER_BASE_URL)) {
    return Promise.resolve();
  }
  return Promise.reject('The URL is invalid. Please provide the correct Bunny URL.');
};

export const gdUrlValidator = (_, value) => {
  if (!value || value.includes(GD_PLAYER_BASE_URL)) {
    return Promise.resolve();
  }
  return Promise.reject('The URL is invalid. Please provide the correct Video URL.');
};

export const getEntityType = (type) => {
  switch (type) {
    case VARIETY_SHOWS_LK:
      return "Variety Show";
    case DOCUMENTARIES_LK:
      return "Documentarie";
    case DHAMMA_LK:
      return "Dhamma";
    case SERIES_LK:
      return "Serie";
    default:
      return null;
  }
}