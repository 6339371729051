import { call, put, select, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import _ from "lodash";
import { message } from "antd";


/**
 * worker saga: Calls the Genre create organization API.
 */
function* createGenre({ payload: {
  name,
  nameMy,
} }) {
  try {
    yield put({ type: actions.CREATE_GENRE_LOADING });

    /*** get data from reducer ***/
    let {
      list,
    } = yield select(getDataFromStore);

    //api call
    const response = yield call(
      { context: api, fn: api.createGenre },
      { name, nameMy }
    );

    // parse the data from response
    const { userResponse: newGenre } = response;

    let newGenreList = [
      newGenre,
      ...list,
    ]

    yield put({
      type: actions.CREATE_GENRE_SUCCESS,
      payload: {
        newGenreList,
        newGenre
      }
    })
  }
  catch (error) {
    yield put({
      type: actions.CREATE_GENRE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch Genre list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchGenreList({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_GENRE_LIST_LOADING });

    let {
      searchText: keyword,
      filter: statusName,
      list: extList,
    } = yield select(getDataFromStore);

    //api call
    const response = yield call(
      { context: api, fn: api.fetchGenreList },
      { page, keyword, statusName }
    );

    // // parse the data from response
    // const {
    //   userResponse: {
    //     genreList: newList },
    // } = response;

    const { userResponse: newList } = response;

    const _list = page === 1 ? newList : [...extList, ...newList];

    yield put({
      type: actions.FETCH_GENRE_LIST_SUCCESS,
      payload: {
        list: _list,
        hasMore: newList.length >= 12,
        selectedGenre: _list[0],
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_GENRE_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the Put edit Genre API.
 */
function* editGenre({ payload: {
  name,
  nameMy,
  genreIdentifier
} }) {
  try {
    yield put({ type: actions.EDIT_GENRE_LOADING });

    //*** get data from reducer ***
    let {
      list,
    } = yield select(getDataFromStore);

    //api call
    const response = yield call(
      { context: api, fn: api.editGenre },
      { name, nameMy, genreIdentifier }
    );

    // parse the data from response
    const {
      userResponse
    } = response;

    let _list = _.clone(list);
    for (let i = 0; i < _list.length; i++) {
      if (_list[i].genreIdentifier === genreIdentifier) {
        _list[i] = userResponse
      }
    }

    yield put({
      type: actions.EDIT_GENRE_SUCCESS,
      payload: {
        updatedGenre: userResponse,
        updatedList: _list
      },
    });
  }
  catch (error) {
    yield put({
      type: actions.EDIT_GENRE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    })
  }
}

/**
 * worker saga: Calls the delete Genre API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload       
 */
function* deleteGenre({ payload: { genreIdentifier } }) {
  try {
    yield put({ type: actions.DELETE_GENRE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteGenre },
      { genreIdentifier },
    );

    // const { userResponse } = response;

    message.success('Genre deleted successfully!');

    const { list } = yield select(getDataFromStore);
    let filteredList = list.filter(item => item.genreIdentifier !== genreIdentifier);

    yield put({
      type: actions.DELETE_GENRE_SUCCESS,
      payload: {
        list: filteredList,
        selectedGenre: filteredList[0],
      },
    });
  }
  catch (error) {
    yield put({
      type: actions.DELETE_GENRE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ genres }) => {
  const { searchText, filter, list } = genres;
  return {
    searchText,
    filter,
    list,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.CREATE_GENRE_REQUEST, createGenre);
  yield takeLatest(actions.FETCH_GENRE_LIST_REQUEST, fetchGenreList);
  yield takeLatest(actions.EDIT_GENRE_REQUEST, editGenre);
  yield takeLatest(actions.DELETE_GENRE_REQUEST, deleteGenre);
}
