const productionHouseActions = {
    CREATE_PRODUCTION_HOUSE_REQUEST: 'CREATE_PRODUCTION_HOUSE_REQUEST',
    CREATE_PRODUCTION_HOUSE_LOADING: 'CREATE_PRODUCTION_HOUSE_LOADING',
    CREATE_PRODUCTION_HOUSE_SUCCESS: 'CREATE_PRODUCTION_HOUSE_SUCCESS',
    CREATE_PRODUCTION_HOUSE_ERROR: 'CREATE_PRODUCTION_HOUSE_ERROR',

    FETCH_PRODUCTION_HOUSE_LIST_REQUEST: 'FETCH_PRODUCTION_HOUSE_LIST_REQUEST',
    FETCH_PRODUCTION_HOUSE_LIST_LOADING: 'FETCH_PRODUCTION_HOUSE_LIST_LOADING',
    FETCH_PRODUCTION_HOUSE_LIST_SUCCESS: 'FETCH_PRODUCTION_HOUSE_LIST_SUCCESS',
    FETCH_PRODUCTION_HOUSE_LIST_ERROR: 'FETCH_PRODUCTION_HOUSE_LIST_ERROR',

    EDIT_PRODUCTION_HOUSE_REQUEST: 'EDIT_PRODUCTION_HOUSE_REQUEST',
    EDIT_PRODUCTION_HOUSE_LOADING: 'EDIT_PRODUCTION_HOUSE_LOADING',
    EDIT_PRODUCTION_HOUSE_SUCCESS: 'EDIT_PRODUCTION_HOUSE_SUCCESS',
    EDIT_PRODUCTION_HOUSE_ERROR: 'EDIT_PRODUCTION_HOUSE_ERROR',
    RESET_PRODUCTION_HOUSE_STATE: 'RESET_PRODUCTION_HOUSE_STATE',

    UPLOAD_PRODUCTION_IMAGE_REQUEST: 'UPLOAD_PRODUCTION_IMAGE_REQUEST',
    UPLOAD_PRODUCTION_IMAGE_LOADING: 'UPLOAD_PRODUCTION_IMAGE_LOADING',
    UPLOAD_PRODUCTION_IMAGE_SUCCESS: 'UPLOAD_PRODUCTION_IMAGE_SUCCESS',
    UPLOAD_PRODUCTION_IMAGE_ERROR: 'UPLOAD_PRODUCTION_IMAGE_ERROR',

    RESET_PRODUCTION_HOUSE_LIST_STATE: 'RESET_PRODUCTION_HOUSE_LIST_STATE',
    SET_PRODUCTION_HOUSES_SEARCH_TEXT: 'SET_PRODUCTION_HOUSES_SEARCH_TEXT',
    SET_PRODUCTION_HOUSES_FILTER: 'SET_PRODUCTION_HOUSES_FILTER',
    EDIT_VIEW: 'EDIT_VIEW',
    SET_SELECTED_PRODUCTION_HOUSE: 'SET_SELECTED_PRODUCTION_HOUSE',
    CREATE_VIEW: 'CREATE_VIEW',

    createProductionHouse: ({
        name,
        nameMy,
        statusName,
    }) => {
        return {
            type: productionHouseActions.CREATE_PRODUCTION_HOUSE_REQUEST,
            payload: {
                name,
                nameMy,
                statusName,
            }
        }
    },

    fetchProductionHouseList: page => {
        return {
            type: productionHouseActions.FETCH_PRODUCTION_HOUSE_LIST_REQUEST,
            payload: {
                page,
            },
        };
    },

    resetProductionHouseListState: () => {
        return {
            type: productionHouseActions.RESET_PRODUCTION_HOUSE_LIST_STATE,
        };
    },

    editProductionHouse: ({
        name,
        nameMy,
        statusName,
        productionHouseIdentifier
    }) => {
        return {
            type: productionHouseActions.EDIT_PRODUCTION_HOUSE_REQUEST,
            payload: {
                name,
                nameMy,
                statusName,
                productionHouseIdentifier
            }
        }
    },

    resetProducitonHouseState: () => ({ type: productionHouseActions.RESET_PRODUCTION_HOUSE_STATE }),

    setSearchText: payload => {
        return {
            type: productionHouseActions.SET_PRODUCTION_HOUSES_SEARCH_TEXT,
            payload,
        };
    },

    setFilter: payload => {
        return {
            type: productionHouseActions.SET_PRODUCTION_HOUSES_FILTER,
            payload,
        }
    },

    setSelectedProductionHouse: productionHouse => {
        return {
            type: productionHouseActions.SET_SELECTED_PRODUCTION_HOUSE,
            payload: {
                productionHouse,
            },
        }
    },

    viewChange: view => ({
        type: productionHouseActions.EDIT_VIEW,
        payload: {
            view,
        },
    }),

    setCreateProductionHouse: creating => ({
        type: productionHouseActions.CREATE_VIEW,
        payload: {
            creating,
        }
    }),

    uploadProductionImage: (file, fileDirectory, fileCategory, productionHouseIdentifier) => {
        return {
            type: productionHouseActions.UPLOAD_PRODUCTION_IMAGE_REQUEST,
            payload: {
                file,
                fileCategory,
                fileDirectory,
                productionHouseIdentifier,
            },
        };
    },

};

export default productionHouseActions;
