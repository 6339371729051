import galleryActions from "./actions";
import { Status } from "@api";

const initialState = {
  createGalleryStatus: Status.DEFAULT,
  createGalleryErrorMessage: "",
  deleteGalleryStatus: Status.DEFAULT,
  deleteGalleryErrorMessage: "",
  list: [],
};

export default function galleryReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case galleryActions.CREATE_GALLERY_LOADING:
      return {
        ...state,
        createGalleryStatus: Status.LOADING,
        createGalleryErrorMessage: "",
      };
    case galleryActions.CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        createGalleryStatus: Status.SUCCESS,
        createGalleryErrorMessage: "",
        list: payload.list,
      };
    case galleryActions.CREATE_GALLERY_ERROR:
      return {
        ...state,
        createGalleryStatus: Status.ERROR,
        createGalleryErrorMessage: payload.errorMessage,
      };

    case galleryActions.DELETE_GALLERY_LOADING:
      return {
        ...state,
        deleteGalleryStatus: Status.LOADING,
        deleteGalleryErrorMessage: "",
      };
    case galleryActions.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        deleteGalleryStatus: Status.SUCCESS,
        deleteGalleryErrorMessage: "",
      };
    case galleryActions.DELETE_GALLERY_ERROR:
      return {
        ...state,
        deleteGalleryStatus: Status.ERROR,
        deleteGalleryErrorMessage: payload.errorMessage,
      };
    case galleryActions.RESET_GALLERY_STATE:
      return {
        createGalleryStatus: Status.DEFAULT,
        createGalleryErrorMessage: "",
        deleteGalleryStatus: Status.DEFAULT,
        deleteGalleryErrorMessage: "",
      };

    default:
      return state;
  }
}
