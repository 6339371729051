const seriesActions = {
  FETCH_SERIES_LIST_REQUEST: "FETCH_SERIES_LIST_REQUEST",
  FETCH_SERIES_LIST_LOADING: "FETCH_SERIES_LIST_LOADING",
  FETCH_SERIES_LIST_SUCCESS: "FETCH_SERIES_LIST_SUCCESS",
  FETCH_SERIES_LIST_ERROR: "FETCH_SERIES_LIST_ERROR",
  CREATE_SERIES_REQUEST: "CREATE_SERIES_REQUEST",
  CREATE_SERIES_LOADING: "CREATE_SERIES_LOADING",
  CREATE_SERIES_SUCCESS: "CREATE_SERIES_SUCCESS",
  CREATE_SERIES_ERROR: "CREATE_SERIES_ERROR",
  RESET_CREATE_SERIES_STATE: "RESET_CREATE_SERIES_STATE",
  RESET_SERIES_STATE: "RESET_SERIES_STATE",
  DELETE_SERIES_REQUEST: "DELETE_SERIES_REQUEST",
  DELETE_SERIES_LOADING: "DELETE_SERIES_LOADING",
  DELETE_SERIES_SUCCESS: "DELETE_SERIES_SUCCESS",
  DELETE_SERIES_ERROR: "DELETE_SERIES_ERROR",

  FETCH_SERIES_DETAIL_REQUEST: "FETCH_SERIES_DETAIL_REQUEST",
  FETCH_SERIES_DETAIL_LOADING: "FETCH_SERIES_DETAIL_LOADING",
  FETCH_SERIES_DETAIL_SUCCESS: "FETCH_SERIES_DETAIL_SUCCESS",
  FETCH_SERIES_DETAIL_ERROR: "FETCH_SERIES_DETAIL_ERROR",

  SHOW_BANNER_SERIES_REQUEST: "SHOW_BANNER_SERIES_REQUEST",
  SHOW_BANNER_SERIES_LOADING: "SHOW_BANNER_SERIES_LOADING",
  SHOW_BANNER_SERIES_SUCCESS: "SHOW_BANNER_SERIES_SUCCESS",
  SHOW_BANNER_SERIES_ERROR: "SHOW_BANNER_SERIES_ERROR",

  // SEARCH
  SET_SERIES_SEARCH_TEXT: "SET_SERIES_SEARCH_TEXT",
  SET_SERIES_FILTER: "SET_SERIES_FILTER",

  EDIT_SERIES_REQUEST: "EDIT_SERIES_REQUEST",
  EDIT_SERIES_LOADING: "EDIT_SERIES_LOADING",
  EDIT_SERIES_SUCCESS: "EDIT_SERIES_SUCCESS",
  EDIT_SERIES_ERROR: "EDIT_SERIES_ERROR",

  fetchSeriesList: (page) => {
    return {
      type: seriesActions.FETCH_SERIES_LIST_REQUEST,
      payload: {
        page,
      },
    };
  },
  fetchSeriesDetails: (seriesIdentifier) => {
    return {
      type: seriesActions.FETCH_SERIES_DETAIL_REQUEST,
      payload: {
        seriesIdentifier,
      },
    };
  },

  createSeries: (series) => {
    return {
      type: seriesActions.CREATE_SERIES_REQUEST,
      payload: {
        ...series,
      },
    };
  },

  editSeries: (series) => {
    return {
      type: seriesActions.EDIT_SERIES_REQUEST,
      payload: {
        ...series,
      },
    };
  },
  showBannerSeries: (series) => {
    return {
      type: seriesActions.SHOW_BANNER_SERIES_REQUEST,
      payload: {
        ...series,
      },
    };
  },

  resetCreateSeriesState: () => {
    return {
      type: seriesActions.RESET_CREATE_SERIES_STATE,
    };
  },
  resetSeriesState: () => {
    return {
      type: seriesActions.RESET_SERIES_STATE,
    };
  },
  deleteSeries: (seriesIdentifier) => {
    return {
      type: seriesActions.DELETE_SERIES_REQUEST,
      payload: {
        seriesIdentifier,
      },
    };
  },
  setSearchText: (payload) => {
    return {
      type: seriesActions.SET_SERIES_SEARCH_TEXT,
      payload,
    };
  },
  setSeriesFilter: (payload) => {
    return {
      type: seriesActions.SET_SERIES_FILTER,
      payload,
    };
  },
};

export default seriesActions;
