const reportActions = {
  FETCH_SUMMARY_REPORT_LIST_REQUEST: "FETCH_SUMMARY_REPORT_LIST_REQUEST",
  FETCH_SUMMARY_REPORT_LIST_LOADING: "FETCH_SUMMARY_REPORT_LIST_LOADING",
  FETCH_SUMMARY_REPORT_LIST_SUCCESS: "FETCH_SUMMARY_REPORT_LIST_SUCCESS",
  FETCH_SUMMARY_REPORT_LIST_ERROR: "FETCH_SUMMARY_REPORT_LIST_ERROR",
  RESET_SUMMARY_REPORT_STATE: "RESET_SUMMARY_REPORT_STATE",
  SET_SUMMARY_REPORT_SEARCH_TEXT: "SET_SUMMARY_REPORT_SEARCH_TEXT",

  fetchSummaryReport: (page) => {
    return {
      type: reportActions.FETCH_SUMMARY_REPORT_LIST_REQUEST,
      payload: {
        page,
      },
    };
  },

  resetReportState: () => {
    return {
      type: reportActions.RESET_SUMMARY_REPORT_STATE,
    };
  },

  setSearchText: (payload) => {
    return {
      type: reportActions.SET_SUMMARY_REPORT_SEARCH_TEXT,
      payload,
    };
  },
};

export default reportActions;
