import castActions from "../cast/actions";
import galleryActions from "../gallery/actions";
import ticketsActions from "../tickets/actions";
import moviesActions from "./actions";
import { Status } from "@api";
const initialState = {
  movieStatus: Status.DEFAULT,
  movieErrorMessage: "",
  createMovieStatus: Status.DEFAULT,
  createMovieErrorMessage: "",
  deleteMovieStatus: Status.DEFAULT,
  deleteMovieErrorMessage: "",
  movieDetailsStatus: Status.DEFAULT,
  movieDetailsErrorMessage: "",
  showBannerStatus: Status.DEFAULT,
  showBannerErrorMessage: "",
  editMovieStatus: Status.DEFAULT,
  editMovieErrorMessage: "",
  movieDetails: {},
  list: [],
  count: {},
  filter: "",
  searchText: "",
  hasMore: true,
};
export default function moviesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case moviesActions.FETCH_MOVIE_LIST_LOADING:
      return {
        ...state,
        movieStatus: Status.LOADING,
        movieErrorMessage: "",
      };
    case moviesActions.FETCH_MOVIE_LIST_SUCCESS:
      const { list, count, hasMore } = payload;

      return {
        ...state,
        movieStatus: Status.SUCCESS,
        movieErrorMessage: "",
        list,
        count,
        hasMore,
      };
    case moviesActions.FETCH_MOVIE_LIST_ERROR:
      return {
        ...state,
        movieStatus: Status.ERROR,
        movieErrorMessage: payload.errorMessage,
      };

    case moviesActions.FETCH_MOVIE_DETAIL_LOADING:
      return {
        ...state,
        movieDetailsStatus: Status.LOADING,
        movieDetailsErrorMessage: "",
      };
    case moviesActions.FETCH_MOVIE_DETAIL_SUCCESS:
      return {
        ...state,
        movieDetailsStatus: Status.SUCCESS,
        movieDetailsErrorMessage: "",
        movieDetails: payload.movieDetails,
      };
    case moviesActions.FETCH_MOVIE_DETAIL_ERROR:
      return {
        ...state,
        movieDetailsStatus: Status.ERROR,
        movieDetailsErrorMessage: payload.errorMessage,
      };

    case moviesActions.EDIT_MOVIE_LOADING:
      return {
        ...state,
        editMovieStatus: Status.LOADING,
        editMovieErrorMessage: "",
      };
    case moviesActions.EDIT_MOVIE_SUCCESS:
      return {
        ...state,
        editMovieStatus: Status.SUCCESS,
        editMovieErrorMessage: "",
        movieDetails: payload.movieDetails,
      };
    case moviesActions.EDIT_MOVIE_ERROR:
      return {
        ...state,
        editMovieStatus: Status.ERROR,
        editMovieErrorMessage: payload.errorMessage,
      };

    case moviesActions.RESET_MOVIE_DETAIL_STATE:
      return {
        ...state,
        movieDetailsStatus: Status.DEFAULT,
        movieDetailsErrorMessage: "",
        movieDetails: null,
        editMovieStatus: Status.DEFAULT,
        editMovieErrorMessage: "",
      };
    case moviesActions.CREATE_MOVIE_LOADING:
      return {
        ...state,
        createMovieStatus: Status.LOADING,
        createMovieErrorMessage: "",
      };
    case moviesActions.CREATE_MOVIE_SUCCESS:
      return {
        ...state,
        createMovieStatus: Status.SUCCESS,
        createMovieErrorMessage: "",
        count: payload.count,
        list: payload.list,
      };
    case moviesActions.CREATE_MOVIE_ERROR:
      return {
        ...state,
        createMovieStatus: Status.ERROR,
        createMovieErrorMessage: payload.errorMessage,
      };

    case moviesActions.SHOW_BANNER_LOADING:
      return {
        ...state,
        showBannerStatus: Status.LOADING,
        showBannerErrorMessage: "",
      };
    case moviesActions.SHOW_BANNER_SUCCESS:
      return {
        ...state,
        showBannerStatus: Status.SUCCESS,
        showBannerErrorMessage: "",
        list: payload.newList,
      };
    case moviesActions.SHOW_BANNER_ERROR:
      return {
        ...state,
        showBannerStatus: Status.ERROR,
        showBannerErrorMessage: payload.errorMessage,
      };

    case moviesActions.RESET_CREATE_MOVIE_STATE:
      return {
        ...state,
        createMovieStatus: Status.DEFAULT,
        createMovieErrorMessage: "",
      };
    case moviesActions.RESET_MOVIE_STATE:
      return {
        ...state,
        movieStatus: Status.DEFAULT,
        movieErrorMessage: "",
        createMovieStatus: Status.DEFAULT,
        createMovieErrorMessage: "",
        deleteMovieStatus: Status.DEFAULT,
        deleteMovieErrorMessage: "",
        movieDetailsStatus: Status.DEFAULT,
        movieDetailsErrorMessage: "",
        showBannerStatus: Status.DEFAULT,
        showBannerErrorMessage: "",
        editMovieStatus: Status.DEFAULT,
        editMovieErrorMessage: "",
        filter: "",
        searchText: ""
      };
    case moviesActions.DELETE_MOVIE_LOADING:
      return {
        ...state,
        deleteMovieStatus: Status.LOADING,
        deleteMovieErrorMessage: "",
      };
    case moviesActions.DELETE_MOVIE_SUCCESS:
      return {
        ...state,
        deleteMovieStatus: Status.SUCCESS,
        deleteMovieErrorMessage: "",
        list: payload.list,
        count: payload.count,
      };
    case moviesActions.DELETE_MOVIE_ERROR:
      return {
        ...state,
        deleteMovieStatus: Status.ERROR,
        deleteMovieErrorMessage: payload.errorMessage,
      };
    case castActions.CREATE_CAST_SUCCESS:
      return {
        ...state,
        movieDetails: { ...state?.movieDetails, castList: payload?.list },
      };
    case castActions.EDIT_CAST_SUCCESS:
      return {
        ...state,
        movieDetails: { ...state?.movieDetails, castList: payload?.castList },
      };
    case castActions.DELETE_CAST_SUCCESS:
      return {
        ...state,
        movieDetails: { ...state?.movieDetails, castList: payload?.castList },
      };
    case ticketsActions.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        movieDetails: {
          ...state?.movieDetails,
          price: payload?.price,
          ticketPrefixCode: payload?.ticketPrefixCode,
        },
      };

    case moviesActions.SET_MOVIE_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case moviesActions.SET_MOVIE_FILTER:
      return {
        ...state,
        filter: payload,
      };

    case galleryActions.CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        movieDetails: {
          ...state?.movieDetails,
          galleryList: payload?.list,
        },
      };
    case galleryActions.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        movieDetails: { ...state?.movieDetails, galleryList: payload?.list },
      };
    default:
      return state;
  }
}
