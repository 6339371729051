import genresActions from "./actions";
import { Status } from "@api";

const initialState = {
  list: [],
  count: {},
  filter: "",
  searchText: "",
  selectedGenre: {},
  hasMore: true,
  editView: false,
  creating: false,
  genreCreateStatus: Status.DEFAULT,
  genreCreateErrorMessage: '',
  genreStatus: Status.DEFAULT,
  genreErrorMessage: "",
  genreEditStatus: Status.DEFAULT,
  genreEditErrorMessage: '',
  deleteGenreStatus: Status.DEFAULT,
  deleteGenreErrorMessage: '',
};

export default function genresReducer(state = initialState, { type, payload }) {
  switch (type) {
    case genresActions.CREATE_GENRE_LOADING:
      return {
        ...state,
        genreCreateStatus: Status.LOADING,
        genreCreateErrorMessage: '',
      }
    case genresActions.CREATE_GENRE_SUCCESS:
      return {
        ...state,
        selectedGenre: payload.newGenre,
        list: payload.newGenreList,
        editView: false,
        creating: false,
        genreCreateStatus: Status.SUCCESS,
        genreCreateErrorMessage: '',
      }
    case genresActions.CREATE_GENRE_ERROR:
      return {
        ...state,
        genreCreateStatus: Status.ERROR,
        genreCreateErrorMessage: payload.errorMessage,
      }

    case genresActions.FETCH_GENRE_LIST_LOADING:
      return {
        ...state,
        genreStatus: Status.LOADING,
        genreErrorMessage: "",
      };
    case genresActions.FETCH_GENRE_LIST_SUCCESS:
      const { list, count, hasMore, selectedGenre } = payload;
      return {
        ...state,
        list,
        count,
        hasMore,
        selectedGenre,
        editView: false,
        genreStatus: Status.SUCCESS,
        genreErrorMessage: "",
      };
    case genresActions.FETCH_GENRE_LIST_ERROR:
      return {
        ...state,
        genreStatus: Status.ERROR,
        genreErrorMessage: payload.errorMessage,
      };
    case genresActions.RESET_GENRE_LIST_STATE:
      return {
        ...state,
        genreStatus: Status.DEFAULT,
        genreErrorMessage: '',
        filter: '',
        searchText: '',
      }
    case genresActions.EDIT_GENRE_LOADING:
      return {
        ...state,
        genreEditStatus: Status.LOADING,
        genreEditErrorMessage: '',
      }
    case genresActions.EDIT_GENRE_SUCCESS:
      return {
        ...state,
        ...payload.updatedGenre,
        selectedGenre: payload.updatedGenre,
        list: payload.updatedList,
        editView: false,
        genreEditStatus: Status.SUCCESS,
        genreEditErrorMessage: '',
      }
    case genresActions.EDIT_GENRE_ERROR:
      return {
        ...state,
        genreEditStatus: Status.ERROR,
        genreEditErrorMessage: payload.errorMessage,
      }
    case genresActions.DELETE_GENRE_LOADING:
      return {
        ...state,
        deleteGenreStatus: Status.LOADING,
        deleteGenreErrorMessage: '',
      }
    case genresActions.DELETE_GENRE_SUCCESS:
      return {
        ...state,
        deleteGenreStatus: Status.SUCCESS,
        deleteGenreErrorMessage: '',
        list: payload.list,
        count: payload.count,
        selectedGenre: payload.selectedGenre
      }
    case genresActions.DELETE_GENRE_ERROR:
      return {
        ...state,
        deleteGenreStatus: Status.ERROR,
        deleteGenreErrorMessage: payload.errorMessage,
      }
    case genresActions.RESET_GENRE_STATE:
      return {
        ...state,
        genreCreateStatus: Status.DEFAULT,
        genreCreateErrorMessage: "",
        genreEditStatus: Status.DEFAULT,
        genreEditErrorMessage: "",
      };
    case genresActions.SET_GENRES_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      }
    // case genresActions.SET_GENRES_FILTER:
    //     return {
    //         ...state,
    //         filter: payload,
    //     }
    case genresActions.SET_SELECTED_GENRE:
      return {
        ...state,
        selectedGenre: payload.genre,
        editView: false,
        creating: false,
      }
    case genresActions.EDIT_VIEW:
      return {
        ...state,
        editView: payload.view,
      }
    case genresActions.CREATE_VIEW:
      return {
        ...state,
        creating: payload.creating,
        editView: true,
        selectedProductionHouse: {},
      }
    default:
      return state;
  }
}
