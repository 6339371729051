import faqsActions from "./actions";
import { Status } from '@api';

const initialState = {
    faqStatus: Status.DEFAULT,
    faqErrorMessage: '',
    createFAQStatus: Status.DEFAULT,
    createFAQErrorMessage: '',
    deleteFAQStatus: Status.DEFAULT,
    deleteFAQErrorMessage: '',
    list: [],
    count: {},
    filter: '',
    searchText: '',
    hasMore: true,
};

export default function faqsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case faqsActions.FETCH_FAQ_LIST_LOADING:
            return {
                ...state,
                faqStatus: Status.LOADING,
                faqErrorMessage: '',
            };
        case faqsActions.FETCH_FAQ_LIST_SUCCESS:
            const { list, count, hasMore } = payload;
            return {
                ...state,
                faqStatus: Status.SUCCESS,
                faqErrorMessage: '',
                list,
                count,
                hasMore,
            };
        case faqsActions.FETCH_FAQ_LIST_ERROR:
            return {
                ...state,
                faqStatus: Status.ERROR,
                faqErrorMessage: payload.errorMessage,
            };
        case faqsActions.CREATE_FAQ_LOADING:
            return {
                ...state,
                createFAQStatus: Status.LOADING,
                createFAQErrorMessage: "",
            };
        case faqsActions.CREATE_FAQ_SUCCESS:
            return {
                ...state,
                createFAQStatus: Status.SUCCESS,
                createFAQErrorMessage: '',
                count: payload.count,
                list: payload.newFAQList,
            };
        case faqsActions.CREATE_FAQ_ERROR:
            return {
                ...state,
                createFAQStatus: Status.ERROR,
                createFAQErrorMessage: payload.errorMessage,
            };
        case faqsActions.RESET_CREATE_FAQ_STATE:
            return {
                ...state,
                createFAQStatus: Status.DEFAULT,
                createFAQErrorMessage: '',
            };
        case faqsActions.RESET_FAQ_STATE:
            return {
                ...state,
                faqStatus: Status.DEFAULT,
                faqErrorMessage: '',
                filter: '',
                searchText: '',
            };
        case faqsActions.DELETE_FAQ_LOADING:
            return {
                ...state,
                deleteFAQStatus: Status.LOADING,
                deleteFAQErrorMessage: '',
            }
        case faqsActions.DELETE_FAQ_SUCCESS:
            return {
                ...state,
                deleteFAQStatus: Status.SUCCESS,
                deleteFAQErrorMessage: '',
                list: payload.list,
                count: payload.count,
            }
        case faqsActions.DELETE_FAQ_ERROR:
            return {
                ...state,
                deleteFAQStatus: Status.ERROR,
                deleteFAQErrorMessage: payload.errorMessage,
            }
        case faqsActions.SET_FAQ_SEARCH_TEXT:
            return {
                ...state,
                searchText: payload,
            };
        case faqsActions.SET_FAQ_FILTER:
            return {
                ...state,
                filter: payload,
            };
        default:
            return state;
    }
}