import reportActions from "./actions";
import { Status } from "@api";

const initialState = {
  reportStatus: Status.DEFAULT,
  reportErrorMessage: "",
  createreportStatus: Status.DEFAULT,
  createreportErrorMessage: "",
  deletereportStatus: Status.DEFAULT,
  deletereportErrorMessage: "",
  list: [],
  count: {},
  filter: "",
  searchText: "",
  hasMore: true,
};
export default function reportReducer(state = initialState, { type, payload }) {
  switch (type) {
    case reportActions.FETCH_SUMMARY_REPORT_LIST_LOADING:
      return {
        ...state,
        reportStatus: Status.LOADING,
        reportErrorMessage: "",
      };
    case reportActions.FETCH_SUMMARY_REPORT_LIST_SUCCESS:
      const { list, count, hasMore } = payload;
      return {
        ...state,
        reportStatus: Status.SUCCESS,
        reportErrorMessage: "",
        list,
        count,
        hasMore,
      };
    case reportActions.FETCH_SUMMARY_REPORT_LIST_ERROR:
      return {
        ...state,
        reportStatus: Status.ERROR,
        reportErrorMessage: payload.errorMessage,
      };
    case reportActions.RESET_SUMMARY_REPORT_STATE:
      return {
        ...state,
        reportStatus: Status.DEFAULT,
        searchText: "",
      };
    case reportActions.SET_SUMMARY_REPORT_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    default:
      return state;
  }
}
