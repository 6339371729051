import invoicesActions from "./actions";
import { Status } from "@api";

const initialState = {
  invoiceStatus: Status.DEFAULT,
  invoiceDetailsStatus: Status.DEFAULT,
  invoiceDetailsErrorMessage: "",
  invoiceChangeTicketStatus: Status.DEFAULT,
  invoiceChangeTicketErrorMessage: "",
  list: [],
  searchText: "",
  invoiceErrorMessage: "",
  hasMore: true,
  entityIdentifier: "",
  entityType: "",
  invoiceDetailsList: [],
  invoiceDetailsInfo: null,
  showTitle: "",
  hasMoreInvoice: true,
  ticketList: null,
  hasMoreTicketList: true,
  startDate: "",
  endDate: ""
};

export default function invoicesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case invoicesActions.FETCH_INVOICE_LIST_LOADING:
      return {
        ...state,
        invoiceStatus: Status.LOADING,
        invoiceErrorMessage: "",
      };
    case invoicesActions.FETCH_INVOICE_LIST_SUCCESS:
      const { list, hasMore, showTitle } = payload;
      return {
        ...state,
        invoiceStatus: Status.SUCCESS,
        list,
        hasMore,
        showTitle,
      };
    case invoicesActions.FETCH_INVOICE_LIST_ERROR:
      return {
        ...state,
        invoiceStatus: Status.ERROR,
        invoiceErrorMessage: payload.errorMessage,
      };
    // ticket list
    case invoicesActions.FETCH_TICKET_LIST_LOADING:
      return {
        ...state,
        invoiceStatus: Status.LOADING,
        invoiceErrorMessage: "",
      };
    case invoicesActions.FETCH_TICKET_LIST_SUCCESS:
      return {
        ...state,
        invoiceStatus: Status.SUCCESS,
        ticketList: payload?.ticketList,
        invoiceDetailsInfo: payload?.userResponse,
        hasMoreTicketList: payload?.hasMoreTicketList,
      };
    case invoicesActions.FETCH_TICKET_LIST_ERROR:
      return {
        ...state,
        invoiceStatus: Status.ERROR,
        invoiceErrorMessage: payload.errorMessage,
      };
    case invoicesActions.SET_ENTITY:
      const { entityIdentifier, entityType } = payload;
      return {
        ...state,
        entityIdentifier: entityIdentifier,
        entityType: entityType,
      };
    case invoicesActions.RESET_INVOICE_LIST:
      return {
        ...state,
        invoiceStatus: Status.DEFAULT,
        invoiceErrorMessage: "",
        list: [],
        hasMore: true,
        entityIdentifier: "",
        entityType: "",
      };
    case invoicesActions.RESET_INVOICE_STATE:
      return {
        ...state,
        invoiceStatus: Status.DEFAULT,
        invoiceErrorMessage: "",
        searchText: "",
      };
    case invoicesActions.SET_INVOICES_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case invoicesActions.FETCH_INVOICE_DETAILS_LOADING:
      return {
        ...state,
        invoiceDetailsStatus: Status.LOADING,
        invoiceDetailsErrorMessage: "",
      };
    case invoicesActions.FETCH_INVOICE_DETAILS_SUCCESS:
      const { invoiceDetailsList, hasMoreInvoice, userResponse } = payload;
      return {
        ...state,
        invoiceDetailsStatus: Status.SUCCESS,
        invoiceDetailsErrorMessage: "",
        invoiceDetailsList,
        invoiceDetailsInfo: userResponse,
        hasMoreInvoice,
      };
    case invoicesActions.FETCH_INVOICE_DETAILS_ERROR:
      return {
        ...state,
        invoiceDetailsStatus: Status.ERROR,
        invoiceDetailsErrorMessage: payload.errorMessage,
      };
    case invoicesActions.RESET_INVOICE_DETAILS_STATE:
      return {
        ...state,
        invoiceDetailsStatus: Status.DEFAULT,
        invoiceDetailsErrorMessage: "",
        invoiceDetailsList: [],
        ticketList: [],
        invoiceDetailsInfo: null,
        hasMoreTicketList: true,
        hasMoreInvoice: true,
      };
    case invoicesActions.CHANGE_TICKET_STATUS_LOADING:
      return {
        ...state,
        invoiceChangeTicketStatus: Status.LOADING,
        invoiceChangeTicketErrorMessage: "",
      };
    case invoicesActions.CHANGE_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        invoiceChangeTicketStatus: Status.SUCCESS,
        invoiceChangeTicketErrorMessage: "",
        invoiceDetailsList: payload.updatedInvoiceDetailsList,
      };
    case invoicesActions.CHANGE_TICKET_STATUS_ERROR:
      return {
        ...state,
        invoiceChangeTicketStatus: Status.ERROR,
        invoiceChangeTicketErrorMessage: payload.errorMessage,
      };
    case invoicesActions.RESET_CHANGE_TICKET_STATUS_STATE:
      return {
        ...state,
        invoiceChangeTicketStatus: Status.DEFAULT,
        invoiceChangeTicketErrorMessage: "",
      };
    case invoicesActions.SET_DATE_FILTER:
      return {
        ...state,
        startDate: payload.startDate,
        endDate: payload.endDate,
      };
    default:
      return state;
  }
}
