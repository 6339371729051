import { call, takeLatest, put, select } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { LIMITS } from "@constants";

/**
 * worker saga: Calls the fetch ticket list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchReportSummaryByEventVenue({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_SUMMARY_REPORT_LIST_LOADING });

    let { searchText: keyword, list: extList } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.fetchReportSummaryByEventVenue },
      { keyword, page }
    );

    // parse the data from response
    const {
      userResponse: { count, report: newList },
    } = response;

    yield put({
      type: actions.FETCH_SUMMARY_REPORT_LIST_SUCCESS,
      payload: {
        count,
        list: page === 1 ? newList : [...extList, ...newList],
        hasMore: newList.length >= LIMITS.PAGE_SIZE,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_SUMMARY_REPORT_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ report }) => {
  const { searchText, filter, list } = report;

  return {
    searchText,
    filter,
    list,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(
    actions.FETCH_SUMMARY_REPORT_LIST_REQUEST,
    fetchReportSummaryByEventVenue
  );
}
