export default {
  PAGE_SIZE: 12,
  MAX_TICKET_COUNT: 50,
  VIDEO_DURATION: "08:00:00", // 8 hours
  MAX_IMAGE_FILE_SIZE: 10, // 10 MB
  MAX_VIDEO_FILE_SIZE: 8, // 8 GB
  TEXT_COUNT: 30,
  TITLE_MAX_LENGTH: 255,
  DESCRIPTIONS_MAX_LENGTH: 50000,
  SHORT_DESCRIPTIONS_MAX_LENGTH: 255,
  EPISODE_DESCRIPTIONS_MAX_LENGTH: 8000
};
