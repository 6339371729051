import { call, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";

/**
 * worker saga: Calls the create venue API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* updateTicket({ payload }) {
  try {
    yield put({ type: actions.UPDATE_TICKET_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.updateTicket },
      { ...payload }
    );

    // parse the data from response
    const { userResponse } = response;

    yield put({
      type: actions.UPDATE_TICKET_SUCCESS,
      payload: {
        ...userResponse,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_TICKET_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.UPDATE_TICKET_REQUEST, updateTicket);
}
