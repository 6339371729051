/* eslint-disable no-unused-vars */
import { call, takeEvery, takeLatest, put, } from 'redux-saga/effects';
import {
  setToken,
  getToken,
  clearToken,
  setAdminIdentifier,
  getAdminIdentifier,
  clearAdminIdentifier,
  setAdminRole,
  getAdminRole,
  clearAdminRole,
} from '@iso/lib/helpers/utility';
import actions from './actions';
import adminActions from '../admin/actions';
import { api } from '@api';

/**
 * 
 * worker saga: Check session id in Local storage
 *
 */
function* checkAuthorization() {
  const sessionId = getToken();
  const adminIdentifier = getAdminIdentifier();
  const adminRole = getAdminRole();
  if (sessionId) {
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        sessionId,
        adminIdentifier,
        adminRole,
      }
    });
  }
}

/**
 * worker saga: Calls the login API with given credentials,
 * on sucess, receives user identifier & session id
 *
 * TODO: Send correct ip address in api body
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.email       - email of the user
 * @param {string} action.payload.password    - password for the account
 */
function* login({ payload: { email, password } }) {
  try {
    yield put({ type: actions.LOGIN_LOADING });
    const ipAddress = '1.1.1.1'; // <===== temp solution
    // api call
    const response = yield call(
      { context: api, fn: api.login },
      { email, password, ipAddress, },
    );

    // parse the data from response
    const {
      userResponse: {
        sessionId,
        adminIdentifier,
        adminRole,
      },
    } = response;

    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        sessionId,
        adminIdentifier,
        adminRole,
      },
    });

  }
  catch (error) {
    yield put({
      type: actions.LOGIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* loginSuccess({ payload: { sessionId, adminIdentifier, adminRole, } }) {
  //update sessionId in Request
  api.setSessionId(sessionId);
  setToken(sessionId);
  setAdminIdentifier(adminIdentifier);
  setAdminRole(adminRole);

  yield put({
    type: adminActions.FETCH_ADMIN_DETAILS_REQUEST,
    payload: {
      adminIdentifier,
    },
  });
}

/**
* worker saga: Remove session id from Local storage
*/
function* logout({ payload: { isSessionExpired } }) {
  try {
    yield put({ type: actions.LOGOUT_LOADING });

    if (!isSessionExpired) {
      // api call
      yield call({ context: api, fn: api.logout });
    }

    api.setSessionId('');

    yield put({
      type: actions.LOGOUT_SUCCESS,
    });

    clearToken();
    clearAdminIdentifier();
    clearAdminRole();

  } catch (error) {
    yield put({
      type: actions.LOGOUT_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * Fetch data from redux store
 *
 * NOTE: Don't overfill this for all data acess from redux store, create new
 * accessor if requirement is different or gets exhaustive
 *
 * @return {Object} return user identifier
 */
const getDataFromStore = ({ Admin }) => {
  const { adminIdentifier } = Admin;
  return {
    adminIdentifier,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization);
  yield takeLatest(actions.LOGIN_REQUEST, login);
  yield takeLatest(actions.LOGIN_SUCCESS, loginSuccess);
  yield takeLatest(actions.LOGOUT_REQUEST, logout);
}
