const videoUploadActions = {
  UPLOAD_VIDEO_REQUEST: 'UPLOAD_VIDEO_REQUEST',
  UPLOAD_VIDEO_LOADING: 'UPLOAD_VIDEO_LOADING',
  UPLOAD_VIDEO_SUCCESS: 'UPLOAD_VIDEO_SUCCESS',
  UPLOAD_VIDEO_ERROR: 'UPLOAD_VIDEO_ERROR',
  PRESIGNED_URL_REQUEST: 'PRESIGNED_URL_REQUEST',
  PRESIGNED_URL_LOADING: 'PRESIGNED_URL_LOADING',
  PRESIGNED_URL_SUCCESS: 'PRESIGNED_URL_SUCCESS',
  PRESIGNED_URL_ERROR: 'PRESIGNED_URL_ERROR',
  UPLOAD_STATUS_REQUEST: 'UPLOAD_STATUS_REQUEST',
  UPLOAD_STATUS_LOADING: 'UPLOAD_STATUS_LOADING',
  UPLOAD_STATUS_SUCCESS: 'UPLOAD_STATUS_SUCCESS',
  UPLOAD_STATUS_ERROR: 'UPLOAD_STATUS_ERROR',
  RESET_UPLOAD_STATE: 'RESET_UPLOAD_STATE',

  uploadVideo: ({ broadcastIdentifier, file, fileName, mimeType, fileSize }) => {
    return {
      type: videoUploadActions.UPLOAD_VIDEO_REQUEST,
      payload: {
        broadcastIdentifier,
        file,
        fileName,
        mimeType,
        fileSize,
      },
    };
  },
  requestPresignedUrl: ({ presignedUrl, file, uploadIdentifier, broadcastIdentifier }) => {
    return {
      type: videoUploadActions.PRESIGNED_URL_REQUEST,
      payload: {
        presignedUrl,
        file,
        uploadIdentifier,
        broadcastIdentifier,
      },
    };
  },
  resetUploadState: () => {
    return {
      type: videoUploadActions.RESET_UPLOAD_STATE,
    };
  },
};

export default videoUploadActions;