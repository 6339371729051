import packageActions from "./actions";
import { Status } from "@api";

const initialState = {
  packageStatus: Status.DEFAULT,
  packageErrorMessage: "",
  createPackageStatus: Status.DEFAULT,
  createPackageErrorMessage: "",
  deletePackageStatus: Status.DEFAULT,
  deletePackageErrorMessage: "",
  packageDetailsStatus: Status.DEFAULT,
  packageDetailsErrorMessage: "",
  packageDetails: "",
  editPackageStatus: Status.DEFAULT,
  editPackageErrorMessage: "",
  list: [],
  count: {},
  filter: "",
  searchText: "",
  hasMore: true,
};

export default function packageReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case packageActions.FETCH_PACKAGE_LIST_LOADING:
      return {
        ...state,
        packageStatus: Status.LOADING,
        packageErrorMessage: "",
      };
    case packageActions.FETCH_PACKAGE_LIST_SUCCESS:
      const { list, count, hasMore } = payload;
      return {
        ...state,
        packageStatus: Status.SUCCESS,
        packageErrorMessage: "",
        list,
        count,
        hasMore,
      };
    case packageActions.FETCH_PACKAGE_LIST_ERROR:
      return {
        ...state,
        packageStatus: Status.ERROR,
        packageErrorMessage: payload.errorMessage,
      };
    case packageActions.FETCH_PACKAGE_DETAIL_LOADING:
      return {
        ...state,
        packageDetailsStatus: Status.LOADING,
        packageDetailsErrorMessage: "",
      };
    case packageActions.FETCH_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        packageDetailsStatus: Status.SUCCESS,
        packageDetailsErrorMessage: "",
        packageDetails: payload.packageDetails,
      };
    case packageActions.FETCH_PACKAGE_DETAIL_ERROR:
      return {
        ...state,
        packageDetailsStatus: Status.ERROR,
        packageDetailsErrorMessage: payload.errorMessage,
      };

    case packageActions.CREATE_PACKAGE_LOADING:
      return {
        ...state,
        createPackageStatus: Status.LOADING,
        createPackageErrorMessage: "",
      };
    case packageActions.CREATE_PACKAGE_SUCCESS:
      return {
        ...state,
        createPackageStatus: Status.SUCCESS,
        createPackageErrorMessage: "",
        count: payload.count,
        list: payload.newPackageList,
      };
    case packageActions.CREATE_PACKAGE_ERROR:
      return {
        ...state,
        createPackageStatus: Status.ERROR,
        createPackageErrorMessage: payload.errorMessage,
      };
    case packageActions.RESET_CREATE_PACKAGE_STATE:
      return {
        ...state,
        createPackageStatus: Status.DEFAULT,
        createPackageErrorMessage: "",
      };
    case packageActions.RESET_PACKAGE_STATE:
      return {
        ...state,
        packageStatus: Status.DEFAULT,
        packageErrorMessage: "",
        createPackageStatus: Status.DEFAULT,
        createPackageErrorMessage: "",
        deletePackageStatus: Status.DEFAULT,
        deletePackageErrorMessage: "",
        packageDetailsStatus: Status.DEFAULT,
        packageDetailsErrorMessage: "",
        editPackageStatus: Status.DEFAULT,
        editPackageErrorMessage: "",
        list: [],
        count: {},
        filter: "",
        searchText: "",
      };
    case packageActions.DELETE_PACKAGE_LOADING:
      return {
        ...state,
        deletePackageStatus: Status.LOADING,
        deletePackageErrorMessage: "",
      };
    case packageActions.DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        deletePackageStatus: Status.SUCCESS,
        deletePackageErrorMessage: "",
        list: payload.list,
        count: payload.count,
      };
    case packageActions.DELETE_PACKAGE_ERROR:
      return {
        ...state,
        deletePackageStatus: Status.ERROR,
        deletePackageErrorMessage: payload.errorMessage,
      };

    case packageActions.EDIT_PACKAGE_LOADING:
      return {
        ...state,
        editPackageStatus: Status.LOADING,
        editPackageErrorMessage: "",
      };
    case packageActions.EDIT_PACKAGE_SUCCESS:
      return {
        ...state,
        editPackageStatus: Status.SUCCESS,
        editPackageErrorMessage: "",
        movieDetails: payload.movieDetails,
      };
    case packageActions.EDIT_PACKAGE_ERROR:
      return {
        ...state,
        editPackageStatus: Status.ERROR,
        editPackageErrorMessage: payload.errorMessage,
      };

    case packageActions.SET_PACKAGE_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case packageActions.SET_PACKAGE_FILTER:
      return {
        ...state,
        filter: payload,
      };
    default:
      return state;
  }
}
