import defaultTheme from './default';
import customTheme from './custom';
import { getTopBarTheme, getSiteBarTheme, getLayoutTheme, getChangeThemes } from '../../library/helpers/utility';

const themes = {
  defaultTheme,
  customTheme,
};

export const themeConfig = {
  topbar: getTopBarTheme(),
  sidebar: getSiteBarTheme(),
  layout: getLayoutTheme(),
  theme: getChangeThemes(),
};
export default themes;
