import postersActions from "./actions";
import { Status } from "@api";

const initialState = {
  uploadPortraitImageStatus: Status.DEFAULT,
  uploadPortraitImageErrorMessage: "",
  recentUploadedPortraitImage: null,

  uploadLandscapeImageStatus: Status.DEFAULT,
  uploadLandscapeImageErrorMessage: "",
  recentUploadedLandscapeImage: null,

  uploadLightImageStatus: Status.DEFAULT,
  uploadLightImageErrorMessage: "",
  recentUploadedLightImage: null,

  uploadDarkImageStatus: Status.DEFAULT,
  uploadDarkImageErrorMessage: "",
  recentUploadedDarkImage: null,

  uploadWebBannerImageStatus: Status.DEFAULT,
  uploadWebBannerImageErrorMessage: "",
  recentUploadedWebBannerImage: null,

  uploadMobileBannerImageStatus: Status.DEFAULT,
  uploadMobileBannerImageErrorMessage: "",
  recentUploadedMobileBannerImage: null,

  uploadLandscapeGifImageStatus: Status.DEFAULT,
  uploadLandscapeGifImageErrorMessage: "",
  recentUploadedLandscapeGifImage: null,

  uploadPortraitGifImageStatus: Status.DEFAULT,
  uploadPortraitGifImageErrorMessage: "",
  recentUploadedPortraitGifImage: null,
};
export default function postersReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case postersActions.UPLOAD_PORTRAIT_IMAGE_LOADING:
      return {
        ...state,
        uploadPortraitImageStatus: Status.LOADING,
        uploadPortraitImageErrorMessage: "",
      };
    case postersActions.UPLOAD_PORTRAIT_IMAGE_SUCCESS:
      return {
        ...state,
        uploadPortraitImageStatus: Status.SUCCESS,
        uploadPortraitImageErrorMessage: "",
        recentUploadedPortraitImage: payload.portraitImageUrl,
      };
    case postersActions.UPLOAD_PORTRAIT_IMAGE_ERROR:
      return {
        ...state,
        uploadPortraitImageStatus: Status.ERROR,
        uploadPortraitImageErrorMessage: payload.errorMessage,
      };

    // landscape

    case postersActions.UPLOAD_LANDSCAPE_IMAGE_LOADING:
      return {
        ...state,
        uploadLandscapeImageStatus: Status.LOADING,
        uploadLandscapeImageErrorMessage: "",
      };
    case postersActions.UPLOAD_LANDSCAPE_IMAGE_SUCCESS:
      return {
        ...state,
        uploadLandscapeImageStatus: Status.SUCCESS,
        uploadLandscapeImageErrorMessage: "",
        recentUploadedLandscapeImage: payload.landscapeImageUrl,
      };
    case postersActions.UPLOAD_LANDSCAPE_IMAGE_ERROR:
      return {
        ...state,
        uploadLandscapeImageStatus: Status.ERROR,
        uploadLandscapeImageErrorMessage: payload.errorMessage,
      };

    // light
    case postersActions.UPLOAD_LIGHT_IMAGE_LOADING:
      return {
        ...state,
        uploadLightImageStatus: Status.LOADING,
        uploadLightImageErrorMessage: "",
      };
    case postersActions.UPLOAD_LIGHT_IMAGE_SUCCESS:
      return {
        ...state,
        uploadLightImageStatus: Status.SUCCESS,
        uploadLightImageErrorMessage: "",
        recentUploadedLightImage: payload.lightImageUrl,
      };
    case postersActions.UPLOAD_LIGHT_IMAGE_ERROR:
      return {
        ...state,
        uploadLightImageStatus: Status.ERROR,
        uploadLightImageErrorMessage: payload.errorMessage,
      };

    // dark

    case postersActions.UPLOAD_DARK_IMAGE_LOADING:
      return {
        ...state,
        uploadDarkImageStatus: Status.LOADING,
        uploadDarkImageErrorMessage: "",
      };
    case postersActions.UPLOAD_DARK_IMAGE_SUCCESS:
      return {
        ...state,
        uploadDarkImageStatus: Status.SUCCESS,
        uploadDarkImageErrorMessage: "",
        recentUploadedDarkImage: payload.darkImageUrl,
      };
    case postersActions.UPLOAD_DARK_IMAGE_ERROR:
      return {
        ...state,
        uploadDarkImageStatus: Status.ERROR,
        uploadDarkImageErrorMessage: payload.errorMessage,
      };

    // banner

    case postersActions.UPLOAD_MOBILE_BANNER_IMAGE_LOADING:
      return {
        ...state,
        uploadMobileBannerImageStatus: Status.LOADING,
        uploadMobileBannerImageErrorMessage: "",
      };
    case postersActions.UPLOAD_MOBILE_BANNER_IMAGE_SUCCESS:
      return {
        ...state,
        uploadMobileBannerImageStatus: Status.SUCCESS,
        uploadMobileBannerImageErrorMessage: "",
        recentUploadedMobileBannerImage: payload.mobileBannerImageUrl,
      };
    case postersActions.UPLOAD_MOBILE_BANNER_IMAGE_ERROR:
      return {
        ...state,
        uploadMobileBannerImageStatus: Status.ERROR,
        uploadMobileBannerImageErrorMessage: payload.errorMessage,
      };

    case postersActions.UPLOAD_WEB_BANNER_IMAGE_LOADING:
      return {
        ...state,
        uploadWebBannerImageStatus: Status.LOADING,
        uploadWebBannerImageErrorMessage: "",
      };
    case postersActions.UPLOAD_WEB_BANNER_IMAGE_SUCCESS:
      return {
        ...state,
        uploadWebBannerImageStatus: Status.SUCCESS,
        uploadWebBannerImageErrorMessage: "",
        recentUploadedWebBannerImage: payload.webBannerImageUrl,
      };
    case postersActions.UPLOAD_WEB_BANNER_IMAGE_ERROR:
      return {
        ...state,
        uploadWebBannerImageStatus: Status.ERROR,
        uploadWebBannerImageErrorMessage: payload.errorMessage,
      };

    // Gif

    case postersActions.UPLOAD_LANDSCAPE_GIF_IMAGE_LOADING:
      return {
        ...state,
        uploadLandscapeGifImageStatus: Status.LOADING,
        uploadLandscapeGifImageErrorMessage: "",
      };
    case postersActions.UPLOAD_LANDSCAPE_GIF_IMAGE_SUCCESS:
      return {
        ...state,
        uploadLandscapeGifImageStatus: Status.SUCCESS,
        uploadLandscapeGifImageErrorMessage: "",
        recentUploadedLandscapeGifImage: payload.landscapeGifUrl,
      };
    case postersActions.UPLOAD_LANDSCAPE_GIF_IMAGE_ERROR:
      return {
        ...state,
        uploadLandscapeGifImageStatus: Status.ERROR,
        uploadLandscapeGifImageErrorMessage: payload.errorMessage,
      };

    case postersActions.UPLOAD_PORTRAIT_GIF_IMAGE_LOADING:
      return {
        ...state,
        uploadPortraitGifImageStatus: Status.LOADING,
        uploadPortraitGifImageErrorMessage: "",
      };
    case postersActions.UPLOAD_PORTRAIT_GIF_IMAGE_SUCCESS:
      return {
        ...state,
        uploadPortraitGifImageStatus: Status.SUCCESS,
        uploadPortraitGifImageErrorMessage: "",
        recentUploadedPortraitGifImage: payload.portraitGifUrl,
      };
    case postersActions.UPLOAD_PORTRAIT_GIF_IMAGE_ERROR:
      return {
        ...state,
        uploadPortraitGifImageStatus: Status.ERROR,
        uploadPortraitGifImageErrorMessage: payload.errorMessage,
      };

    case postersActions.RESET_UPLOAD_STATE:
      return {
        ...state,
        uploadPortraitImageStatus: Status.DEFAULT,
        uploadPortraitImageErrorMessage: "",
        recentUploadedPortraitImage: "",

        uploadLandscapeImageStatus: Status.DEFAULT,
        uploadLandscapeImageErrorMessage: "",
        recentUploadedLandscapeImage: "",

        uploadLightImageStatus: Status.DEFAULT,
        uploadLightImageErrorMessage: "",
        recentUploadedLightImage: "",

        uploadDarkImageStatus: Status.DEFAULT,
        uploadDarkImageErrorMessage: "",
        recentUploadedDarkImage: "",

        uploadMobileBannerImageStatus: Status.DEFAULT,
        uploadMobileBannerImageErrorMessage: "",
        recentUploadedMobileBannerImage: null,

        uploadLandscapeGifImageStatus: Status.DEFAULT,
        uploadLandscapeGifImageErrorMessage: "",
        recentUploadedLandscapeGifImage: "",

        uploadWebBannerImageStatus: Status.DEFAULT,
        uploadWebBannerImageErrorMessage: "",
        recentUploadedWebBannerImage: null,

        uploadPortraitGifImageStatus: Status.DEFAULT,
        uploadPortraitGifImageErrorMessage: "",
        recentUploadedPortraitGifImage: null,
      };
    default:
      return state;
  }
}
