import videoUploadActions from "./actions";
import { Status } from '@api';

const initialState = {
  videoUploadStatus: Status.DEFAULT,
  videoUploadErrorMessage: '',
  videoUploadErrorStatus: '',
};

export default function videoUploadReducer(state = initialState, { type, payload }) {
  switch (type) {
    case videoUploadActions.UPLOAD_VIDEO_LOADING:
      return {
        ...state,
        videoUploadStatus: Status.LOADING,
        videoUploadErrorMessage: '',
      };
    case videoUploadActions.PRESIGNED_URL_LOADING:
      return {
        ...state,
        videoUploadStatus: Status.LOADING,
        videoUploadErrorMessage: '',
      }
    case videoUploadActions.UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        videoUploadStatus: Status.SUCCESS,
        videoUploadErrorMessage: '',
      };
    case videoUploadActions.UPLOAD_STATUS_ERROR:
      return {
        ...state,
        videoUploadStatus: Status.ERROR,
        videoUploadErrorMessage: payload.errorMessage,
      }
    case videoUploadActions.PRESIGNED_URL_ERROR:
      return {
        ...state,
        videoUploadStatus: Status.ERROR,
        videoUploadErrorMessage: payload.errorMessage,
        videoUploadErrorStatus: payload.errorStatus,
      }
    case videoUploadActions.UPLOAD_VIDEO_ERROR:
      return {
        ...state,
        videoUploadStatus: Status.ERROR,
        videoUploadErrorMessage: payload.errorMessage,
      }
    case videoUploadActions.RESET_UPLOAD_STATE:
      return {
        ...state,
        videoUploadStatus: Status.DEFAULT,
        videoUploadErrorMessage: '',
        videoUploadErrorStatus: '',
      }
    default:
      return state;
  }
}