/* eslint-disable require-yield */
import { all, takeEvery, fork } from 'redux-saga/effects';
import { getDeviceInfo, } from '@iso/lib/helpers/utility';
import actions from './actions';
import { api } from '@api';
import { sessionExpiredAction } from '../store';

export function* initializeApp() {
  yield takeEvery(actions.INITIALIZE_APP, function* () {
    const { deviceId, deviceName } = getDeviceInfo();
    api.init({
      deviceId,
      deviceName,
      sessionExpiredAction,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(initializeApp),
  ]);
}