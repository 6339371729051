import episodeActions from "./actions";
import { Status } from "@api";

const initialState = {
  episodeStatus: Status.DEFAULT,
  episodeErrorMessage: "",
  createEpisodeStatus: Status.DEFAULT,
  createEpisodeErrorMessage: "",
  deleteEpisodeStatus: Status.DEFAULT,
  deleteEpisodeErrorMessage: "",
  list: [],
  uploadEpisodeImageStatus: Status.DEFAULT,
  uploadEpisodeImageErrorMessage: "",
  episodeEditStatus: Status.DEFAULT,
  episodeEditErrorMessage: "",
};

export default function episodeReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case episodeActions.CREATE_EPISODE_LOADING:
      return {
        ...state,
        createEpisodeStatus: Status.LOADING,
        createEpisodeErrorMessage: "",
      };
    case episodeActions.CREATE_EPISODE_SUCCESS:
      return {
        ...state,
        createEpisodeStatus: Status.SUCCESS,
        createEpisodeErrorMessage: "",

        list: payload.list,
      };
    case episodeActions.CREATE_EPISODE_ERROR:
      return {
        ...state,
        createEpisodeStatus: Status.ERROR,
        createEpisodeErrorMessage: payload.errorMessage,
      };

    case episodeActions.UPLOAD_EPISODE_IMAGE_LOADING:
      return {
        ...state,
        uploadEpisodeImageStatus: Status.LOADING,
        uploadEpisodeImageErrorMessage: "",
      };
    case episodeActions.UPLOAD_EPISODE_IMAGE_SUCCESS:
      return {
        ...state,
        uploadEpisodeImageStatus: Status.SUCCESS,
        uploadEpisodeImageErrorMessage: "",
      };
    case episodeActions.UPLOAD_EPISODE_IMAGE_ERROR:
      return {
        ...state,
        uploadEpisodeImageStatus: Status.ERROR,
        uploadEpisodeImageErrorMessage: payload.errorMessage,
      };

    case episodeActions.UPLOAD_TVPROGRAM_IMAGE_LOADING:
      return {
        ...state,
        uploadEpisodeImageStatus: Status.LOADING,
        uploadEpisodeImageErrorMessage: "",
      };
    case episodeActions.UPLOAD_TVPROGRAM_IMAGE_SUCCESS:
      return {
        ...state,
        uploadEpisodeImageStatus: Status.SUCCESS,
        uploadEpisodeImageErrorMessage: "",
      };
    case episodeActions.UPLOAD_TVPROGRAM_IMAGE_ERROR:
      return {
        ...state,
        uploadEpisodeImageStatus: Status.ERROR,
        uploadEpisodeImageErrorMessage: payload.errorMessage,
      };

    case episodeActions.EDIT_EPISODE_LOADING:
      return {
        ...state,
        episodeEditStatus: Status.LOADING,
        episodeEditErrorMessage: "",
      };
    case episodeActions.EDIT_EPISODE_SUCCESS:
      return {
        ...state,
        episodeEditStatus: Status.SUCCESS,
        episodeEditErrorMessage: "",
      };
    case episodeActions.EDIT_EPISODE_ERROR:
      return {
        ...state,
        episodeEditStatus: Status.ERROR,
        episodeEditErrorMessage: payload.errorMessage,
      };
    case episodeActions.DELETE_EPISODE_LOADING:
      return {
        ...state,
        deleteEpisodeStatus: Status.LOADING,
        deleteEpisodeErrorMessage: "",
      };
    case episodeActions.DELETE_EPISODE_SUCCESS:
      return {
        ...state,
        deleteEpisodeStatus: Status.SUCCESS,
        deleteEpisodeErrorMessage: "",
      };
    case episodeActions.DELETE_EPISODE_ERROR:
      return {
        ...state,
        deleteEpisodeStatus: Status.ERROR,
        deleteEpisodeErrorMessage: payload.errorMessage,
      };
    case episodeActions.RESET_EPISODE_STATE:
      return {
        episodeStatus: Status.DEFAULT,
        episodeErrorMessage: "",
        createEpisodeStatus: Status.DEFAULT,
        createEpisodeErrorMessage: "",
        deleteEpisodeStatus: Status.DEFAULT,
        deleteEpisodeErrorMessage: "",
        uploadEpisodeImageStatus: Status.DEFAULT,
        uploadEpisodeImageErrorMessage: "",
        episodeEditStatus: Status.DEFAULT,
        episodeEditErrorMessage: "",
      };

    default:
      return state;
  }
}
