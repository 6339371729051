const giftTicketsActions = {
    FETCH_GIFT_TICKET_LIST_REQUEST: 'FETCH_GIFT_TICKET_LIST_REQUEST',
    FETCH_GIFT_TICKET_LIST_LOADING: 'FETCH_GIFT_TICKET_LIST_LOADING',
    FETCH_GIFT_TICKET_LIST_SUCCESS: 'FETCH_GIFT_TICKET_LIST_SUCCESS',
    FETCH_GIFT_TICKET_LIST_ERROR: 'FETCH_GIFT_TICKET_LIST_ERROR',

    RESET_GIFT_TICKET_STATE: 'RESET_GIFT_TICKET_STATE',

    //SEARCH
    SET_GIFT_TICKET_SEARCH_TEXT: 'SET_GIFT_TICKET_SEARCH_TEXT',

    fetchGiftTicketList: page => {
        return {
            type: giftTicketsActions.FETCH_GIFT_TICKET_LIST_REQUEST,
            payload: {
                page,
            }
        };
    },

    setSearchText: payload => {
        return {
            type: giftTicketsActions.SET_GIFT_TICKET_SEARCH_TEXT,
            payload
        }
    },

    resetGiftTicketState: () => {
        return {
            type: giftTicketsActions.RESET_GIFT_TICKET_STATE,
        }
    }
}

export default giftTicketsActions;