const invoicesActions = {
  FETCH_INVOICE_LIST_REQUEST: "FETCH_INVOICE_LIST_REQUEST",
  FETCH_INVOICE_LIST_LOADING: "FETCH_INVOICE_LIST_LOADING",
  FETCH_INVOICE_LIST_SUCCESS: "FETCH_INVOICE_LIST_SUCCESS",
  FETCH_INVOICE_LIST_ERROR: "FETCH_INVOICE_LIST_ERROR",
  RESET_INVOICE_LIST: "RESET_INVOICE_LIST",
  RESET_INVOICE_STATE: "RESET_INVOICE_STATE",
  FETCH_INVOICE_DETAILS_REQUEST: "FETCH_INVOICE_DETAILS_REQUEST",
  FETCH_INVOICE_DETAILS_LOADING: "FETCH_INVOICE_DETAILS_LOADING",
  FETCH_INVOICE_DETAILS_SUCCESS: "FETCH_INVOICE_DETAILS_SUCCESS",
  FETCH_INVOICE_DETAILS_ERROR: "FETCH_INVOICE_DETAILS_ERROR",
  RESET_INVOICE_DETAILS_STATE: "RESET_INVOICE_DETAILS_STATE",
  CHANGE_TICKET_STATUS_REQUEST: "CHANGE_TICKET_STATUS_REQUEST",
  CHANGE_TICKET_STATUS_LOADING: "CHANGE_TICKET_STATUS_LOADING",
  CHANGE_TICKET_STATUS_SUCCESS: "CHANGE_TICKET_STATUS_SUCCESS",
  CHANGE_TICKET_STATUS_ERROR: "CHANGE_TICKET_STATUS_ERROR",
  RESET_CHANGE_TICKET_STATUS_STATE: "RESET_CHANGE_TICKET_STATUS_STATE",
  FETCH_TICKET_LIST_REQUEST: "FETCH_TICKET_LIST_REQUEST",
  FETCH_TICKET_LIST_LOADING: "FETCH_TICKET_LIST_LOADING",
  FETCH_TICKET_LIST_SUCCESS: "FETCH_TICKET_LIST_SUCCESS",
  FETCH_TICKET_LIST_ERROR: "FETCH_TICKET_LIST_ERROR",

  //SEARCH
  SET_INVOICES_SEARCH_TEXT: "SET_INVOICES_SEARCH_TEXT",
  SET_ENTITY: "SET_ENTITY",
  SET_DATE_FILTER: "SET_DATE_FILTER",

  fetchInvoiceList: (page) => {
    return {
      type: invoicesActions.FETCH_INVOICE_LIST_REQUEST,
      payload: {
        page
      },
    };
  },

  resetTicketInvoiceList: () => {
    return {
      type: invoicesActions.RESET_INVOICE_LIST,
    };
  },

  setEntity: ({ entityIdentifier, entityType }) => {
    return {
      type: invoicesActions.SET_ENTITY,
      payload: {
        entityIdentifier,
        entityType,
      },
    };
  },

  resetInvoiceState: () => {
    return {
      type: invoicesActions.RESET_INVOICE_STATE,
    };
  },

  changeTicketToNewStatus: ({ ticketSaleDetailIdentifier, statusName }) => {
    return {
      type: invoicesActions.CHANGE_TICKET_STATUS_REQUEST,
      payload: {
        ticketSaleDetailIdentifier,
        statusName,
      },
    };
  },

  resetChangeTicketStatusState: () => ({
    type: invoicesActions.RESET_CHANGE_TICKET_STATUS_STATE,
  }),

  setSearchText: (payload) => {
    return {
      type: invoicesActions.SET_INVOICES_SEARCH_TEXT,
      payload,
    };
  },

  fetchInvoiceDetails: ({ ticketInvoiceIdentifier, page }) => {
    return {
      type: invoicesActions.FETCH_INVOICE_DETAILS_REQUEST,
      payload: {
        ticketInvoiceIdentifier,
        page,
      },
    };
  },

  fetchTicketList: ({ ticketInvoiceIdentifier, page }) => {
    return {
      type: invoicesActions.FETCH_TICKET_LIST_REQUEST,
      payload: {
        ticketInvoiceIdentifier,
        page,
      },
    };
  },

  resetTicketInvoiceDetails: () => {
    return {
      type: invoicesActions.RESET_INVOICE_DETAILS_STATE,
    };
  },

  setDateFilter: ({startDate, endDate}) => {
    return {
      type: invoicesActions.SET_DATE_FILTER,
      payload: {
        startDate,
        endDate
      }
    }
  }
};

export default invoicesActions;
