import { call, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { message } from "antd";

/**
 * worker saga: Calls the create venue API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* createEpisode({ payload }) {
  try {
    yield put({ type: actions.CREATE_EPISODE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.createEpisode },
      { ...payload }
    );

    // parse the data from response
    const {
      userResponse: { episodeList },
    } = response;

    yield put({
      type: actions.CREATE_EPISODE_SUCCESS,
      payload: {
        list: [...episodeList],
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_EPISODE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* uploadEpisodeImage({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_EPISODE_IMAGE_LOADING });
    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Photo is uploaded successfully!");
    const { userResponse } = response;

    yield put({
      type: actions.UPLOAD_EPISODE_IMAGE_SUCCESS,
      payload: {
        ...userResponse,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_EPISODE_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* uploadTVProgramImage({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_TVPROGRAM_IMAGE_LOADING });
    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Photo is uploaded successfully!");
    const { userResponse } = response;

    yield put({
      type: actions.UPLOAD_TVPROGRAM_IMAGE_SUCCESS,
      payload: {
        ...userResponse,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_TVPROGRAM_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* editEpisode({ payload: { episode } }) {
  try {
    yield put({ type: actions.EDIT_EPISODE_LOADING });

    //*** get data from reducer ***

    // api call
    const response = yield call(
      { context: api, fn: api.editEpisode },
      {
        episode,
      }
    );

    // parse the data from response
    const {
      userResponse: { episodeList },
    } = response;

    yield put({
      type: actions.EDIT_EPISODE_SUCCESS,
      payload: {
        episodeList,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_EPISODE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* deleteEpisode({ payload }) {
  try {
    yield put({ type: actions.DELETE_EPISODE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteEpisode },
      { ...payload }
    );

    const {
      userResponse: { episodeList },
    } = response;

    yield put({
      type: actions.DELETE_EPISODE_SUCCESS,
      payload: {
        episodeList,
      },
    });
  } catch (error) {
    message.error(error.message);

    yield put({
      type: actions.DELETE_EPISODE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.UPLOAD_EPISODE_IMAGE_REQUEST, uploadEpisodeImage);
  yield takeLatest(
    actions.UPLOAD_TVPROGRAM_IMAGE_REQUEST,
    uploadTVProgramImage
  );
  yield takeLatest(actions.CREATE_EPISODE_REQUEST, createEpisode);
  yield takeLatest(actions.EDIT_EPISODE_REQUEST, editEpisode);
  yield takeLatest(actions.DELETE_EPISODE_REQUEST, deleteEpisode);
}
