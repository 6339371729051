const faqsActions = {
    FETCH_FAQ_LIST_REQUEST: 'FETCH_FAQ_LIST_REQUEST',
    FETCH_FAQ_LIST_LOADING: 'FETCH_FAQ_LIST_LOADING',
    FETCH_FAQ_LIST_SUCCESS: 'FETCH_FAQ_LIST_SUCCESS',
    FETCH_FAQ_LIST_ERROR: 'FETCH_FAQ_LIST_ERROR',

    CREATE_FAQ_REQUEST: 'CREATE_FAQ_REQUEST',
    CREATE_FAQ_LOADING: 'CREATE_FAQ_LOADING',
    CREATE_FAQ_SUCCESS: 'CREATE_FAQ_SUCCESS',
    CREATE_FAQ_ERROR: 'CREATE_FAQ_ERROR',

    DELETE_FAQ_REQUEST: 'DELETE_FAQ_REQUEST',
    DELETE_FAQ_LOADING: 'DELETE_FAQ_LOADING',
    DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
    DELETE_FAQ_ERROR: 'DELETE_FAQ_ERROR',

    RESET_CREATE_FAQ_STATE: 'RESET_CREATE_FAQ_STATE',
    RESET_FAQ_STATE: 'RESET_FAQ_STATE',

    //SEARCH
    SET_FAQ_SEARCH_TEXT: 'SET_FAQ_SEARCH_TEXT',
    SET_FAQ_FILTER: 'SET_FAQ_FILTER',

    fetchFAQList: page => {
        return {
            type: faqsActions.FETCH_FAQ_LIST_REQUEST,
            payload: {
                page,
            }
        };
    },

    createFAQ: ({
        question,
        answer,
        questionMy,
        answerMy,
        statusName
    }) => {
        return {
            type: faqsActions.CREATE_FAQ_REQUEST,
            payload: {
                question,
                answer,
                questionMy,
                answerMy,
                statusName
            }
        }
    },

    deleteFAQ: (FAQIdentifier) => {
        return {
            type: faqsActions.DELETE_FAQ_REQUEST,
            payload: { FAQIdentifier }
        };
    },

    resetFAQState: () => {
        return {
            type: faqsActions.RESET_FAQ_STATE,
        };
    },

    resetCreateFAQState: () => {
        return {
            type: faqsActions.RESET_CREATE_FAQ_STATE,
        }
    },

    setSearchText: payload => {
        return {
            type: faqsActions.SET_FAQ_SEARCH_TEXT,
            payload,
        }
    },

    setEventFilter: payload => {
        return {
            type: faqsActions.SET_FAQ_FILTER,
            payload,
        }
    },
}

export default faqsActions;