import castActions from "./actions";
import { Status } from "@api";

const initialState = {
  castStatus: Status.DEFAULT,
  castErrorMessage: "",
  createCastStatus: Status.DEFAULT,
  createCastErrorMessage: "",
  deleteCastStatus: Status.DEFAULT,
  deleteCastErrorMessage: "",
  list: [],
  uploadCastImageStatus: Status.DEFAULT,
  uploadCastImageErrorMessage: "",
  castEditStatus: Status.DEFAULT,
  castEditErrorMessage: "",
};

export default function castReducer(state = initialState, { type, payload }) {
  switch (type) {
    case castActions.CREATE_CAST_LOADING:
      return {
        ...state,
        createCastStatus: Status.LOADING,
        createCastErrorMessage: "",
      };
    case castActions.CREATE_CAST_SUCCESS:
      return {
        ...state,
        createCastStatus: Status.SUCCESS,
        createCastErrorMessage: "",

        list: payload.list,
      };
    case castActions.CREATE_CAST_ERROR:
      return {
        ...state,
        createCastStatus: Status.ERROR,
        createCastErrorMessage: payload.errorMessage,
      };

    case castActions.UPLOAD_CAST_IMAGE_LOADING:
      return {
        ...state,
        uploadCastImageStatus: Status.LOADING,
        uploadCastImageErrorMessage: "",
      };
    case castActions.UPLOAD_CAST_IMAGE_SUCCESS:
      return {
        ...state,
        uploadCastImageStatus: Status.SUCCESS,
        uploadCastImageErrorMessage: "",
      };
    case castActions.UPLOAD_CAST_IMAGE_ERROR:
      return {
        ...state,
        uploadCastImageStatus: Status.ERROR,
        uploadCastImageErrorMessage: payload.errorMessage,
      };

    case castActions.EDIT_CAST_LOADING:
      return {
        ...state,
        castEditStatus: Status.LOADING,
        castEditErrorMessage: "",
      };
    case castActions.EDIT_CAST_SUCCESS:
      return {
        ...state,
        castEditStatus: Status.SUCCESS,
        castEditErrorMessage: "",
      };
    case castActions.EDIT_CAST_ERROR:
      return {
        ...state,
        castEditStatus: Status.ERROR,
        castEditErrorMessage: payload.errorMessage,
      };
    case castActions.DELETE_CAST_LOADING:
      return {
        ...state,
        deleteCastStatus: Status.LOADING,
        deleteCastErrorMessage: "",
      };
    case castActions.DELETE_CAST_SUCCESS:
      return {
        ...state,
        deleteCastStatus: Status.SUCCESS,
        deleteCastErrorMessage: "",
      };
    case castActions.DELETE_CAST_ERROR:
      return {
        ...state,
        deleteCastStatus: Status.ERROR,
        deleteCastErrorMessage: payload.errorMessage,
      };
    case castActions.RESET_CAST_STATE:
      return {
        castStatus: Status.DEFAULT,
        castErrorMessage: "",
        createCastStatus: Status.DEFAULT,
        createCastErrorMessage: "",
        deleteCastStatus: Status.DEFAULT,
        deleteCastErrorMessage: "",
        uploadCastImageStatus: Status.DEFAULT,
        uploadCastImageErrorMessage: "",
        castEditStatus: Status.DEFAULT,
        castEditErrorMessage: "",
      };

    default:
      return state;
  }
}
