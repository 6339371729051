/* eslint-disable no-unused-vars */
import { add } from "lodash";
import { getContentMD5 } from "./helpers/headerUtils";
import Request from "./Request";
import { key } from "styled-theme";
import { retry } from "redux-saga/effects";

class Api {
  constructor() {
    this.request = new Request();
  }

  /**
   * @param {Object} options                - contain extra configuration for request object
   * @param {string} deviceId               - device id
   * @param {string} deviceName             - device name
   * @param {string} sessionId              - session id if user is logged in
   * @param {function} sessionExpiredAction - A redux action which will be dispatch if session is expired
   */
  init({ deviceId, deviceName, sessionExpiredAction }) {
    this.request.init({
      deviceId,
      deviceName,
      sessionExpiredAction,
    });
  }

  // ================================ APIs =================================
  login({ email, password, ipAddress }) {
    const userRequest = {
      email,
      ipAddress,
    };
    userRequest.password = getContentMD5(password);
    return this.request.post("/v01/adminLogin", null, { userRequest });
  }

  logout() {
    return this.request.post("/v01/adminLogout", null, null);
  }

  createAdmin({
    adminName,
    email,
    adminRole,
    gender,
    mobileNumber,
    address,
    statusName,
  }) {
    const userRequest = {
      adminName,
      email,
      adminRole,
      gender,
      mobileNumber,
      address,
      statusName,
    };
    return this.request.post("/v01/createAdmin", null, { userRequest });
  }

  fetchAdminList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchAdminList", { userRequest }, null);
  }

  fetchAdminDetails({ adminIdentifier }) {
    const userRequest = {
      adminIdentifier,
    };
    return this.request.get("/v01/fetchAdminDetails", { userRequest }, null);
  }

  editAdmin({
    adminName,
    email,
    adminRole,
    gender,
    mobileNumber,
    address,
    statusName,
    adminIdentifier,
  }) {
    const userRequest = {
      adminName,
      email,
      adminRole,
      gender,
      statusName,
      adminIdentifier,
    };
    if (mobileNumber) {
      userRequest.mobileNumber = mobileNumber;
    }
    if (address) {
      userRequest.address = address;
    }
    return this.request.put("/v01/editAdmin", null, { userRequest });
  }

  adminForgotPassword({ email }) {
    const userRequest = { email };

    return this.request.post("/v01/adminForgotPassword", null, { userRequest });
  }

  adminChangePassword({ currentPassword, newPassword, confirmPassword }) {
    const userRequest = { currentPassword, newPassword, confirmPassword };

    userRequest.currentPassword = getContentMD5(currentPassword);
    userRequest.newPassword = getContentMD5(newPassword);
    userRequest.confirmPassword = getContentMD5(confirmPassword);

    return this.request.put("/v01/adminChangePassword", null, { userRequest });
  }

  fetchPosterList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchPosterList", { userRequest }, null);
  }

  showBannerPoster(posterIdentifier) {
    const userRequest = {
      posterIdentifier,
    };
    return this.request.post("/v01/showBannerPoster", null, { userRequest });
  }

  showBanner(movie) {
    const userRequest = {
      ...movie,
    };
    return this.request.post("/v01/showBanner", null, { userRequest });
  }

  fetchMemberList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchMemberList", { userRequest }, null);
  }

  uploadMedia({ file, fileCategory, fileDirectory, entityIdentifier }) {
    const userRequest = {
      fileCategory,
      fileDirectory,
    };
    if (entityIdentifier) {
      userRequest.entityIdentifier = entityIdentifier;
    }
    let data = new FormData();
    data.append("userRequest[file]", file);
    data.append("userRequest[fileCategory]", fileCategory);
    data.append("userRequest[fileDirectory]", fileDirectory);
    if (entityIdentifier) {
      data.append("userRequest[entityIdentifier]", entityIdentifier);
    }
    return this.request.post("/v01/s3FileUpload", { userRequest }, data, true);
  }

  fetchMovieList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchMovieList", { userRequest }, null);
  }

  createPosterLink({ posterIdentifier, entityIdentifier, entityType }) {
    const userRequest = {
      posterIdentifier,
      entityIdentifier,
      entityType,
    };
    return this.request.post("/v01/createPosterLink", null, { userRequest });
  }

  removePosterLink({ posterIdentifier, entityIdentifier, entityType }) {
    const userRequest = {
      posterIdentifier,
      entityIdentifier,
      entityType,
    };
    return this.request.delete("/v01/removePosterLink", null, { userRequest });
  }

  fetchHostUserList() {
    return this.request.get("/v01/fetchHostUserList", null, null);
  }

  fetchCategoryList() {
    return this.request.get("/v01/fetchCategoryList", null, null);
  }

  fetchSourceList() {
    return this.request.get("/v01/fetchSourceList", null, null);
  }

  createMovie({ ...params }) {
    const userRequest = {
      ...params,
    };

    return this.request.post("/v01/createMovie", null, { userRequest });
  }

  fetchMovieDetails({ movieIdentifier }) {
    const userRequest = {
      movieIdentifier,
    };

    return this.request.get("/v01/fetchMovieDetails", { userRequest }, null);
  }

  editMovie({ description, hostIdentifier, ...params }) {
    const userRequest = {
      ...params,
    };
    if (hostIdentifier) {
      userRequest.hostIdentifier = hostIdentifier;
    }
    if (description) {
      userRequest.description = description;
    }
    return this.request.put("/v01/editMovie", null, { userRequest });
  }

  // videoFileUpload({ broadcastIdentifier, fileName, mimeType, fileSize }) {
  //   const userRequest = {
  //     broadcastIdentifier,
  //     fileName,
  //     mimeType,
  //     fileSize,
  //   };
  //   return this.request.post("/v01/videoFileUpload", null, { userRequest });
  // }

  uploadStatus({ broadcastIdentifier, uploadIdentifier }) {
    const userRequest = {
      broadcastIdentifier,
      uploadIdentifier,
    };
    return this.request.put("/v01/uploadStatus", null, { userRequest });
  }

  notifyUploadStatus({ broadcastIdentifier }) {
    const userRequest = {
      broadcastIdentifier,
    };
    return this.request.get("/v01/notifyUploadStatus", { userRequest }, null);
  }

  requestPresignedUrl({ presignedUrl, file }) {
    return this.request.put("", null, file, presignedUrl);
  }

  fetchEventList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchEventList", { userRequest }, null);
  }

  createEvent({ shortTitle, description, hostIdentifier, ...params }) {
    const userRequest = {
      ...params,
    };

    if (shortTitle) {
      userRequest.shortTitle = shortTitle;
    }

    if (description) {
      userRequest.description = description;
    }

    if (hostIdentifier) {
      userRequest.hostIdentifier = hostIdentifier;
    }
    return this.request.post("/v01/createEvent", null, { userRequest });
  }

  fetchInvoiceList({ page, keyword, startDate, endDate }) {
    const userRequest = {
      filter: { startDate, endDate },
      pagination: {
        page,
      },
    };

    if (keyword) {
      userRequest.keyword = keyword;
    }
    return this.request.get("/v01/fetchInvoiceList", { userRequest }, null);
  }

  checkDuplicateTicketCode({ ticketPrefixCode, ticketInfoIdentifier }) {
    const userRequest = {
      ticketPrefixCode,
    };
    if (ticketInfoIdentifier) {
      userRequest.ticketInfoIdentifier = ticketInfoIdentifier;
    }
    return this.request.post("/v01/checkDuplicateTicketCode", null, {
      userRequest,
    });
  }

  fetchInvoiceDetails({ ticketInvoiceIdentifier, page }) {
    const userRequest = {
      ticketInvoiceIdentifier,
      pagination: {
        page,
      },
    };
    return this.request.get("/v01/fetchInvoiceDetails", { userRequest }, null);
  }

  fetchTicketList({ ticketInvoiceIdentifier, page }) {
    const userRequest = {
      ticketInvoiceIdentifier,
      pagination: {
        page,
      },
    };
    return this.request.get("/v01/fetchTicketList", { userRequest }, null);
  }

  fetchEventDetails({ eventIdentifier }) {
    const userRequest = {
      eventIdentifier,
    };
    return this.request.get("/v01/fetchEventDetails", { userRequest }, null);
  }

  editEvent({ shortTitle, description, hostIdentifier, ...params }) {
    const userRequest = {
      ...params,
    };
    if (hostIdentifier) {
      userRequest.hostIdentifier = hostIdentifier;
    }
    if (shortTitle) {
      userRequest.shortTitle = shortTitle;
    }
    if (description) {
      userRequest.description = description;
    }
    return this.request.put("/v01/editEvent", null, { userRequest });
  }

  changeTicketToNewStatus({ ticketSaleDetailIdentifier, statusName }) {
    const userRequest = {
      ticketSaleDetailIdentifier,
      statusName,
    };
    return this.request.put("/v01/changeTicketToNewStatus", null, {
      userRequest,
    });
  }

  fetchDashboardData() {
    return this.request.get("/v01/fetchDashboardData", null, null);
  }

  fetchToBeExpiredShows() {
    return this.request.get("/v01/fetchToBeExpiredShows", null, null);
  }

  fetchGiftTicketList({ keyword, page }) {
    const userRequest = {
      keyword,
      pagination: {
        page,
      },
    };
    return this.request.get("/v01/fetchFreeTicketList", { userRequest }, null);
  }

  fetchFreeInvoiceList({ entityIdentifier, entityType, page }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      pagination: {
        page,
      },
    };
    return this.request.get("/v01/fetchFreeInvoiceList", { userRequest }, null);
  }

  fetchFreeInvoiceDetails({ ticketInvoiceIdentifier }) {
    const userRequest = {
      ticketInvoiceIdentifier,
    };
    return this.request.get(
      "/v01/fetchFreeInvoiceDetails",
      { userRequest },
      null
    );
  }

  createFreeTicket({ entityIdentifier, ticketCount, entityType }) {
    const userRequest = {
      entityIdentifier,
      ticketCount,
      entityType,
    };
    return this.request.post("/v01/generateFreeTicket", null, { userRequest });
  }

  deleteEvent({ eventIdentifier }) {
    const userRequest = {
      eventIdentifier,
    };
    return this.request.delete("/v01/deleteEvent", null, { userRequest });
  }

  deleteMovie({ movieIdentifier }) {
    const userRequest = {
      movieIdentifier,
    };
    return this.request.delete("/v01/deleteMovie", null, { userRequest });
  }

  //FAQs

  fetchFAQList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchFAQList", { userRequest }, null);
  }

  fetchFAQDetails({ FAQIdentifier }) {
    const userRequest = {
      FAQIdentifier,
    };
    return this.request.get("/v01/fetchFAQDetails", { userRequest }, null);
  }

  createFAQ({ question, answer, questionMy, answerMy, statusName }) {
    const userRequest = {
      question,
      answer,
      questionMy,
      answerMy,
      statusName,
    };
    return this.request.post("/v01/createFAQ", null, { userRequest });
  }

  editFAQ({
    FAQIdentifier,
    question,
    answer,
    questionMy,
    answerMy,
    statusName,
  }) {
    const userRequest = {
      FAQIdentifier,
      question,
      answer,
      questionMy,
      answerMy,
      statusName,
    };
    return this.request.put("/v01/editFAQ", null, { userRequest });
  }

  deleteFAQ({ FAQIdentifier }) {
    const userRequest = { FAQIdentifier };
    return this.request.delete("/v01/deleteFAQ", null, { userRequest });
  }

  //Production House
  createProductionHouse({ name, nameMy, statusName }) {
    const userRequest = {
      name,
      nameMy,
      statusName,
    };
    return this.request.post("/v01/createProductionHouse", null, {
      userRequest,
    });
  }

  fetchProductionHouseList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get(
      "/v01/fetchProductionHouseList",
      { userRequest },
      null
    );
  }

  editProductionHouse({ name, nameMy, statusName, productionHouseIdentifier }) {
    const userRequest = {
      name,
      nameMy,
      statusName,
      productionHouseIdentifier,
    };
    return this.request.put("/v01/editProductionHouse", null, { userRequest });
  }

  fetchFreeTicketList({ entityIdentifier, entityType, page }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      pagination: {
        page,
      },
    };
    return this.request.get("/v01/fetchFreeTicketList", { userRequest }, null);
  }

  updateOutdate({ venueIdentifier }) {
    const userRequest = {
      venueIdentifier,
    };
    return this.request.post("/v01/updateOutdate", null, { userRequest });
  }

  fetchReportSummaryByEventVenue({ keyword, page }) {
    const userRequest = {
      keyword,
      pagination: {
        page,
      },
    };

    return this.request.get(
      "/v01/fetchReportSummaryByEventVenue",
      { userRequest },
      null
    );
  }

  fetchPeriodReportList({ searchText, period, month, year, page }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (searchText) {
      userRequest.keyword = searchText;
    }
    if (period && (month || year)) {
      userRequest.period = period;
    }
    if (year) {
      userRequest.year = year;
    }
    if (month) {
      userRequest.month = month;
    }
    return this.request.get("/v01/fetchReportPeriod", { userRequest }, null);
  }

  fetchInvoiceListByShow({ entityType, entityIdentifier, searchText, page }) {
    const userRequest = {
      entityType,
      entityIdentifier,
      pagination: {
        page,
      },
    };
    if (searchText) {
      userRequest.keyword = searchText;
    }
    return this.request.get(
      "/v01/fetchInvoiceListByShow",
      { userRequest },
      null
    );
  }

  videoFileUpload({ entityIdentifier, entityType, filePath }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      filePath,
    };

    return this.request.post("/v01/videoFileUpload", null, { userRequest });
  }

  //Genre
  createGenre({ name, nameMy }) {
    const userRequest = {
      name,
      nameMy,
    };
    return this.request.post("/v01/createGenre", null, { userRequest });
  }

  fetchGenreList({ page, keyword }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    return this.request.get("/v01/fetchGenreList", { userRequest }, null);
  }

  editGenre({ name, nameMy, genreIdentifier }) {
    const userRequest = {
      name,
      nameMy,
      genreIdentifier,
    };
    return this.request.put("/v01/editGenre", null, { userRequest });
  }

  deleteGenre({ genreIdentifier }) {
    const userRequest = { genreIdentifier };
    return this.request.delete("/v01/deleteGenre", null, { userRequest });
  }

  editCast({ cast }) {
    const userRequest = {
      ...cast,
    };

    return this.request.put("/v01/editCast", null, { userRequest });
  }

  createCast(data) {
    const userRequest = {
      ...data,
    };

    return this.request.post("/v01/createCast", null, { userRequest });
  }
  deleteCast({ castIdentifier }) {
    const userRequest = {
      castIdentifier,
    };
    return this.request.delete("/v01/deleteCast", null, { userRequest });
  }

  updateTicket(data) {
    const userRequest = {
      ...data,
    };
    return this.request.post("/v01/updateTicket", null, { userRequest });
  }

  fetchSeriesList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchSeriesList", { userRequest }, null);
  }

  createSeries({ ...params }) {
    const userRequest = {
      ...params,
    };

    return this.request.post("/v01/createSeries", null, { userRequest });
  }

  deleteSeries({ seriesIdentifier }) {
    const userRequest = {
      seriesIdentifier,
    };
    return this.request.delete("/v01/deleteSeries", null, { userRequest });
  }

  fetchSeriesDetails({ seriesIdentifier }) {
    const userRequest = {
      seriesIdentifier,
    };

    return this.request.get("/v01/fetchSeriesDetails", { userRequest }, null);
  }

  editSeries({ ...params }) {
    const userRequest = {
      ...params,
    };

    return this.request.put("/v01/editSeries", null, { userRequest });
  }

  editEpisode({ episode }) {
    const userRequest = {
      ...episode,
    };

    return this.request.put("/v01/editEpisode", null, { userRequest });
  }

  createEpisode(data) {
    const userRequest = {
      ...data,
    };

    return this.request.post("/v01/createEpisode", null, { userRequest });
  }
  deleteEpisode(data) {
    const userRequest = {
      ...data,
    };
    return this.request.delete("/v01/deleteEpisode", null, { userRequest });
  }

  fetchTvProgramList({ page, keyword, statusName, categoryName, privacyName }) {
    const userRequest = {
      categoryName,
      privacyName,
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }
    return this.request.get("/v01/fetchTvProgramList", { userRequest }, null);
  }

  createTvProgram({ ...params }) {
    const userRequest = {
      ...params,
    };

    return this.request.post("/v01/createTvProgram", null, { userRequest });
  }

  fetchTvProgramDetails({ tvProgramIdentifier }) {
    const userRequest = {
      tvProgramIdentifier,
    };

    return this.request.get(
      "/v01/fetchTvProgramDetails",
      { userRequest },
      null
    );
  }

  editTvProgram({ ...params }) {
    const userRequest = {
      ...params,
    };

    return this.request.put("/v01/editTvProgram", null, { userRequest });
  }

  deleteTvProgram({ tvProgramIdentifier }) {
    const userRequest = {
      tvProgramIdentifier,
    };
    return this.request.delete("/v01/deleteTvProgram", null, { userRequest });
  }

  // createGallery(data) {
  //   const userRequest = {
  //     ...data,
  //   };

  //   return this.request.post("/v01/createGallery", null, { userRequest });
  // }

  createGallery(payload) {
    const userRequest = {
      entityIdentifier: payload?.entityIdentifier,
      entityType: payload?.entityType,
      fileType: payload?.fileType,
      filePath: payload?.filePath,
    };

    let data = new FormData();
    data.append("userRequest[entityIdentifier]", payload.entityIdentifier);
    data.append("userRequest[fileType]", payload.fileType);
    data.append("userRequest[entityType]", payload.entityType);
    if (payload?.fileType === "video") {
      data.append("userRequest[filePath]", payload.filePath);
    }
    data.append("userRequest[file]", payload.file);

    return this.request.post("/v01/createGallery", { userRequest }, data, true);
  }

  deleteGallery({ galleryIdentifier }) {
    const userRequest = {
      galleryIdentifier,
    };
    return this.request.delete("/v01/deleteGallery", null, { userRequest });
  }

  fetchPackageList({ page, keyword, statusName }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    if (keyword) {
      userRequest.keyword = keyword;
    }
    if (statusName) {
      userRequest.statusName = statusName;
    }

    return this.request.get(
      "/v01/fetchPromotionPackageList",
      { userRequest },
      null
    );
  }

  createPackage({ ...params }) {
    const userRequest = {
      ...params,
    };

    return this.request.post("/v01/createPromotionPackage", null, {
      userRequest,
    });
  }

  deletePackage({ packageIdentifier }) {
    const userRequest = {
      packageIdentifier,
    };
    return this.request.delete("/v01/deletePackage", null, { userRequest });
  }

  fetchPackageDetails({ packageIdentifier }) {
    const userRequest = {
      promotionIdentifier: packageIdentifier,
    };

    return this.request.get(
      "/v01/fetchPromotionPackageDetails",
      { userRequest },
      null
    );
  }

  editPackage({ ...params }) {
    const userRequest = {
      ...params,
    };

    return this.request.put("/v01/editPromotionPackage", null, { userRequest });
  }

  /**
   * After a successful login, save session id into
   * request object, which will be send in Headers
   */
  setSessionId(sessionId) {
    this.request.setSessionId(sessionId);
  }
}

const api = new Api();

export default api;
