import { call, takeLatest, put, select } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { LIMITS } from "@constants";
import { message } from "antd";
import _ from "lodash";
/**
 * worker saga: Calls the fetch ticket list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchMovieList({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_MOVIE_LIST_LOADING });

    let {
      searchText: keyword,
      filter: statusName,
      list: extList,
    } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.fetchMovieList },
      { page, keyword, statusName }
    );

    // parse the data from response
    const {
      userResponse: { count, movieList: newList },
    } = response;

    yield put({
      type: actions.FETCH_MOVIE_LIST_SUCCESS,
      payload: {
        count,
        list: page === 1 ? newList : [...extList, ...newList],
        hasMore: newList.length >= LIMITS.PAGE_SIZE,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_MOVIE_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the create movie API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* createMovie({ payload }) {
  try {
    yield put({ type: actions.CREATE_MOVIE_LOADING });

    const { list } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.createMovie },
      { ...payload }
    );

    // parse the data from response
    const {
      userResponse: { count, ...newMovie },
    } = response;

    yield put({
      type: actions.CREATE_MOVIE_SUCCESS,
      payload: {
        count,
        list: [newMovie, ...list],
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_MOVIE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the delete movie API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* deleteMovie({ payload: { movieIdentifier } }) {
  try {
    yield put({ type: actions.DELETE_MOVIE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteMovie },
      { movieIdentifier }
    );

    const { userResponse: count } = response;

    message.success("Movie was deleted successfully!");

    const { list } = yield select(getDataFromStore);

    let filteredList = _.remove(
      list,
      (item) => item.movieIdentifier !== movieIdentifier
    );

    yield put({
      type: actions.DELETE_MOVIE_SUCCESS,
      payload: {
        list: filteredList,
        count,
      },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_MOVIE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch  details API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.movieIdentifier       - identifier
 */
function* fetchMovieDetails({ payload: { movieIdentifier } }) {
  try {
    yield put({ type: actions.FETCH_MOVIE_DETAIL_LOADING });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchMovieDetails },
      { movieIdentifier }
    );

    // parse the data from response
    const { userResponse: movieDetails } = response;

    yield put({
      type: actions.FETCH_MOVIE_DETAIL_SUCCESS,
      payload: {
        movieDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_MOVIE_DETAIL_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the show banner API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* showBanner({ payload }) {
  try {
    yield put({ type: actions.SHOW_BANNER_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.showBanner },
      { ...payload }
    );

    // parse the data from response
    const { userResponse } = response;
    const { list } = yield select(getDataFromStore);

    let newList = _.map(list, (item) => {
      if (item.movieIdentifier === userResponse?.entityIdentifier) {
        return {
          ...item,
          showBanner: userResponse?.showBanner,
          showBannerButton: userResponse?.showBannerButton,
        };
      } else {
        return item;
      }
    });

    yield put({
      type: actions.SHOW_BANNER_SUCCESS,
      payload: {
        newList,
      },
    });
  } catch (error) {
    yield put({
      type: actions.SHOW_BANNER_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the create movie API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* editMovie({ payload }) {
  try {
    yield put({ type: actions.EDIT_MOVIE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.editMovie },
      { ...payload }
    );

    // parse the data from response
    const { userResponse: movieDetails } = response;

    yield put({
      type: actions.EDIT_MOVIE_SUCCESS,
      payload: {
        movieDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_MOVIE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ movies }) => {
  const { searchText, filter, list } = movies;
  return {
    searchText,
    filter,
    list,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.FETCH_MOVIE_LIST_REQUEST, fetchMovieList);
  yield takeLatest(actions.FETCH_MOVIE_DETAIL_REQUEST, fetchMovieDetails);
  yield takeLatest(actions.CREATE_MOVIE_REQUEST, createMovie);
  yield takeLatest(actions.EDIT_MOVIE_REQUEST, editMovie);
  yield takeLatest(actions.SHOW_BANNER_REQUEST, showBanner);
  yield takeLatest(actions.DELETE_MOVIE_REQUEST, deleteMovie);
}
