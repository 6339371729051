import { call, takeLatest, put, select } from 'redux-saga/effects';
import { api } from '@api';
import actions from './actions';
import _ from 'lodash';

/**
 * worker saga: Calls the Post create admin API.
 */
function* createAdmin({ payload: {
  adminName,
  email,
  adminRole,
  gender,
  mobileNumber,
  address,
  statusName,
} }) {
  try {
    yield put({ type: actions.CREATE_ADMIN_LOADING });

    /*** get data from reducer ***/
    let {
      list,
    } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.createAdmin },
      { adminName, email, adminRole, gender, mobileNumber, address, statusName },
    );

    // parse the data from response
    const { userResponse: newAdmin } = response;

    let newAdminList = [
      newAdmin,
      ...list,
    ]

    yield put({
      type: actions.CREATE_ADMIN_SUCCESS,
      payload: {
        newAdminList,
        newAdmin,
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_ADMIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
* worker saga: Remove session id from Local storage
*/
function* fetchAdminDetails({ payload: { adminIdentifier } }) {
  try {
    yield put({ type: actions.FETCH_ADMIN_DETAILS_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.fetchAdminDetails },
      { adminIdentifier },
    );
    /**
     * {
     * adminId: '',
     * adminName: '',
     * ...
     * }
     * 
     */
    const {
      userResponse //obj {}
    } = response;

    yield put({
      type: actions.FETCH_ADMIN_DETAILS_SUCCESS,
      payload: {
        ...userResponse,
        /**
        
          adminId:'',
          adminName: '',
        
        */
      }
    });

  } catch (error) {
    yield put({
      type: actions.FETCH_ADMIN_DETAILS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch admin list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchAdminList({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_ADMIN_LIST_LOADING });

    //*** get data from reducer ***
    let {
      searchText: keyword,
      filter: statusName,
      list: extList,
    } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.fetchAdminList },
      { page, keyword, statusName },
    );

    // parse the data from response
    const {
      userResponse: {
        count,
        adminList: newList,
      },
    } = response;

    const _list = page === 1 ? newList : [...extList, ...newList];

    yield put({
      type: actions.FETCH_ADMIN_LIST_SUCCESS,
      payload: {
        count,
        list: _list,
        hasMore: newList.length >= 12,
        selectedAdmin: _list[0],
      }
    });

  } catch (error) {
    yield put({
      type: actions.FETCH_ADMIN_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the Put edit admin API.
 */
function* editAdmin({ payload: {
  adminName,
  email,
  adminRole,
  gender,
  mobileNumber,
  address,
  statusName,
  adminIdentifier
} }) {
  try {
    yield put({ type: actions.EDIT_ADMIN_LOADING });

    //*** get data from reducer ***
    let {
      list,
    } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.editAdmin },
      { adminName, email, adminRole, gender, mobileNumber, address, statusName, adminIdentifier },
    );

    // parse the data from response
    const {
      userResponse
    } = response;

    let _list = _.clone(list);

    for (let i = 0; i < _list.length; i++) {
      if (_list[i].adminIdentifier === adminIdentifier) {
        _list[i] = userResponse
      }
    }

    yield put({
      type: actions.EDIT_ADMIN_SUCCESS,
      payload: {
        updatedAdmin: userResponse,
        updatedList: _list
      },
    });

  } catch (error) {
    yield put({
      type: actions.EDIT_ADMIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* editCurrentAdmin({ payload: {
  adminName,
  email,
  adminRole,
  gender,
  mobileNumber,
  address,
  statusName,
  adminIdentifier
} }) {
  try {
    yield put({ type: actions.EDIT_CURRENT_ADMIN_LOADING });

    //*** get data from reducer ***
    let {
      list,
    } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.editAdmin },
      { adminName, email, adminRole, gender, mobileNumber, address, statusName, adminIdentifier },
    );

    // parse the data from response
    const {
      userResponse
    } = response;

    let _list = _.clone(list);

    for (let i = 0; i < _list.length; i++) {
      if (_list[i].adminIdentifier === adminIdentifier) {
        _list[i] = userResponse
      }
    }

    yield put({
      type: actions.EDIT_CURRENT_ADMIN_SUCCESS,
      payload: {
        updatedCurrentAdmin: userResponse,
        updatedList: _list
      },
    });
  }
  catch (error) {
    yield put({
      type: actions.EDIT_CURRENT_ADMIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the upload poster image API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload       
 */
function* uploadAdminUserImage({ payload: { file, fileCategory, fileDirectory, entityIdentifier } }) {
  try {
    yield put({ type: actions.UPLOAD_ADMIN_IMAGE_LOADING });

    //api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier },
    );

    const {
      userResponse: { profileImagePath }
    } = response;


    // send parsed data into action payload
    yield put({
      type: actions.UPLOAD_ADMIN_IMAGE_SUCCESS,
      payload: {
        profileImagePath,
      },
    });
  }
  catch (error) {
    yield put({
      type: actions.UPLOAD_ADMIN_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ Admin }) => {
  const { searchText, filter, list, } = Admin;
  return {
    searchText,
    filter,
    list,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.FETCH_ADMIN_DETAILS_REQUEST, fetchAdminDetails);
  yield takeLatest(actions.FETCH_ADMIN_LIST_REQUEST, fetchAdminList);
  yield takeLatest(actions.EDIT_ADMIN_REQUEST, editAdmin);
  yield takeLatest(actions.CREATE_ADMIN_REQUEST, createAdmin);
  yield takeLatest(actions.UPLOAD_ADMIN_IMAGE_REQUEST, uploadAdminUserImage);
  yield takeLatest(actions.EDIT_CURRENT_ADMIN_REQUEST, editCurrentAdmin);
}