import ticketsActions from "./actions";
import { Status } from "@api";

const initialState = {
  ticketStatus: Status.DEFAULT,
  ticketErrorMessage: "",
};
export default function ticketReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ticketsActions.UPDATE_TICKET_LOADING:
      return {
        ticketStatus: Status.LOADING,
        ticketErrorMessage: "",
      };
    case ticketsActions.UPDATE_TICKET_SUCCESS:
      return {
        ticketStatus: Status.SUCCESS,
        ticketErrorMessage: "",
      };
    case ticketsActions.UPDATE_TICKET_ERROR:
      return {
        ticketStatus: Status.ERROR,
        ticketErrorMessage: payload.errorMessage,
      };
    case ticketsActions.RESET_TICKET_UPDATE_STATE:
      return {
        ...state,
        ticketStatus: Status.DEFAULT,
        ticketErrorMessage: "",
      };
    default:
      return state;
  }
}
