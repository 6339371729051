import { call, takeLatest, put, select } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { message } from "antd";

/**
 * worker saga: Calls the create venue API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* createCast({ payload }) {
  try {
    yield put({ type: actions.CREATE_CAST_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.createCast },
      { ...payload }
    );

    // parse the data from response
    const {
      userResponse: { castList },
    } = response;

    yield put({
      type: actions.CREATE_CAST_SUCCESS,
      payload: {
        list: [...castList],
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_CAST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* uploadCastImage({
  payload: { file, fileCategory, fileDirectory, entityIdentifier },
}) {
  try {
    yield put({ type: actions.UPLOAD_CAST_IMAGE_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      { file, fileCategory, fileDirectory, entityIdentifier }
    );

    message.success("Photo is uploaded successfully!");
    const {
      userResponse: { identifier, profileImagePath },
    } = response;

    yield put({
      type: actions.UPLOAD_CAST_IMAGE_SUCCESS,
      payload: {
        identifier,
        profileImagePath,
      },
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_CAST_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* editCast({ payload: { cast } }) {
  try {
    yield put({ type: actions.EDIT_CAST_LOADING });

    //*** get data from reducer ***

    // api call
    const response = yield call(
      { context: api, fn: api.editCast },
      {
        cast,
      }
    );

    // parse the data from response
    const {
      userResponse: { castList },
    } = response;

    yield put({
      type: actions.EDIT_CAST_SUCCESS,
      payload: {
        castList,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_CAST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* deleteCast({ payload: { castIdentifier } }) {
  try {
    yield put({ type: actions.DELETE_CAST_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteCast },
      { castIdentifier }
    );

    const {
      userResponse: { castList },
    } = response;

    yield put({
      type: actions.DELETE_CAST_SUCCESS,
      payload: {
        castList,
      },
    });
  } catch (error) {
    message.error(error.message);

    yield put({
      type: actions.DELETE_CAST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.UPLOAD_CAST_IMAGE_REQUEST, uploadCastImage);
  yield takeLatest(actions.CREATE_CAST_REQUEST, createCast);
  yield takeLatest(actions.EDIT_CAST_REQUEST, editCast);
  yield takeLatest(actions.DELETE_CAST_REQUEST, deleteCast);
}
