const packageActions = {
  FETCH_PACKAGE_LIST_REQUEST: "FETCH_PACKAGE_LIST_REQUEST",
  FETCH_PACKAGE_LIST_LOADING: "FETCH_PACKAGE_LIST_LOADING",
  FETCH_PACKAGE_LIST_SUCCESS: "FETCH_PACKAGE_LIST_SUCCESS",
  FETCH_PACKAGE_LIST_ERROR: "FETCH_PACKAGE_LIST_ERROR",

  CREATE_PACKAGE_REQUEST: "CREATE_PACKAGE_REQUEST",
  CREATE_PACKAGE_LOADING: "CREATE_PACKAGE_LOADING",
  CREATE_PACKAGE_SUCCESS: "CREATE_PACKAGE_SUCCESS",
  CREATE_PACKAGE_ERROR: "CREATE_PACKAGE_ERROR",

  DELETE_PACKAGE_REQUEST: "DELETE_PACKAGE_REQUEST",
  DELETE_PACKAGE_LOADING: "DELETE_PACKAGE_LOADING",
  DELETE_PACKAGE_SUCCESS: "DELETE_PACKAGE_SUCCESS",
  DELETE_PACKAGE_ERROR: "DELETE_PACKAGE_ERROR",

  RESET_CREATE_PACKAGE_STATE: "RESET_CREATE_PACKAGE_STATE",
  RESET_PACKAGE_STATE: "RESET_PACKAGE_STATE",

  //SEARCH
  SET_PACKAGE_SEARCH_TEXT: "SET_PACKAGE_SEARCH_TEXT",
  SET_PACKAGE_FILTER: "SET_PACKAGE_FILTER",

  FETCH_PACKAGE_DETAIL_REQUEST: "FETCH_PACKAGE_DETAIL_REQUEST",
  FETCH_PACKAGE_DETAIL_LOADING: "FETCH_PACKAGE_DETAIL_LOADING",
  FETCH_PACKAGE_DETAIL_SUCCESS: "FETCH_PACKAGE_DETAIL_SUCCESS",
  FETCH_PACKAGE_DETAIL_ERROR: "FETCH_PACKAGE_DETAIL_ERROR",

  EDIT_PACKAGE_REQUEST: "EDIT_PACKAGE_REQUEST",
  EDIT_PACKAGE_LOADING: "EDIT_PACKAGE_LOADING",
  EDIT_PACKAGE_SUCCESS: "EDIT_PACKAGE_SUCCESS",
  EDIT_PACKAGE_ERROR: "EDIT_PACKAGE_ERROR",

  fetchPackageDetails: (packageIdentifier) => {
    return {
      type: packageActions.FETCH_PACKAGE_DETAIL_REQUEST,
      payload: {
        packageIdentifier,
      },
    };
  },

  fetchPackageList: (page) => {
    return {
      type: packageActions.FETCH_PACKAGE_LIST_REQUEST,
      payload: {
        page,
      },
    };
  },

  createPackage: (payload) => {
    return {
      type: packageActions.CREATE_PACKAGE_REQUEST,
      payload: {
        ...payload,
      },
    };
  },

  deletePackage: (packageIdentifier) => {
    return {
      type: packageActions.DELETE_PACKAGE_REQUEST,
      payload: { packageIdentifier },
    };
  },

  editPackage: (payload) => {
    return {
      type: packageActions.EDIT_PACKAGE_REQUEST,
      payload: {
        ...payload,
      },
    };
  },

  resetPackageState: () => {
    return {
      type: packageActions.RESET_PACKAGE_STATE,
    };
  },

  setSearchText: (payload) => {
    return {
      type: packageActions.SET_PACKAGE_SEARCH_TEXT,
      payload,
    };
  },
  setSeriesFilter: (payload) => {
    return {
      type: packageActions.SET_PACKAGE_FILTER,
      payload,
    };
  },
  //   setEventFilter: (payload) => {
  //     return {
  //       type: packageActions.SET_PACKAGE_FILTER,
  //       payload,
  //     };
  //   },
};

export default packageActions;
