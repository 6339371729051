import { call, put, select, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { api } from '@api';
import { LIMITS } from '@constants';
import { message } from "antd";

/**
 * worker saga: Calls the fetch FAQList list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchFAQList({ payload: { page = 1 } }) {
    try {
        yield put({ type: actions.FETCH_FAQ_LIST_LOADING });

        let {
            searchText: keyword,
            filter: statusName,
            list: extList,
        } = yield select(getDataFromStore);

        // api call
        const response = yield call(
            { context: api, fn: api.fetchFAQList },
            { page, keyword, statusName },
        );

        // parse the data from response
        const {
            userResponse: {
                FAQList: newList,
                count,
            }
        } = response;

        yield put({
            type: actions.FETCH_FAQ_LIST_SUCCESS,
            payload: {
                count,
                list: page === 1 ? newList : [...extList, ...newList],
                hasMore: newList?.length >= LIMITS.PAGE_SIZE,
            },
        });
    }
    catch (error) {
        yield put({
            type: actions.FETCH_FAQ_LIST_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * worker saga: Calls the create FAQ API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload       
 */
function* createFAQ({ payload }) {
    try {
        yield put({ type: actions.CREATE_FAQ_LOADING });

        const { list } = yield select(getDataFromStore);

        // api call
        const response = yield call(
            { context: api, fn: api.createFAQ },
            { ...payload },
        );

        // parse the data from response
        const { userResponse: newFAQ } = response;

        let newFAQList = [
            newFAQ,
            ...list
        ]

        yield put({
            type: actions.CREATE_FAQ_SUCCESS,
            payload: {
                newFAQList,
                newFAQ,
                count: newFAQ.count
            },
        });
    }
    catch (error) {
        yield put({
            type: actions.CREATE_FAQ_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * worker saga: Calls the delete FAQ API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload       
 */

function* deleteFAQ({ payload: { FAQIdentifier } }) {
    try {
        yield put({ type: actions.DELETE_FAQ_LOADING });

        // api call
        const response = yield call(
            { context: api, fn: api.deleteFAQ },
            { FAQIdentifier },
        );

        // const { userResponse } = response;

        message.success('FAQ was deleted successfully!');

        const { list } = yield select(getDataFromStore);
        let filteredList = list.filter(item => item.FAQIdentifier !== FAQIdentifier);

        yield put({
            type: actions.DELETE_FAQ_SUCCESS,
            payload: {
                list: filteredList,
                //   count,
            },
        });

    }
    catch (error) {
        yield put({
            type: actions.DELETE_FAQ_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

const getDataFromStore = ({ faqs }) => {
    const { searchText, filter, list } = faqs;
    return {
        searchText,
        filter,
        list
    };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
    yield takeLatest(actions.FETCH_FAQ_LIST_REQUEST, fetchFAQList);
    yield takeLatest(actions.CREATE_FAQ_REQUEST, createFAQ);
    yield takeLatest(actions.DELETE_FAQ_REQUEST, deleteFAQ);
}