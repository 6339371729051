const genresActions = {
  CREATE_GENRE_REQUEST: 'CREATE_GENRE_REQUEST',
  CREATE_GENRE_LOADING: 'CREATE_GENRE_LOADING',
  CREATE_GENRE_SUCCESS: 'CREATE_GENRE_SUCCESS',
  CREATE_GENRE_ERROR: 'CREATE_GENRE_ERROR',

  FETCH_GENRE_LIST_REQUEST: "FETCH_GENRE_LIST_REQUEST",
  FETCH_GENRE_LIST_LOADING: "FETCH_GENRE_LIST_LOADING",
  FETCH_GENRE_LIST_SUCCESS: "FETCH_GENRE_LIST_SUCCESS",
  FETCH_GENRE_LIST_ERROR: "FETCH_GENRE_LIST_ERROR",

  EDIT_GENRE_REQUEST: 'EDIT_GENRE_REQUEST',
  EDIT_GENRE_LOADING: 'EDIT_GENRE_LOADING',
  EDIT_GENRE_SUCCESS: 'EDIT_GENRE_SUCCESS',
  EDIT_GENRE_ERROR: 'EDIT_GENRE_ERROR',
  RESET_GENRE_STATE: "RESET_GENRE_STATE",

  DELETE_GENRE_REQUEST: 'DELETE_GENRE_REQUEST',
  DELETE_GENRE_LOADING: 'DELETE_GENRE_LOADING',
  DELETE_GENRE_SUCCESS: 'DELETE_GENRE_SUCCESS',
  DELETE_GENRE_ERROR: 'DELETE_GENRE_ERROR',

  RESET_GENRE_LIST_STATE: 'RESET_GENRE_LIST_STATE',
  SET_GENRES_SEARCH_TEXT: "SET_GENRES_SEARCH_TEXT",
  // SET_GENRES_FILTER: "SET_GENRES_FILTER",
  EDIT_VIEW: 'EDIT_VIEW',
  SET_SELECTED_GENRE: "SET_SELECTED_GENRE",
  CREATE_VIEW: 'CREATE_VIEW',

  createGenre: ({
    name,
    nameMy,
  }) => {
    return {
      type: genresActions.CREATE_GENRE_REQUEST,
      payload: {
        name,
        nameMy,
      }
    }
  },

  fetchGenreList: (page) => {
    return {
      type: genresActions.FETCH_GENRE_LIST_REQUEST,
      payload: {
        page,
      },
    };
  },

  resetGenreListState: () => {
    return {
      type: genresActions.RESET_GENRE_LIST_STATE,
    };
  },

  editGenre: ({
    name,
    nameMy,
    genreIdentifier
  }) => {
    return {
      type: genresActions.EDIT_GENRE_REQUEST,
      payload: {
        name,
        nameMy,
        genreIdentifier
      }
    };
  },

  deleteGenre: (genreIdentifier) => {
    return {
      type: genresActions.DELETE_GENRE_REQUEST,
      payload: { genreIdentifier }
    };
  },

  resetGenreState: () => {
    return {
      type: genresActions.RESET_GENRE_STATE,
    };
  },

  setSearchText: (payload) => {
    return {
      type: genresActions.SET_GENRES_SEARCH_TEXT,
      payload,
    };
  },

  //   setFilter: (payload) => {
  //     return {
  //       type: gemresActions.SET_GENRES_FILTER,
  //       payload,
  //     };
  //   },

  setSelectedGenre: (genre) => {
    return {
      type: genresActions.SET_SELECTED_GENRE,
      payload: {
        genre,
      },
    };
  },

  viewChange: view => ({
    type: genresActions.EDIT_VIEW,
    payload: {
      view,
    },
  }),

  setCreateGenre: creating => ({
    type: genresActions.CREATE_VIEW,
    payload: {
      creating,
    }
  }),
};

export default genresActions;
