import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Admin from "@iso/redux/admin/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import modal from "@iso/redux/modal/reducer";
import tickets from "@iso/redux/tickets/reducer";
import movies from "@iso/redux/movies/reducer";
import series from "@iso/redux/series/reducer";

import videoUpload from "@iso/redux/videoUpload/reducer";
import invoices from "@iso/redux/invoices/reducer";
import members from "@iso/redux/members/reducer";
import giftTickets from "@iso/redux/giftTickets/reducer";
import report from "@iso/redux/report/reducer";
import faqs from "@iso/redux/faqs/reducer";
import productionHouses from "@iso/redux/productionHouses/reducer";
import genres from "@iso/redux/genre/reducer";
import posters from "@iso/redux/posters/reducer";
import cast from "@iso/redux/cast/reducer";
import episode from "@iso/redux/episode/reducer";
import tvprogram from "@iso/redux/tvprogram/reducer";
import gallery from "@iso/redux/gallery/reducer";
import promotionPackage from "@iso/redux/package/reducer";

export default combineReducers({
  Auth,
  App,
  Admin,
  ThemeSwitcher,
  LanguageSwitcher,
  modal,
  tickets,
  movies,
  videoUpload,
  invoices,
  members,
  giftTickets,
  report,
  faqs,
  productionHouses,
  genres,
  posters,
  cast,
  series,
  episode,
  tvprogram,
  gallery,
  promotionPackage,
});
