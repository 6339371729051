import productionHouseActions from "./actions";
import { Status } from '@api';

const initialState = {
    list: [],
    count: {},
    filter: '',
    searchText: '',
    selectedProductionHouse: {},
    hasMore: true,
    editView: false,
    creating: false,
    productionImagePath: null,
    productionHouseCreateStatus: Status.DEFAULT,
    productionHouseCreateErrorMessage: '',
    productionHouseStatus: Status.DEFAULT,
    productionHouseErrorMessage: '',
    productionHouseEditStatus: Status.DEFAULT,
    productionHouseEditErrorMessage: '',
    uploadProductionStatus: Status.DEFAULT,
    uploadProductionErrorMessage: '',
}

export default function productionHousesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case productionHouseActions.CREATE_PRODUCTION_HOUSE_LOADING:
            return {
                ...state,
                productionHouseCreateStatus: Status.LOADING,
                productionHouseCreateErrorMessage: '',
            }
        case productionHouseActions.CREATE_PRODUCTION_HOUSE_SUCCESS:
            return {
                ...state,
                selectedProductionHouse: payload.newProductionHouse,
                list: payload.newProductionHouseList,
                editView: false,
                creating: false,
                productionHouseCreateStatus: Status.SUCCESS,
                productionHouseCreateErrorMessage: '',
                count: payload.count,
            }
        case productionHouseActions.CREATE_PRODUCTION_HOUSE_ERROR:
            return {
                ...state,
                productionHouseCreateStatus: Status.ERROR,
                productionHouseCreateErrorMessage: payload.errorMessage,
            }
        case productionHouseActions.FETCH_PRODUCTION_HOUSE_LIST_LOADING:
            return {
                ...state,
                productionHouseStatus: Status.LOADING,
                productionHouseErrorMessage: '',
            }
        case productionHouseActions.FETCH_PRODUCTION_HOUSE_LIST_SUCCESS:
            const { list, count, hasMore, selectedProductionHouse } = payload;
            return {
                ...state,
                list,
                count,
                hasMore,
                selectedProductionHouse,
                editView: false,
                productionHouseStatus: Status.SUCCESS,
                productionHouseErrorMessage: '',
            }
        case productionHouseActions.FETCH_PRODUCTION_HOUSE_LIST_ERROR:
            return {
                ...state,
                productionHouseStatus: Status.ERROR,
                productionHouseErrorMessage: payload.errorMessage,
            }
        case productionHouseActions.RESET_PRODUCTION_HOUSE_LIST_STATE:
            return {
                ...state,
                productionHouseStatus: Status.DEFAULT,
                productionHouseErrorMessage: '',
                filter: '',
                searchText: '',
            }
        case productionHouseActions.EDIT_PRODUCTION_HOUSE_LOADING:
            return {
                ...state,
                productionHouseEditStatus: Status.LOADING,
                productionHouseEditErrorMessage: '',
            }
        case productionHouseActions.EDIT_PRODUCTION_HOUSE_SUCCESS:
            return {
                ...state,
                ...payload.updatedProductionHouse,
                selectedProductionHouse: payload.updatedProductionHouse,
                list: payload.updatedList,
                editView: false,
                productionHouseEditStatus: Status.SUCCESS,
                productionHouseEditErrorMessage: '',
            }
        case productionHouseActions.EDIT_PRODUCTION_HOUSE_ERROR:
            return {
                ...state,
                productionHouseEditStatus: Status.ERROR,
                productionHouseEditErrorMessage: payload.errorMessage,
            }
        case productionHouseActions.RESET_PRODUCTION_HOUSE_STATE:
            return {
                ...state,
                productionHouseCreateStatus: Status.DEFAULT,
                productionHouseCreateErrorMessage: '',
                productionHouseEditStatus: Status.DEFAULT,
                productionHouseEditErrorMessage: '',
            }
        case productionHouseActions.UPLOAD_PRODUCTION_IMAGE_LOADING:
            return {
                ...state,
                uploadProductionStatus: Status.LOADING,
                uploadProductionErrorMessage: '',
            }
        case productionHouseActions.UPLOAD_PRODUCTION_IMAGE_SUCCESS:
            return {
                ...state,
                uploadProductionStatus: Status.SUCCESS,
                uploadProductionErrorMessage: '',
                productionImagePath: payload.productionImagePath,
            }
        case productionHouseActions.UPLOAD_PRODUCTION_IMAGE_ERROR:
            return {
                ...state,
                uploadProductionStatus: Status.ERROR,
                uploadProductionErrorMessage: payload.errorMessage,
            }
        case productionHouseActions.SET_PRODUCTION_HOUSES_SEARCH_TEXT:
            return {
                ...state,
                searchText: payload,
            }
        case productionHouseActions.SET_PRODUCTION_HOUSES_FILTER:
            return {
                ...state,
                filter: payload,
                editView: false,
            }
        case productionHouseActions.SET_SELECTED_PRODUCTION_HOUSE:
            return {
                ...state,
                selectedProductionHouse: payload.productionHouse,
                editView: false,
                creating: false,
            }
        case productionHouseActions.EDIT_VIEW:
            return {
                ...state,
                editView: payload.view,
            }
        case productionHouseActions.CREATE_VIEW:
            return {
                ...state,
                creating: payload.creating,
                editView: true,
                selectedProductionHouse: {},
            }
        default:
            return state;
    }
}