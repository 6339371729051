import { call, takeLatest, put, select } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { LIMITS } from "@constants";
import { message } from "antd";
import _ from "lodash";
/**
 * worker saga: Calls the fetch ticket list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchSeriesList({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_SERIES_LIST_LOADING });

    let {
      searchText: keyword,
      filter: statusName,
      list: extList,
    } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.fetchSeriesList },
      { page, keyword, statusName }
    );

    // parse the data from response
    const {
      userResponse: { count, seriesList: newList },
    } = response;

    yield put({
      type: actions.FETCH_SERIES_LIST_SUCCESS,
      payload: {
        count,
        list: page === 1 ? newList : [...extList, ...newList],
        hasMore: newList.length >= LIMITS.PAGE_SIZE,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_SERIES_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the create series API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* createSeries({ payload }) {
  try {
    yield put({ type: actions.CREATE_SERIES_LOADING });

    const { list } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.createSeries },
      { ...payload }
    );

    // parse the data from response
    const {
      userResponse: { count, ...newSeries },
    } = response;

    yield put({
      type: actions.CREATE_SERIES_SUCCESS,
      payload: {
        count,
        list: [newSeries, ...list],
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_SERIES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the delete series API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* deleteSeries({ payload: { seriesIdentifier } }) {
  try {
    yield put({ type: actions.DELETE_SERIES_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteSeries },
      { seriesIdentifier }
    );

    const { userResponse: count } = response;

    message.success("Series was deleted successfully!");

    const { list } = yield select(getDataFromStore);
    let filteredList = _.remove(
      list,
      (item) => item.seriesIdentifier !== seriesIdentifier
    );

    yield put({
      type: actions.DELETE_SERIES_SUCCESS,
      payload: {
        list: filteredList,
        count,
      },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_SERIES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch  details API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.seriesIdentifier       - identifier
 */
function* fetchSeriesDetails({ payload: { seriesIdentifier } }) {
  try {
    yield put({ type: actions.FETCH_SERIES_DETAIL_LOADING });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchSeriesDetails },
      { seriesIdentifier }
    );

    // parse the data from response
    const { userResponse: seriesDetails } = response;

    yield put({
      type: actions.FETCH_SERIES_DETAIL_SUCCESS,
      payload: {
        seriesDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_SERIES_DETAIL_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the show banner API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* showBannerSeries({ payload }) {
  try {
    yield put({ type: actions.SHOW_BANNER_SERIES_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.showBanner },
      { ...payload }
    );

    // parse the data from response
    const { userResponse } = response;
    const { list } = yield select(getDataFromStore);

    let newList = _.map(list, (item) => {
      if (item.seriesIdentifier === userResponse?.entityIdentifier) {
        return {
          ...item,
          showBanner: userResponse?.showBanner,
          showBannerButton: userResponse?.showBannerButton,
        };
      } else {
        return item;
      }
    });

    yield put({
      type: actions.SHOW_BANNER_SERIES_SUCCESS,
      payload: {
        newList,
      },
    });
  } catch (error) {
    yield put({
      type: actions.SHOW_BANNER_SERIES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the EDIT SERIES API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* editSeries({ payload }) {
  try {
    yield put({ type: actions.EDIT_SERIES_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.editSeries },
      { ...payload }
    );

    // parse the data from response
    const { userResponse: seriesDetails } = response;

    yield put({
      type: actions.EDIT_SERIES_SUCCESS,
      payload: {
        seriesDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_SERIES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ series }) => {
  const { searchText, filter, list } = series;
  return {
    searchText,
    filter,
    list,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.FETCH_SERIES_LIST_REQUEST, fetchSeriesList);
  yield takeLatest(actions.FETCH_SERIES_DETAIL_REQUEST, fetchSeriesDetails);
  yield takeLatest(actions.CREATE_SERIES_REQUEST, createSeries);
  yield takeLatest(actions.SHOW_BANNER_SERIES_REQUEST, showBannerSeries);
  yield takeLatest(actions.DELETE_SERIES_REQUEST, deleteSeries);
  yield takeLatest(actions.EDIT_SERIES_REQUEST, editSeries);
}
