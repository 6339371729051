const tvprogramActions = {
  FETCH_TV_PROGRAM_LIST_REQUEST: "FETCH_TV_PROGRAM_LIST_REQUEST",
  FETCH_TV_PROGRAM_LIST_LOADING: "FETCH_TV_PROGRAM_LIST_LOADING",
  FETCH_TV_PROGRAM_LIST_SUCCESS: "FETCH_TV_PROGRAM_LIST_SUCCESS",
  FETCH_TV_PROGRAM_LIST_ERROR: "FETCH_TV_PROGRAM_LIST_ERROR",
  CREATE_TV_PROGRAM_REQUEST: "CREATE_TV_PROGRAM_REQUEST",
  CREATE_TV_PROGRAM_LOADING: "CREATE_TV_PROGRAM_LOADING",
  CREATE_TV_PROGRAM_SUCCESS: "CREATE_TV_PROGRAM_SUCCESS",
  CREATE_TV_PROGRAM_ERROR: "CREATE_TV_PROGRAM_ERROR",
  RESET_CREATE_TV_PROGRAM_STATE: "RESET_CREATE_TV_PROGRAM_STATE",
  RESET_TV_PROGRAM_STATE: "RESET_TV_PROGRAM_STATE",
  DELETE_TV_PROGRAM_REQUEST: "DELETE_TV_PROGRAM_REQUEST",
  DELETE_TV_PROGRAM_LOADING: "DELETE_TV_PROGRAM_LOADING",
  DELETE_TV_PROGRAM_SUCCESS: "DELETE_TV_PROGRAM_SUCCESS",
  DELETE_TV_PROGRAM_ERROR: "DELETE_TV_PROGRAM_ERROR",

  FETCH_TV_PROGRAM_DETAIL_REQUEST: "FETCH_TV_PROGRAM_DETAIL_REQUEST",
  FETCH_TV_PROGRAM_DETAIL_LOADING: "FETCH_TV_PROGRAM_DETAIL_LOADING",
  FETCH_TV_PROGRAM_DETAIL_SUCCESS: "FETCH_TV_PROGRAM_DETAIL_SUCCESS",
  FETCH_TV_PROGRAM_DETAIL_ERROR: "FETCH_TV_PROGRAM_DETAIL_ERROR",

  SHOW_BANNER_TV_PROGRAM_REQUEST: "SHOW_BANNER_TV_PROGRAM_REQUEST",
  SHOW_BANNER_TV_PROGRAM_LOADING: "SHOW_BANNER_TV_PROGRAM_LOADING",
  SHOW_BANNER_TV_PROGRAM_SUCCESS: "SHOW_BANNER_TV_PROGRAM_SUCCESS",
  SHOW_BANNER_TV_PROGRAM_ERROR: "SHOW_BANNER_TV_PROGRAM_ERROR",

  // SEARCH
  SET_TV_PROGRAM_SEARCH_TEXT: "SET_TV_PROGRAM_SEARCH_TEXT",
  SET_TV_PROGRAM_FILTER: "SET_TV_PROGRAM_FILTER",

  EDIT_TV_PROGRAM_REQUEST: "EDIT_TV_PROGRAM_REQUEST",
  EDIT_TV_PROGRAM_LOADING: "EDIT_TV_PROGRAM_LOADING",
  EDIT_TV_PROGRAM_SUCCESS: "EDIT_TV_PROGRAM_SUCCESS",
  EDIT_TV_PROGRAM_ERROR: "EDIT_TV_PROGRAM_ERROR",

  fetchTvProgramList: (page) => {
    return {
      type: tvprogramActions.FETCH_TV_PROGRAM_LIST_REQUEST,
      payload: {
        ...page,
      },
    };
  },

  fetchTvProgramDetails: (tvProgramIdentifier) => {
    return {
      type: tvprogramActions.FETCH_TV_PROGRAM_DETAIL_REQUEST,
      payload: {
        tvProgramIdentifier,
      },
    };
  },

  createTvProgram: (tvprogram) => {
    return {
      type: tvprogramActions.CREATE_TV_PROGRAM_REQUEST,
      payload: {
        ...tvprogram,
      },
    };
  },

  editTvProgram: (tvprogram) => {
    return {
      type: tvprogramActions.EDIT_TV_PROGRAM_REQUEST,
      payload: {
        ...tvprogram,
      },
    };
  },
  showBannerTvProgram: (tvprogram) => {
    return {
      type: tvprogramActions.SHOW_BANNER_TV_PROGRAM_REQUEST,
      payload: {
        ...tvprogram,
      },
    };
  },

  resetCreateTvProgramState: () => {
    return {
      type: tvprogramActions.RESET_CREATE_TV_PROGRAM_STATE,
    };
  },
  resetTvProgramState: () => {
    return {
      type: tvprogramActions.RESET_TV_PROGRAM_STATE,
    };
  },
  deleteTvProgram: (tvProgramIdentifier) => {
    return {
      type: tvprogramActions.DELETE_TV_PROGRAM_REQUEST,
      payload: {
        tvProgramIdentifier,
      },
    };
  },
  setSearchText: (payload) => {
    return {
      type: tvprogramActions.SET_TV_PROGRAM_SEARCH_TEXT,
      payload,
    };
  },
  setTvProgramFilter: (payload) => {
    return {
      type: tvprogramActions.SET_TV_PROGRAM_FILTER,
      payload,
    };
  },
};

export default tvprogramActions;
