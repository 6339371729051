const ticketsActions = {
  UPDATE_TICKET_REQUEST: "UPDATE_TICKET_REQUEST",
  UPDATE_TICKET_LOADING: "UPDATE_TICKET_LOADING",
  UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",
  UPDATE_TICKET_ERROR: "UPDATE_TICKET_ERROR",

  RESET_TICKET_UPDATE_STATE: "RESET_TICKET_UPDATE_STATE",

  updateTicket: (ticket) => {
    return {
      type: ticketsActions.UPDATE_TICKET_REQUEST,
      payload: {
        ...ticket,
      },
    };
  },
  resetTicketstate: () => {
    return {
      type: ticketsActions.RESET_TICKET_UPDATE_STATE,
    };
  },
};

export default ticketsActions;
