import { isObject, } from '@iso/lib/helpers/utility';
import { SERVER_ERROR_CODES } from '@constants';

const genericApiErrorMessage = "Unable to process the request, something went wrong.";

/**
 * The request was made and the server responded with a status code
 * that falls out of the range of 2xx
 *
 * @param {object} error - object containing error detail from axios
 *
 * @return error object contaning message and error code from server
 *
 * NOTE: the error code sent back is different from http status code.
 */
export function parseServerErrorResponse(error) {
  let errorCode = SERVER_ERROR_CODES.noErrorCodeFromServer;
  let errorMessage = genericApiErrorMessage;
  if (error.response.data && isObject(error.response.data)) {
    const { error: { code, text } = {} } = error.response.data;
    errorCode = code;
    errorMessage = text;
  }

  const errorToReturn = new Error(errorMessage);
  errorToReturn.name = errorCode;
  errorToReturn.status = error.response?.status;
  return errorToReturn;
}
