import castActions from "../cast/actions";
import episodeActions from "../episode/actions";
import galleryActions from "../gallery/actions";
import ticketsActions from "../tickets/actions";
import tvprogramActions from "./actions";
import { Status } from "@api";

const initialState = {
  tvprogramStatus: Status.DEFAULT,
  tvprogramErrorMessage: "",
  createTvprogramStatus: Status.DEFAULT,
  createTvprogramErrorMessage: "",
  editTvprogramStatus: Status.DEFAULT,
  editTvprogramErrorMessage: "",
  deleteTvprogramStatus: Status.DEFAULT,
  deleteTvprogramErrorMessage: "",
  tvprogramDetailsStatus: Status.DEFAULT,
  tvprogramDetailsErrorMessage: "",
  showBannerStatus: Status.DEFAULT,
  showBannerErrorMessage: "",
  tvprogramDetails: {},
  list: [],
  count: {},
  filter: "",
  searchText: "",
  hasMore: true,
};
export default function tvprogramReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case tvprogramActions.FETCH_TV_PROGRAM_LIST_LOADING:
      return {
        ...state,
        tvprogramStatus: Status.LOADING,
        tvprogramErrorMessage: "",
      };
    case tvprogramActions.FETCH_TV_PROGRAM_LIST_SUCCESS:
      const { list, count, hasMore } = payload;

      return {
        ...state,
        tvprogramStatus: Status.SUCCESS,
        tvprogramErrorMessage: "",
        list,
        count,
        hasMore,
      };
    case tvprogramActions.FETCH_TV_PROGRAM_LIST_ERROR:
      return {
        ...state,
        tvprogramStatus: Status.ERROR,
        tvprogramErrorMessage: payload.errorMessage,
      };
    case tvprogramActions.FETCH_TV_PROGRAM_DETAIL_LOADING:
      return {
        ...state,
        tvprogramDetailsStatus: Status.LOADING,
        tvprogramDetailsErrorMessage: "",
      };
    case tvprogramActions.FETCH_TV_PROGRAM_DETAIL_SUCCESS:
      return {
        ...state,
        tvprogramDetailsStatus: Status.SUCCESS,
        tvprogramDetailsErrorMessage: "",
        tvprogramDetails: payload.tvProgramDetails,
      };
    case tvprogramActions.FETCH_TV_PROGRAM_DETAIL_ERROR:
      return {
        ...state,
        tvprogramDetailsStatus: Status.ERROR,
        tvprogramDetailsErrorMessage: payload.errorMessage,
      };
    case tvprogramActions.RESET_TV_PROGRAM_DETAIL_STATE:
      return {
        ...state,
        tvprogramDetailsStatus: Status.DEFAULT,
        tvprogramDetailsErrorMessage: "",
        tvprogramDetails: null,
      };
    case tvprogramActions.CREATE_TV_PROGRAM_LOADING:
      return {
        ...state,
        createTvprogramStatus: Status.LOADING,
        createTvprogramErrorMessage: "",
      };
    case tvprogramActions.CREATE_TV_PROGRAM_SUCCESS:
      return {
        ...state,
        createTvprogramStatus: Status.SUCCESS,
        createTvprogramErrorMessage: "",
        count: payload.count,
        list: payload.list,
      };
    case tvprogramActions.CREATE_TV_PROGRAM_ERROR:
      return {
        ...state,
        createTvprogramStatus: Status.ERROR,
        createTvprogramErrorMessage: payload.errorMessage,
      };

    case tvprogramActions.EDIT_TV_PROGRAM_LOADING:
      return {
        ...state,
        editTvprogramStatus: Status.LOADING,
        editTvprogramErrorMessage: "",
      };
    case tvprogramActions.EDIT_TV_PROGRAM_SUCCESS:
      return {
        ...state,
        editTvprogramStatus: Status.SUCCESS,
        editTvprogramErrorMessage: "",
        tvprogramDetails: payload.tvprogramDetails,
      };
    case tvprogramActions.EDIT_TV_PROGRAM_ERROR:
      return {
        ...state,
        editTvprogramStatus: Status.ERROR,
        editTvprogramErrorMessage: payload.errorMessage,
      };

    case tvprogramActions.SHOW_BANNER_TV_PROGRAM_LOADING:
      return {
        ...state,
        showBannerStatus: Status.LOADING,
        showBannerErrorMessage: "",
      };
    case tvprogramActions.SHOW_BANNER_TV_PROGRAM_SUCCESS:
      return {
        ...state,
        showBannerStatus: Status.SUCCESS,
        showBannerErrorMessage: "",
        list: payload.newList,
      };
    case tvprogramActions.SHOW_BANNER_TV_PROGRAM_ERROR:
      return {
        ...state,
        showBannerStatus: Status.ERROR,
        showBannerErrorMessage: payload.errorMessage,
      };

    case tvprogramActions.RESET_CREATE_TV_PROGRAM_STATE:
      return {
        ...state,
        createTvprogramStatus: Status.DEFAULT,
        createTvprogramErrorMessage: "",
      };
    case tvprogramActions.RESET_TV_PROGRAM_STATE:
      return {
        ...state,
        tvprogramStatus: Status.DEFAULT,
        tvprogramErrorMessage: "",
        createTvprogramStatus: Status.DEFAULT,
        createTvprogramErrorMessage: "",
        editTvprogramStatus: Status.DEFAULT,
        editTvprogramErrorMessage: "",
        deleteTvprogramStatus: Status.DEFAULT,
        deleteTvprogramErrorMessage: "",
        tvprogramDetailsStatus: Status.DEFAULT,
        tvprogramDetailsErrorMessage: "",
        showBannerStatus: Status.DEFAULT,
        showBannerErrorMessage: "",
        filter: "",
      };
    case tvprogramActions.DELETE_TV_PROGRAM_LOADING:
      return {
        ...state,
        deleteTvprogramStatus: Status.LOADING,
        deleteTvprogramErrorMessage: "",
      };
    case tvprogramActions.DELETE_TV_PROGRAM_SUCCESS:
      return {
        ...state,
        deleteTvprogramStatus: Status.SUCCESS,
        deleteTvprogramErrorMessage: "",
        list: payload.list,
        count: payload.count,
      };
    case tvprogramActions.DELETE_TV_PROGRAM_ERROR:
      return {
        ...state,
        deleteTvprogramStatus: Status.ERROR,
        deleteTvprogramErrorMessage: payload.errorMessage,
      };
    case episodeActions.CREATE_EPISODE_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          episodeList: payload?.list,
        },
      };
    case episodeActions.EDIT_EPISODE_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          episodeList: payload?.episodeList,
        },
      };
    case episodeActions.DELETE_EPISODE_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          episodeList: payload?.episodeList,
        },
      };
    case episodeActions.UPLOAD_TVPROGRAM_IMAGE_SUCCESS:
      let newEpisodeList = state?.tvprogramDetails?.episodeList?.map(
        (episode) => {
          if (episode?.episodeIdentifier === payload?.identifier) {
            return {
              ...episode,
              landscapeImagePath: payload?.landscapeImagePath,
            };
          }
          return episode;
        }
      );

      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          episodeList: [...newEpisodeList],
        },
      };
    case castActions.CREATE_CAST_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          castList: payload?.list,
        },
      };

    case castActions.EDIT_CAST_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          castList: payload?.castList,
        },
      };
    case castActions.DELETE_CAST_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          castList: payload?.castList,
        },
      };
    case ticketsActions.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          price: payload?.price,
          ticketPrefixCode: payload?.ticketPrefixCode,
        },
      };

    case tvprogramActions.SET_TV_PROGRAM_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case tvprogramActions.SET_TV_PROGRAM_FILTER:
      return {
        ...state,
        filter: payload,
      };

    case galleryActions.CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          galleryList: payload?.list,
        },
      };
    case galleryActions.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        tvprogramDetails: {
          ...state?.tvprogramDetails,
          galleryList: payload?.list,
        },
      };

    default:
      return state;
  }
}
