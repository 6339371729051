import { call, takeLatest, put, select } from "redux-saga/effects";
import actions from "./actions";
import { api } from "@api";
import { LIMITS } from "@constants";
import { message } from "antd";
import _ from "lodash";
/**
 * worker saga: Calls the fetch ticket list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchTvProgramList({ payload }) {
  const { page = 1, categoryName, privacyName } = payload;
  try {
    yield put({ type: actions.FETCH_TV_PROGRAM_LIST_LOADING });

    let {
      searchText: keyword,
      filter: statusName,
      list: extList,
    } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.fetchTvProgramList },
      { page, keyword, statusName, categoryName, privacyName }
    );

    // parse the data from response
    const {
      userResponse: { count, tvProgramList: newList },
    } = response;

    yield put({
      type: actions.FETCH_TV_PROGRAM_LIST_SUCCESS,
      payload: {
        count,
        list: page === 1 ? newList : [...extList, ...newList],
        hasMore: newList.length >= LIMITS.PAGE_SIZE,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_TV_PROGRAM_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the create tvprogram API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* createTvProgram({ payload }) {
  try {
    yield put({ type: actions.CREATE_TV_PROGRAM_LOADING });

    const { list } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.createTvProgram },
      { ...payload }
    );

    // parse the data from response
    const {
      userResponse: { count, ...newTvprogram },
    } = response;

    yield put({
      type: actions.CREATE_TV_PROGRAM_SUCCESS,
      payload: {
        count,
        list: [newTvprogram, ...list],
      },
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_TV_PROGRAM_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the delete tvprogram API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {object} action.payload
 */
function* deleteTvProgram({ payload: { tvProgramIdentifier } }) {
  try {
    yield put({ type: actions.DELETE_TV_PROGRAM_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteTvProgram },
      { tvProgramIdentifier }
    );

    const { userResponse: count } = response;

    message.success("TV Program was deleted successfully!");

    const { list } = yield select(getDataFromStore);
    let filteredList = _.remove(
      list,
      (item) => item.tvProgramIdentifier !== tvProgramIdentifier
    );

    yield put({
      type: actions.DELETE_TV_PROGRAM_SUCCESS,
      payload: {
        list: filteredList,
        count,
      },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_TV_PROGRAM_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch  details API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.tvProgramIdentifier       - identifier
 */
function* fetchTvProgramDetails({ payload: { tvProgramIdentifier } }) {
  try {
    yield put({ type: actions.FETCH_TV_PROGRAM_DETAIL_LOADING });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchTvProgramDetails },
      { tvProgramIdentifier }
    );

    // parse the data from response
    const { userResponse: tvProgramDetails } = response;

    yield put({
      type: actions.FETCH_TV_PROGRAM_DETAIL_SUCCESS,
      payload: {
        tvProgramDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_TV_PROGRAM_DETAIL_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the show banner API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* showBannerTvProgram({ payload }) {
  try {
    yield put({ type: actions.SHOW_BANNER_TV_PROGRAM_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.showBanner },
      { ...payload }
    );

    // parse the data from response
    const { userResponse } = response;
    const { list } = yield select(getDataFromStore);

    let newList = _.map(list, (item) => {
      if (item.tvProgramIdentifier === userResponse?.entityIdentifier) {
        return {
          ...item,
          showBanner: userResponse?.showBanner,
          showBannerButton: userResponse?.showBannerButton,
        };
      } else {
        return item;
      }
    });

    yield put({
      type: actions.SHOW_BANNER_TV_PROGRAM_SUCCESS,
      payload: {
        newList,
      },
    });
  } catch (error) {
    yield put({
      type: actions.SHOW_BANNER_TV_PROGRAM_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the EDIT SERIES API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload
 */
function* editTvProgram({ payload }) {
  try {
    yield put({ type: actions.EDIT_TV_PROGRAM_LOADING });

    // api call
    const response = yield call(
      { context: api, fn: api.editTvProgram },
      { ...payload }
    );

    // parse the data from response
    const { userResponse: tvprogramDetails } = response;

    yield put({
      type: actions.EDIT_TV_PROGRAM_SUCCESS,
      payload: {
        tvprogramDetails,
      },
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_TV_PROGRAM_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ tvprogram }) => {
  const { searchText, filter, list } = tvprogram;
  return {
    searchText,
    filter,
    list,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.FETCH_TV_PROGRAM_LIST_REQUEST, fetchTvProgramList);
  yield takeLatest(
    actions.FETCH_TV_PROGRAM_DETAIL_REQUEST,
    fetchTvProgramDetails
  );
  yield takeLatest(actions.CREATE_TV_PROGRAM_REQUEST, createTvProgram);
  yield takeLatest(actions.SHOW_BANNER_TV_PROGRAM_REQUEST, showBannerTvProgram);
  yield takeLatest(actions.DELETE_TV_PROGRAM_REQUEST, deleteTvProgram);
  yield takeLatest(actions.EDIT_TV_PROGRAM_REQUEST, editTvProgram);
}
