import { all } from "redux-saga/effects";
import appSagas from "@iso/redux/app/saga";
import authSagas from "@iso/redux/auth/saga";
import adminSagas from "@iso/redux/admin/saga";
import ticketsSagas from "@iso/redux/tickets/saga";
import moviesSagas from "@iso/redux/movies/saga";
import videoUploadSagas from "@iso/redux/videoUpload/saga";
import invoiceSagas from "@iso/redux/invoices/saga";
import memberSagas from "@iso/redux/members/saga";
import giftTicketSagas from "@iso/redux/giftTickets/saga";
import reportSagas from "@iso/redux/report/saga";
import faqsSagas from "@iso/redux/faqs/saga";
import productionHousesSagas from "@iso/redux/productionHouses/saga";
import genreSagas from "@iso/redux/genre/saga";
import postersSagas from "@iso/redux/posters/saga";
import castSagas from "@iso/redux/cast/saga";
import seriesSagas from "@iso/redux/series/saga";
import episodeSagas from "@iso/redux/episode/saga";
import tvprogramSagas from "@iso/redux/tvprogram/saga";
import gallerySagas from "@iso/redux/gallery/saga";
import packageSagas from "@iso/redux/package/saga";

export default function* rootSaga(getState) {
  yield all([
    appSagas(),
    authSagas(),
    adminSagas(),
    ticketsSagas(),
    moviesSagas(),
    videoUploadSagas(),
    invoiceSagas(),
    memberSagas(),
    giftTicketSagas(),
    reportSagas(),
    faqsSagas(),
    productionHousesSagas(),
    genreSagas(),
    postersSagas(),
    castSagas(),
    seriesSagas(),
    episodeSagas(),
    tvprogramSagas(),
    gallerySagas(),
    packageSagas(),
  ]);
}
