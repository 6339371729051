import giftTicketsActions from "./actions";
import { Status } from '@api';

const initialState = {
    giftTicketStatus: Status.DEFAULT,
    giftTicketErrorMessage: '',
    list: [],
    hasMore: true,
    searchText: '',
}

export default function giftTicketsReducer(state = initialState, { type, payload }){
    switch (type) {
        case giftTicketsActions.FETCH_GIFT_TICKET_LIST_LOADING:
            return {
                ...state,
                giftTicketStatus: Status.DEFAULT,
                giftTicketErrorMessage: '',
            };
        case giftTicketsActions.FETCH_GIFT_TICKET_LIST_SUCCESS:
            const { list, hasMore } = payload;
            return {
                ...state,
                giftTicketStatus: Status.SUCCESS,
                list,
                hasMore
            }
        case giftTicketsActions.FETCH_GIFT_TICKET_LIST_ERROR:
            return {
                ...state,
                giftTicketStatus: Status.ERROR,
                giftTicketErrorMessage: payload.errorMessage
            }
        case giftTicketsActions.SET_GIFT_TICKET_SEARCH_TEXT:
            return {
                ...state,
                searchText: payload
            }
        case giftTicketsActions.RESET_GIFT_TICKET_STATE:
            return {
                ...state,
                giftTicketStatus: Status.DEFAULT,
                giftTicketErrorMessage: '',
                searchText: '',
            }
        default: 
            return state;
    }
}