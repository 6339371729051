const membersActions = {
    FETCH_MEMBER_LIST_REQUEST: 'FETCH_MEMBER_LIST_REQUEST',
    FETCH_MEMBER_LIST_LOADING: 'FETCH_MEMBER_LIST_LOADING',
    FETCH_MEMBER_LIST_SUCCESS: 'FETCH_MEMBER_LIST_SUCCESS',
    FETCH_MEMBER_LIST_ERROR: 'FETCH_MEMBER_LIST_ERROR',
    RESET_MEMBERS_STATE: 'RESET_MEMBERS_STATE',

    SET_MEMBERS_SEARCH_TEXT: 'SET_MEMBERS_SEARCH_TEXT',
    SET_MEMBERS_FILTER: 'SET_MEMBERS_FILTER',
    SET_SELECTED_MEMBER: 'SET_SELECTED_MEMBER',

    fetchMemberList: page => {
        return {
            type: membersActions.FETCH_MEMBER_LIST_REQUEST,
            payload: {
                page,
            },
        }
    },

    resetMembersState: ()=>{
      return {
        type: membersActions.RESET_MEMBERS_STATE,
      };
    },
    
    setSearchText: payload => {
        return {
            type: membersActions.SET_MEMBERS_SEARCH_TEXT,
            payload,
        };
    },

    setFilter: payload => {
        return {
            type: membersActions.SET_MEMBERS_FILTER,
            payload,
        }
    },

    setSelectedMember: member => {
        return {
            type: membersActions.SET_SELECTED_MEMBER,
            payload: {
                member,
            },
        }
    },

};

export default membersActions;