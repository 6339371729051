const galleryActions = {
  CREATE_GALLERY_REQUEST: "CREATE_GALLERY_REQUEST",
  CREATE_GALLERY_LOADING: "CREATE_GALLERY_LOADING",
  CREATE_GALLERY_SUCCESS: "CREATE_GALLERY_SUCCESS",
  CREATE_GALLERY_ERROR: "CREATE_GALLERY_ERROR",

  RESET_GALLERY_STATE: "RESET_GALLERY_STATE",

  DELETE_GALLERY_REQUEST: "DELETE_GALLERY_REQUEST",
  DELETE_GALLERY_LOADING: "DELETE_GALLERY_LOADING",
  DELETE_GALLERY_SUCCESS: "DELETE_GALLERY_SUCCESS",
  DELETE_GALLERY_ERROR: "DELETE_GALLERY_ERROR",

  // SEARCH
  SET_GALLERY_SEARCH_TEXT: "SET_GALLERY_SEARCH_TEXT",
  SET_GALLERY_FILTER: "SET_GALLERY_FILTER",

  createGallery: (gallery) => {
    return {
      type: galleryActions.CREATE_GALLERY_REQUEST,
      payload: {
        ...gallery,
      },
    };
  },

  resetGalleryState: () => {
    return {
      type: galleryActions.RESET_GALLERY_STATE,
    };
  },
  deleteGallery: (galleryIdentifier) => {
    return {
      type: galleryActions.DELETE_GALLERY_REQUEST,
      payload: {
        galleryIdentifier,
      },
    };
  },
};

export default galleryActions;
