import { call, put, select, takeLatest } from "redux-saga/effects";
import actions from './actions';
import { api } from '@api';

/**
 * worker saga: Calls the fetch member list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchMemberList({ payload: { page = 1 } }) {
    try {
        yield put({ type: actions.FETCH_MEMBER_LIST_LOADING });

        let {
            searchText: keyword,
            filter: statusName,
            list: extList,
        } = yield select(getDataFromStore);

        //api call
        const response = yield call(
            { context: api, fn: api.fetchMemberList },
            { page, keyword, statusName },
        )

        // parse the data from response
        const {
            userResponse: {
                count,
                memberList: newList,
            },
        } = response;
        const _list = page === 1 ? newList : [...extList, ...newList];

        yield put({
            type: actions.FETCH_MEMBER_LIST_SUCCESS,
            payload: {
                count,
                list: _list,
                hasMore: newList.length >= 12,
                selectedMember: _list[0],
            }
        });
    }
    catch (error) {
        yield put({
            type: actions.FETCH_MEMBER_LIST_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

const getDataFromStore = ({ members }) => {
    const { searchText, filter, list, } = members;
    return {
        searchText,
        filter,
        list,
    }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
    yield takeLatest(actions.FETCH_MEMBER_LIST_REQUEST, fetchMemberList);
}