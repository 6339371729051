const castActions = {
  FETCH_CAST_LIST_REQUEST: "FETCH_CAST_LIST_REQUEST",
  FETCH_CAST_LIST_LOADING: "FETCH_CAST_LIST_LOADING",
  FETCH_CAST_LIST_SUCCESS: "FETCH_CAST_LIST_SUCCESS",
  FETCH_CAST_LIST_ERROR: "FETCH_CAST_LIST_ERROR",

  UPLOAD_CAST_IMAGE_REQUEST: "UPLOAD_CAST_IMAGE_REQUEST",
  UPLOAD_CAST_IMAGE_LOADING: "UPLOAD_CAST_IMAGE_LOADING",
  UPLOAD_CAST_IMAGE_SUCCESS: "UPLOAD_CAST_IMAGE_SUCCESS",
  UPLOAD_CAST_IMAGE_ERROR: "UPLOAD_CAST_IMAGE_ERROR",

  CREATE_CAST_REQUEST: "CREATE_CAST_REQUEST",
  CREATE_CAST_LOADING: "CREATE_CAST_LOADING",
  CREATE_CAST_SUCCESS: "CREATE_CAST_SUCCESS",
  CREATE_CAST_ERROR: "CREATE_CAST_ERROR",

  EDIT_CAST_REQUEST: "EDIT_CAST_REQUEST",
  EDIT_CAST_LOADING: "EDIT_CAST_LOADING",
  EDIT_CAST_SUCCESS: "EDIT_CAST_SUCCESS",
  EDIT_CAST_ERROR: "EDIT_CAST_ERROR",

  RESET_CREATE_CAST_STATE: "RESET_CREATE_CAST_STATE",

  RESET_CAST_STATE: "RESET_CAST_STATE",

  DELETE_CAST_REQUEST: "DELETE_CAST_REQUEST",
  DELETE_CAST_LOADING: "DELETE_CAST_LOADING",
  DELETE_CAST_SUCCESS: "DELETE_CAST_SUCCESS",
  DELETE_CAST_ERROR: "DELETE_CAST_ERROR",

  // SEARCH
  SET_CAST_SEARCH_TEXT: "SET_CAST_SEARCH_TEXT",
  SET_CAST_FILTER: "SET_CAST_FILTER",

  fetchCastList: (page) => {
    return {
      type: castActions.FETCH_CAST_LIST_REQUEST,
      payload: {
        page,
      },
    };
  },
  createCast: (cast) => {
    return {
      type: castActions.CREATE_CAST_REQUEST,
      payload: {
        ...cast,
      },
    };
  },

  uploadCastImage: ({
    file,
    entityIdentifier,
    fileDirectory = "profilePhoto",
    fileCategory,
  }) => {
    return {
      type: castActions.UPLOAD_CAST_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },
  editCast: (cast) => {
    return {
      type: castActions.EDIT_CAST_REQUEST,
      payload: {
        cast,
      },
    };
  },
  resetCastState: () => {
    return {
      type: castActions.RESET_CAST_STATE,
    };
  },
  deleteCast: (castIdentifier) => {
    return {
      type: castActions.DELETE_CAST_REQUEST,
      payload: {
        castIdentifier,
      },
    };
  },
};

export default castActions;
