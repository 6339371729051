const episodeActions = {
  FETCH_EPISODE_LIST_REQUEST: "FETCH_EPISODE_LIST_REQUEST",
  FETCH_EPISODE_LIST_LOADING: "FETCH_EPISODE_LIST_LOADING",
  FETCH_EPISODE_LIST_SUCCESS: "FETCH_EPISODE_LIST_SUCCESS",
  FETCH_EPISODE_LIST_ERROR: "FETCH_EPISODE_LIST_ERROR",

  UPLOAD_EPISODE_IMAGE_REQUEST: "UPLOAD_EPISODE_IMAGE_REQUEST",
  UPLOAD_EPISODE_IMAGE_LOADING: "UPLOAD_EPISODE_IMAGE_LOADING",
  UPLOAD_EPISODE_IMAGE_SUCCESS: "UPLOAD_EPISODE_IMAGE_SUCCESS",
  UPLOAD_EPISODE_IMAGE_ERROR: "UPLOAD_EPISODE_IMAGE_ERROR",

  UPLOAD_TVPROGRAM_IMAGE_REQUEST: "UPLOAD_TVPROGRAM_IMAGE_REQUEST",
  UPLOAD_TVPROGRAM_IMAGE_LOADING: "UPLOAD_TVPROGRAM_IMAGE_LOADING",
  UPLOAD_TVPROGRAM_IMAGE_SUCCESS: "UPLOAD_TVPROGRAM_IMAGE_SUCCESS",
  UPLOAD_TVPROGRAM_IMAGE_ERROR: "UPLOAD_TVPROGRAM_IMAGE_ERROR",

  CREATE_EPISODE_REQUEST: "CREATE_EPISODE_REQUEST",
  CREATE_EPISODE_LOADING: "CREATE_EPISODE_LOADING",
  CREATE_EPISODE_SUCCESS: "CREATE_EPISODE_SUCCESS",
  CREATE_EPISODE_ERROR: "CREATE_EPISODE_ERROR",

  EDIT_EPISODE_REQUEST: "EDIT_EPISODE_REQUEST",
  EDIT_EPISODE_LOADING: "EDIT_EPISODE_LOADING",
  EDIT_EPISODE_SUCCESS: "EDIT_EPISODE_SUCCESS",
  EDIT_EPISODE_ERROR: "EDIT_EPISODE_ERROR",

  RESET_CREATE_EPISODE_STATE: "RESET_CREATE_EPISODE_STATE",

  RESET_EPISODE_STATE: "RESET_EPISODE_STATE",

  DELETE_EPISODE_REQUEST: "DELETE_EPISODE_REQUEST",
  DELETE_EPISODE_LOADING: "DELETE_EPISODE_LOADING",
  DELETE_EPISODE_SUCCESS: "DELETE_EPISODE_SUCCESS",
  DELETE_EPISODE_ERROR: "DELETE_EPISODE_ERROR",

  // SEARCH
  SET_EPISODE_SEARCH_TEXT: "SET_EPISODE_SEARCH_TEXT",
  SET_EPISODE_FILTER: "SET_EPISODE_FILTER",

  fetchEpisodeList: (page) => {
    return {
      type: episodeActions.FETCH_EPISODE_LIST_REQUEST,
      payload: {
        page,
      },
    };
  },
  createEpisode: (episode) => {
    return {
      type: episodeActions.CREATE_EPISODE_REQUEST,
      payload: {
        ...episode,
      },
    };
  },

  uploadEpisodeImage: ({
    file,
    entityIdentifier,
    fileDirectory = "profilePhoto",
    fileCategory,
  }) => {
    return {
      type: episodeActions.UPLOAD_EPISODE_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },

  uploadTVProgramImage: ({
    file,
    entityIdentifier,
    fileDirectory = "profilePhoto",
    fileCategory,
  }) => {
    return {
      type: episodeActions.UPLOAD_TVPROGRAM_IMAGE_REQUEST,
      payload: {
        file,
        fileCategory,
        fileDirectory,
        entityIdentifier,
      },
    };
  },

  editEpisode: (episode) => {
    return {
      type: episodeActions.EDIT_EPISODE_REQUEST,
      payload: {
        episode,
      },
    };
  },
  resetEpisodeState: () => {
    return {
      type: episodeActions.RESET_EPISODE_STATE,
    };
  },
  deleteEpisode: (data) => {
    return {
      type: episodeActions.DELETE_EPISODE_REQUEST,
      payload: {
        ...data,
      },
    };
  },
};

export default episodeActions;
