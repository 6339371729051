import { call, takeLatest, put, select, } from 'redux-saga/effects';
import actions from './actions';
import { api } from '@api';
import { LIMITS } from '@constants';

/**
 * worker saga: Calls the fetch ticket list API
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.page       - page number
 */
function* fetchGiftTicketList({ payload: { page = 1 } }) {
  try {
    yield put({ type: actions.FETCH_GIFT_TICKET_LIST_LOADING });

    let {
      list: extList,
      searchText: keyword
    } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.fetchGiftTicketList },
      { keyword, page },
    );

    // parse the data from response
    const newList = response?.userResponse;

    yield put({
      type: actions.FETCH_GIFT_TICKET_LIST_SUCCESS,
      payload: {
        list: page === 1 ? newList : [...extList, ...newList],
        hasMore: newList.length >= LIMITS.PAGE_SIZE,
      },
    });

  }
  catch (error) {
    yield put({
      type: actions.FETCH_GIFT_TICKET_LIST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ giftTickets }) => {
  const { list, searchText } = giftTickets;
  return {
    list,
    searchText
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(actions.FETCH_GIFT_TICKET_LIST_REQUEST, fetchGiftTicketList);
}